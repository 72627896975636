import React from 'react';

type HibooLogoLargePropTypes = {
  height?: number;
  width?: number;
};

export const HibooLogoLarge: React.FC<
  React.PropsWithChildren<HibooLogoLargePropTypes>
> = ({ height = 22, width = 79 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 79 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4678 2.19995H26.249V10.3055H26.2946C26.4164 10.0912 26.5839 9.88069 26.797 9.67404C27.0102 9.46738 27.2652 9.27986 27.562 9.11147C27.8589 8.94308 28.19 8.80531 28.5554 8.69816C28.9208 8.591 29.309 8.53742 29.72 8.53742C30.4203 8.53742 31.0255 8.64458 31.5355 8.85889C32.0455 9.0732 32.4679 9.3717 32.8028 9.7544C33.1378 10.1371 33.3852 10.6002 33.545 11.1436C33.7049 11.687 33.7848 12.2802 33.7848 12.9231V19.5591H32.0036V13.1068C32.0036 12.2037 31.8057 11.4919 31.4099 10.9714C31.014 10.4509 30.3975 10.1907 29.5602 10.1907C28.9817 10.1907 28.4831 10.2902 28.0644 10.4892C27.6458 10.6882 27.3032 10.9714 27.0368 11.3388C26.7704 11.7062 26.5725 12.1424 26.4431 12.6476C26.3137 13.1528 26.249 13.7115 26.249 14.3238V19.5591H24.4678V2.19995Z"
      fill="#040402"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.6829 3.61899C36.4317 3.87923 36.3062 4.18538 36.3062 4.53746C36.3062 4.92016 36.4279 5.23397 36.6715 5.4789C36.9151 5.72383 37.2272 5.84629 37.6078 5.84629C37.9884 5.84629 38.3005 5.72383 38.5441 5.4789C38.7876 5.23397 38.9094 4.92016 38.9094 4.53746C38.9094 4.18538 38.7838 3.87923 38.5326 3.61899C38.2815 3.35875 37.9732 3.22864 37.6078 3.22864C37.2424 3.22864 36.9341 3.35875 36.6829 3.61899ZM38.4985 8.73947H36.7173V19.4856H38.4985V8.73947Z"
      fill="#040402"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.2021 14.1861C43.2021 14.7525 43.2897 15.2768 43.4648 15.759C43.6398 16.2412 43.8948 16.6621 44.2298 17.0219C44.5647 17.3816 44.9643 17.6648 45.4286 17.8714C45.893 18.0781 46.4144 18.1814 46.9929 18.1814C47.5714 18.1814 48.0928 18.0781 48.5571 17.8714C49.0215 17.6648 49.4211 17.3816 49.756 17.0219C50.091 16.6621 50.346 16.2412 50.521 15.759C50.6961 15.2768 50.7836 14.7525 50.7836 14.1861C50.7836 13.6197 50.6961 13.0954 50.521 12.6132C50.346 12.131 50.091 11.71 49.756 11.3503C49.4211 10.9905 49.0215 10.7073 48.5571 10.5007C48.0928 10.294 47.5714 10.1907 46.9929 10.1907C46.4144 10.1907 45.893 10.294 45.4286 10.5007C44.9643 10.7073 44.5647 10.9905 44.2298 11.3503C43.8948 11.71 43.6398 12.131 43.4648 12.6132C43.2897 13.0954 43.2021 13.6197 43.2021 14.1861ZM41.4214 2.19995H43.2026V10.3744H43.2483C43.7354 9.79267 44.3025 9.34109 44.9495 9.01962C45.5965 8.69815 46.3082 8.53742 47.0847 8.53742C47.922 8.53742 48.687 8.67902 49.3797 8.96222C50.0724 9.24542 50.6623 9.63959 51.1494 10.1448C51.6366 10.6499 52.0172 11.2469 52.2912 11.9358C52.5653 12.6246 52.7023 13.3747 52.7023 14.186C52.7023 14.9974 52.5653 15.7474 52.2912 16.4363C52.0172 17.1252 51.6404 17.7222 51.1609 18.2273C50.6813 18.7325 50.1142 19.1267 49.4596 19.4099C48.805 19.6931 48.1047 19.8347 47.3587 19.8347C46.3996 19.8347 45.5585 19.6242 44.8353 19.2032C44.1122 18.7822 43.5832 18.2656 43.2483 17.6533H43.2026V19.5591H41.4214V2.19995Z"
      fill="#040402"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.3472 14.1677C56.3472 14.7341 56.4347 15.2584 56.6098 15.7406C56.7849 16.2228 57.0399 16.6437 57.3748 17.0035C57.7097 17.3632 58.1093 17.6464 58.5737 17.853C59.038 18.0597 59.5594 18.163 60.1379 18.163C60.7164 18.163 61.2379 18.0597 61.7022 17.853C62.1665 17.6464 62.5661 17.3632 62.9011 17.0035C63.236 16.6437 63.491 16.2228 63.6661 15.7406C63.8411 15.2584 63.9287 14.7341 63.9287 14.1677C63.9287 13.6013 63.8411 13.077 63.6661 12.5948C63.491 12.1126 63.236 11.6916 62.9011 11.3319C62.5661 10.9721 62.1665 10.6889 61.7022 10.4823C61.2379 10.2756 60.7164 10.1723 60.1379 10.1723C59.5594 10.1723 59.038 10.2756 58.5737 10.4823C58.1093 10.6889 57.7097 10.9721 57.3748 11.3319C57.0399 11.6916 56.7849 12.1126 56.6098 12.5948C56.4347 13.077 56.3472 13.6013 56.3472 14.1677ZM54.4287 14.1677C54.4287 13.3257 54.5733 12.5603 54.8626 11.8715C55.1518 11.1826 55.5477 10.5894 56.0501 10.0919C56.5524 9.59442 57.1538 9.2079 57.8541 8.93236C58.5544 8.65681 59.3156 8.51904 60.1377 8.51904C60.9598 8.51904 61.7209 8.65681 62.4212 8.93236C63.1215 9.2079 63.7229 9.59442 64.2253 10.0919C64.7277 10.5894 65.1235 11.1826 65.4127 11.8715C65.702 12.5603 65.8466 13.3257 65.8466 14.1677C65.8466 15.0096 65.702 15.775 65.4127 16.4638C65.1235 17.1527 64.7277 17.7459 64.2253 18.2434C63.7229 18.7409 63.1215 19.1274 62.4212 19.403C61.7209 19.6785 60.9598 19.8163 60.1377 19.8163C59.3156 19.8163 58.5544 19.6785 57.8541 19.403C57.1538 19.1274 56.5524 18.7409 56.0501 18.2434C55.5477 17.7459 55.1518 17.1527 54.8626 16.4638C54.5733 15.775 54.4287 15.0096 54.4287 14.1677Z"
      fill="#040402"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.5005 14.1677C69.5005 14.7341 69.588 15.2584 69.7631 15.7406C69.9382 16.2228 70.1932 16.6437 70.5281 17.0035C70.863 17.3632 71.2626 17.6464 71.727 17.853C72.1913 18.0597 72.7127 18.163 73.2912 18.163C73.8697 18.163 74.3911 18.0597 74.8555 17.853C75.3198 17.6464 75.7194 17.3632 76.0544 17.0035C76.3893 16.6437 76.6443 16.2228 76.8194 15.7406C76.9944 15.2584 77.082 14.7341 77.082 14.1677C77.082 13.6013 76.9944 13.077 76.8194 12.5948C76.6443 12.1126 76.3893 11.6916 76.0544 11.3319C75.7194 10.9721 75.3198 10.6889 74.8555 10.4823C74.3911 10.2756 73.8697 10.1723 73.2912 10.1723C72.7127 10.1723 72.1913 10.2756 71.727 10.4823C71.2626 10.6889 70.863 10.9721 70.5281 11.3319C70.1932 11.6916 69.9382 12.1126 69.7631 12.5948C69.588 13.077 69.5005 13.6013 69.5005 14.1677ZM67.582 14.1677C67.582 13.3257 67.7267 12.5603 68.0159 11.8715C68.3052 11.1826 68.701 10.5894 69.2034 10.0919C69.7058 9.59442 70.3071 9.2079 71.0074 8.93236C71.7077 8.65681 72.4689 8.51904 73.291 8.51904C74.1131 8.51904 74.8743 8.65681 75.5746 8.93236C76.2749 9.2079 76.8762 9.59442 77.3786 10.0919C77.881 10.5894 78.2768 11.1826 78.566 11.8715C78.8553 12.5603 78.9999 13.3257 78.9999 14.1677C78.9999 15.0096 78.8553 15.775 78.566 16.4638C78.2768 17.1527 77.881 17.7459 77.3786 18.2434C76.8762 18.7409 76.2749 19.1274 75.5746 19.403C74.8743 19.6785 74.1131 19.8163 73.291 19.8163C72.4689 19.8163 71.7077 19.6785 71.0074 19.403C70.3071 19.1274 69.7058 18.7409 69.2034 18.2434C68.701 17.7459 68.3052 17.1527 68.0159 16.4638C67.7267 15.775 67.582 15.0096 67.582 14.1677Z"
      fill="#040402"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.80537 22C3.28303 18.3314 0 12.6717 0 6.99893C0 4.48509 0.619435 2.11585 1.71406 0.0353902L7.80537 3.78811L13.878 0C14.9843 2.08896 15.6107 4.47082 15.6107 6.99893C15.6107 12.6717 12.263 18.3314 7.80537 22ZM6.22375 4.76823L2.46144 2.45035C1.93758 3.89131 1.66466 5.42454 1.66466 6.99893C1.66466 11.5018 4.03516 16.2927 7.79849 19.7907C11.5572 16.2593 13.9461 11.4639 13.9461 6.99893C13.9461 5.41426 13.6696 3.87131 13.1391 2.42225L9.33317 4.79639C9.73752 5.36564 9.98784 6.15403 9.98784 7.02521C9.98784 8.76096 8.99413 10.1681 7.76833 10.1681C6.54253 10.1681 5.54882 8.76096 5.54882 7.02521C5.54882 6.13957 5.80752 5.33949 6.22375 4.76823ZM8.64688 7.02521C8.64688 7.71228 8.25354 8.26926 7.76832 8.26926C7.28311 8.26926 6.88977 7.71228 6.88977 7.02521C6.88977 6.33814 7.28311 5.78117 7.76832 5.78117C8.25354 5.78117 8.64688 6.33814 8.64688 7.02521Z"
      fill="#040402"
    />
  </svg>
);
