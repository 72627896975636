import colors from 'constants/colors';

export type FavoriteIconProps = {
  size?: number;
  color?: string;
};

export const FavoriteIcon = ({
  size = 16,
  color = colors.gray850,
}: FavoriteIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2731 5.37137L8.50235 1.39889C8.30856 0.964119 7.69145 0.964116 7.49765 1.39889L5.72691 5.37137L1.40167 5.82787C0.928299 5.87783 0.737601 6.46474 1.0912 6.7834L4.32207 9.69503L3.41966 13.9496C3.32089 14.4153 3.82014 14.778 4.23248 14.5402L8 12.3672L11.7675 14.5402C12.1799 14.778 12.6791 14.4153 12.5803 13.9496L11.6779 9.69503L14.9088 6.7834C15.2624 6.46474 15.0717 5.87783 14.5983 5.82787L10.2731 5.37137Z"
      stroke={color}
    />
  </svg>
);
