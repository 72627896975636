import colors from 'constants/colors';

export type ForbiddenIconProps = {
  color?: string;
};

export const ForbiddenIcon = ({
  color = colors.red400,
}: ForbiddenIconProps) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.13403" cy="8" r="6.0481" stroke={color} />
    <line x1="3.76932" y1="3.55153" x2="12.6713" y2="12.4535" stroke={color} />
  </svg>
);
