import colors from 'constants/colors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-left 5px;
  padding-right: 5px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 400;
  color: ${colors.blue400};
  background: ${colors.blue50};
  border: 1px solid ${colors.blue200};
  line-height: 18px;
  width: fit-content;
  `;

const Badge = ({ text }: { text: string }) => {
  return <Wrapper role="status">{text}</Wrapper>;
};

export const New = () => {
  return <Badge text="New" />;
};

export const Beta = () => {
  const { t } = useTranslation('commonUi');

  return <Badge text={t('beta')} />;
};
