import styled from 'styled-components';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import colors from 'constants/colors';

export const Title = styled.h5`
  align-items: center;
  color: ${colors.gray850};
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  min-height: 18px;

  span {
    display: flex;
    margin-left: 9px;
    padding: 10px 0;
  }

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    font-size: 12px;
  }
`;

export const Metric = styled.h4`
  color: ${colors.gray850};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding-top: 4px;
  display: flex;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    font-size: 14px;
  }
`;

export const Caption = styled.div`
  color: ${colors.gray500};
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding-top: 4px;

  span {
    color: ${colors.blue400};
    cursor: default;
    font-weight: 600;
    padding: 10px 0;

    &:hover {
      color: ${colors.blue500};
      text-decoration: none;
    }
  }
`;

export const TooltipWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 18px;
`;
