import styled from 'styled-components';

import colors from 'constants/colors';

export const ExportInProgressButton = styled.div`
  align-items: center;
  color: ${colors.gray500};
  display: flex;
  font-size: 14px;
  line-height: 18px;
`;
