import { useTranslation } from 'react-i18next';

import { Button, HModal } from 'modules/common-ui';
import { Email } from './index.css';

export type ExportConfirmModalProps = {
  isOpen: boolean;
  email: string;
  onContinue: () => any;
  onCancel: () => any;
};

export const ExportConfirmModal = ({
  isOpen,
  email,
  onContinue,
  onCancel,
}: ExportConfirmModalProps) => {
  const [t] = useTranslation('translation');

  return (
    <HModal isOpen={isOpen} style={{ width: 400 }} onRequestClose={onCancel}>
      <HModal.Header>
        <span
          role="img"
          aria-label="hello"
          style={{
            marginRight: '8px',
            fontFamily:
              'apple color emoji, segoe ui emoji, noto color emoji,android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol',
          }}
        >
          📨
        </span>
        {t('common.exportModal.title')}
      </HModal.Header>
      <HModal.Body>
        {t('common.exportModal.description')}
        <Email>{email}</Email>
        {t('common.exportModal.warning')}
      </HModal.Body>
      <HModal.Footer>
        <Button onClick={onCancel}>{t('common.cancel')}</Button>
        <Button variant={'primary'} onClick={onContinue}>
          {t('common.continue')}
        </Button>
      </HModal.Footer>
    </HModal>
  );
};
