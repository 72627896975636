import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import {
  ArrowUpIconFreemium,
  Button,
  Drawer,
  FreemiumButton,
} from 'modules/common-ui';
import { Header as DDHeader } from 'modules/common-ui/components/Drawer/index.css';

import {
  Container,
  DropdownHeaderLeft,
  DropdownHeaderRight,
  FreemiumDescription,
  FreemiumTitle,
  FreemiumWrapper,
} from './DateFilterFreemium.css';

export const DateFilterFreemium = () => {
  const [t] = useTranslation('commonUi');

  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_START });

  const [display, setDisplay] = useState(false);

  const onClick = useCallback(() => {
    if (isMobile) {
      setDisplay(true);
    }
  }, [isMobile, setDisplay]);

  const renderDrawer = () => {
    if (isMobile) {
      const DropdownHeader = () => (
        <DDHeader style={{ marginBottom: '13px' }}>
          <DropdownHeaderLeft>{t('dateFilter.title')}</DropdownHeaderLeft>
          <DropdownHeaderRight onClick={() => setDisplay(false)}>
            {t('translation:common.done')}
          </DropdownHeaderRight>
        </DDHeader>
      );

      return (
        <Drawer isOpen={display} customHeader={<DropdownHeader />}>
          <FreemiumWrapper>
            <FreemiumTitle>
              {t('reports:reports.freemium.freemiumTimespan.tooltipTitle')}
            </FreemiumTitle>
            <FreemiumDescription>
              {t(
                'reports:reports.freemium.freemiumTimespan.tooltipDescription',
              )}
            </FreemiumDescription>
            <FreemiumButton
              id="1weekspan-btn"
              freemiumTooltipButtonLabel={t(
                'reports:reports.freemium.freemiumTimespan.freemiumButtonLabel',
              )}
              block={true}
              style={{ width: '100%' }}
            ></FreemiumButton>
          </FreemiumWrapper>
        </Drawer>
      );
    }

    return null;
  };

  return (
    <Container>
      <Button
        data-testid="freemium-1weekspan-btn"
        freemiumTooltip={!isMobile}
        freemiumTooltipTitle={t(
          'reports:reports.freemium.freemiumTimespan.tooltipTitle',
        )}
        freemiumTooltipDescription={t(
          'reports:reports.freemium.freemiumTimespan.tooltipDescription',
        )}
        freemiumTooltipButtonLabel={t(
          'reports:reports.freemium.freemiumTimespan.freemiumButtonLabel',
        )}
        disabled={false}
        type="submit"
        variant="outline-alternate2"
        onClick={onClick}
        style={
          isMobile
            ? { width: '100%', justifyContent: 'space-between', height: '40px' }
            : {}
        }
      >
        {t('reports:reports.freemium.freemiumTimespan.last7days')}

        <ArrowUpIconFreemium />
      </Button>
      {renderDrawer()}
    </Container>
  );
};
