import { FetchApi } from '../client';

export type ApiPricingResponse = {
  pricingPlan: string;
  billingStatus: string;
  billingEmail: string;
  freeTrial: boolean;
  freeTrialStart: Date | null;
  freeTrialEnd: Date | null;
};

export const pricingDefaultValues: ApiPricingResponse = {
  pricingPlan: 'freemium',
  billingStatus: 'current',
  billingEmail: '',
  freeTrial: false,
  freeTrialStart: null,
  freeTrialEnd: null,
};

export const flagApi = (fetchApi: FetchApi) => ({
  getAllFlags(token: string): Promise<Record<string, boolean>> {
    return fetchApi
      .setToken(token)
      .get(`/app/getAllFlags`)
      .then((response) => {
        return response || {};
      })
      .catch((errorMessage) => {
        console.error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'Unable to get feature flag values.',
        );
        return {};
      });
  },
  getFlagCheck(flagName: string, token: string) {
    return fetchApi
      .setToken(token)
      .get(`/app/flagCheck`, { flagName }, { cache: false })
      .then((response) => {
        return response;
      })
      .catch((errorMessage) => {
        console.error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'Unable to check feature flag value.',
        );
        return false;
      });
  },
  getPricingPlanCheck(token: string): Promise<ApiPricingResponse> {
    return fetchApi
      .setToken(token)
      .get(`/app/pricingPlanCheck`)
      .then((response: ApiPricingResponse) => {
        return response;
      })
      .catch((errorMessage) => {
        console.error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'Unable to check feature pricing plan.',
        );
        return pricingDefaultValues;
      });
  },
});
