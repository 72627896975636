import styled from 'styled-components';

export const SharingIconContainer = styled.div`
  display: inline-flex;
  align-items: center;

  svg {
    margin-bottom: -1px;
  }
`;
