import { fromJS } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import { ActionType, ASSET_USAGE } from '../actions';

const initialState = fromJS({
  // identity data
  assetId: null,

  // data
  dailyUsages: [],
});

export default function assetUsageReducer(
  state = initialState,
  action: ActionType,
) {
  switch (action.type) {
    case ASSET_USAGE.RECEIVED_DATA: {
      const { workspace } = action.data.viewer;
      if (!workspace) {
        return state;
      }
      const { asset } = workspace;
      if (!asset) {
        return state;
      }
      const { dailyAllUsages } = cloneDeep(asset);
      const sortedDailyAllUsages = dailyAllUsages.sort((a, b) => {
        const momentA = moment(a.date);
        const momentB = moment(b.date);
        if (momentA.isBefore(momentB)) return -1;
        if (momentA.isAfter(momentB)) return 1;
        return 0;
      });
      return state.set('dailyUsages', fromJS(sortedDailyAllUsages));
    }

    default:
      return state;
  }
}
