import styled from 'styled-components';

export type ColorIconProps = {
  color: string;
};

export const ColorIcon = styled.div<ColorIconProps>`
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 3px;
  vertical-align: middle;
  ${(props) => `background: ${props.color};`}
`;
