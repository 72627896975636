import colors from 'constants/colors';

export type SourceIconProps = {
  color?: string;
  size?: number;
};

export const SourceIcon = ({
  size,
  color = colors.gray850,
}: SourceIconProps) => (
  <svg
    width={size || 16}
    height={size || 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 2.87209C10.1032 2.31875 9.0499 2 7.92308 2C4.65185 2 2 4.68629 2 8C2 11.3137 4.65185 14 7.92308 14C9.0499 14 10.1032 13.6813 11 13.1279"
      stroke={color}
      strokeLinecap="round"
    />
    <path
      d="M13.4853 7.98529L13.8388 8.33884C14.0341 8.14358 14.0341 7.82699 13.8388 7.63173L13.4853 7.98529ZM6.51471 7.48529C6.23857 7.48529 6.01471 7.70914 6.01471 7.98529C6.01471 8.26143 6.23857 8.48529 6.51471 8.48529L6.51471 7.48529ZM13.1317 7.63173L11.0725 9.69097L11.7796 10.3981L13.8388 8.33884L13.1317 7.63173ZM11.0725 6.2796L13.1317 8.33884L13.8388 7.63173L11.7796 5.5725L11.0725 6.2796ZM13.4853 7.48529H6.51471L6.51471 8.48529H13.4853L13.4853 7.48529Z"
      fill={color}
    />
  </svg>
);
