import { useQuery } from '@apollo/client';
import { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import colors from 'constants/colors';
import {
  NavigationMenuActionTypes,
  useNavigationMenu,
} from 'context/navigation-menu/NavigationMenuContext';
import { apiClient } from 'modules/api-client';
import {
  ChevronDown,
  HibooLogoLarge,
  InventoryIcon,
  LogoutIcon,
  MapIcon,
  Overlay,
  QuestionMark,
  TimesIcon,
  WarningIcon,
} from 'modules/common-ui';
import { WorkspacePickerMobile } from 'modules/sidenav/components/WorkspacePicker';
import { GraphQLGroupFeatures, query } from 'modules/sidenav/gql';
import { UserStore } from 'modules/user';
import { disconnectUser, userSwitchToken } from 'modules/user/actions';
import { buildContext, useFeature } from 'modules/utils';
import { Store } from 'reducers';
import { removeFromStorage as clearFilters } from 'utils/filters';

import { FLAGS } from 'constants/flags';
import { SidebarMobileLink } from './SidebarMobileLink';
import {
  CustomLogo,
  HibooLogo,
  LogoContainer,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarWrapper,
  ToggleButton,
  WorkspaceTitle,
} from './index.css';

type StateType = {
  user: UserStore;
  renderId: number;
};

// eslint-disable-next-line max-statements
export const SideNavMobile = () => {
  const [faultOnMobile] = useFeature(FLAGS.FAULTS_ON_MOBILE);
  const { t } = useTranslation('sidenav');
  const navigate = useNavigate();
  const [isWorkspaceDrawerOpen, setIsWorkspaceDrawerOpen] = useState(false);
  const { renderId, user } = useSelector<Store, StateType>((state) => {
    return {
      token: state.user.token as string,
      user: state.user,
      renderId: state.sidenav.get('renderId') as number,
    };
  });
  const token = user?.token || '';
  const dispatch = useDispatch();
  const { loading, data, refetch } = useQuery<GraphQLGroupFeatures>(query, {
    fetchPolicy: 'no-cache',
    variables: { token },
  });

  useEffect(() => {
    refetch();
  }, [renderId, refetch]);

  const workspaceName = data ? data.viewer.group.name : '';
  const workspaces = data ? data.viewer.workspaces : [];
  const customLogoUrl =
    data && data.viewer.group.featureManagement.customLogoUrl;
  const logoUrl = customLogoUrl || HibooLogoLarge;
  const alt = `${customLogoUrl && workspaceName ? workspaceName : ''} logo`;

  const haveMultiWorkspaces = workspaces && workspaces.length > 1;
  const { state, dispatch: navigationMenuDispatch } = useNavigationMenu();
  const { show } = useIntercom();

  const logout = () => {
    dispatch(disconnectUser());

    analytics.track(
      `Signed Out`,
      {
        first_name: user.firstName,
        last_name: user.lastName,
      },
      {
        context: buildContext(user),
      },
    );

    navigate(`/login`);
  };

  const switchToken = (
    token: string,
    userId: number,
    admin: boolean,
    adminOfOrganization: boolean,
    workspaceId: number,
    group: string,
    email: string,
  ) => {
    return dispatch(
      userSwitchToken(
        token,
        userId,
        admin,
        adminOfOrganization,
        workspaceId,
        group,
        email,
      ),
    );
  };

  const onChangeWorkspace = (workspaceId: number) => {
    apiClient.user
      .switchWorkspace(token || '', { workspaceId })
      .then((response) => {
        clearFilters();
        switchToken(
          response.token,
          response.id,
          response.admin,
          response.adminOfOrganization,
          response.workspaceId,
          response.group,
          response.email,
        );
      })
      .catch(() => {});
  };

  const sidebarMobileLinks: ComponentProps<typeof SidebarMobileLink>[] = [
    {
      iconRight: haveMultiWorkspaces ? <ChevronDown /> : null,
      loading: loading && !data,
      onClick: () => setIsWorkspaceDrawerOpen(true),
      style: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '26px',
        padding: '11px 16px',
      },
      children: <WorkspaceTitle>{workspaceName}</WorkspaceTitle>,
    },
    {
      onClick: () =>
        navigationMenuDispatch({
          type: NavigationMenuActionTypes.TOGGLE_NAVIGATION_MENU,
        }),
      iconLeft: <MapIcon color={colors.gray850} />,
      link: '/map',
      children: t('sidenav.navigation.map'),
    },
    {
      onClick: () =>
        navigationMenuDispatch({
          type: NavigationMenuActionTypes.TOGGLE_NAVIGATION_MENU,
        }),
      iconLeft: <InventoryIcon color={colors.gray850} />,
      link: '/inventory',
      children: t('sidenav.navigation.inventory'),
    },
  ];

  if (faultOnMobile) {
    sidebarMobileLinks.push({
      onClick: () =>
        navigationMenuDispatch({
          type: NavigationMenuActionTypes.TOGGLE_NAVIGATION_MENU,
        }),
      iconLeft: <WarningIcon color={colors.gray850} />,
      link: '/reports/equipment-faults',
      children: t('sidenav.navigation.reports.equipmentFaults'),
    });
  }

  return (
    <>
      {state.isNavigationMenuOpen && (
        <Overlay
          style={{ zIndex: 1003 }}
          onClick={() =>
            navigationMenuDispatch({
              type: NavigationMenuActionTypes.TOGGLE_NAVIGATION_MENU,
            })
          }
        />
      )}
      <SidebarWrapper className={state.isNavigationMenuOpen ? 'open' : ''}>
        <SidebarHeader>
          <LogoContainer>
            <Link to="/map">
              {customLogoUrl ? (
                <CustomLogo src={logoUrl as string} alt={alt} />
              ) : (
                <HibooLogo>
                  <HibooLogoLarge />
                </HibooLogo>
              )}
            </Link>
          </LogoContainer>
          <ToggleButton
            onClick={() =>
              navigationMenuDispatch({
                type: NavigationMenuActionTypes.TOGGLE_NAVIGATION_MENU,
              })
            }
          >
            <TimesIcon />
          </ToggleButton>
        </SidebarHeader>

        <SidebarContent>
          {sidebarMobileLinks.map((link, index) => (
            <SidebarMobileLink key={index} {...link} />
          ))}
        </SidebarContent>

        <SidebarFooter>
          <SidebarMobileLink
            onClick={show}
            iconLeft={<QuestionMark color={colors.gray850} />}
          >
            {t('sidenav.footer.support')}
          </SidebarMobileLink>
          <SidebarMobileLink
            onClick={() => logout()}
            iconLeft={<LogoutIcon color={colors.gray850} />}
          >
            {t('translation:common.logout')}
          </SidebarMobileLink>
        </SidebarFooter>
      </SidebarWrapper>

      <WorkspacePickerMobile
        workspaceName={workspaceName}
        isOpen={isWorkspaceDrawerOpen}
        loading={loading}
        onChange={onChangeWorkspace}
        onClose={() => setIsWorkspaceDrawerOpen(false)}
        workspaces={workspaces}
      />
    </>
  );
};
