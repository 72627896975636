import styled from 'styled-components';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import colors, { categoryColors } from 'constants/colors';

// eslint-disable-next-line no-unexpected-multiline
export const Tag = styled.div<{
  bgColor: string;
  fullSize: boolean;
}>`
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 3px;
  color: ${({ bgColor }) =>
    bgColor === categoryColors.black ? colors.white : colors.gray850};
  display: flex;
  line-height: 14px;
  max-width: ${(props) => (props.fullSize ? '100%' : '80px')};
  width: fit-content;
  padding: 2px 6px;
  position: relative;
  user-select: none;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    height: 18px;
    padding: 2px 5px;
  }
`;

export const Text = styled.div<{
  showFullText?: boolean;
}>`
  font-size: 12px;
  font-weight: 400;
  max-width: inherit;
  overflow: hidden;
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  ${(props) => !props.showFullText && 'max-width: 100px'};
  margin-right: 6px;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    line-height: 13px;
  }
`;

export const Close = styled.div`
  /* display: flex; */
  /* line-height: 14px; */
  /* height: 14px; */
  font-size: 12px;
  line-height: 14px;

  transition: background 120ms ease-in 0s;
  :hover {
    background: rgba(45, 47, 48, 0.08);
    color: #4b5c7c;
  }
  :focus,
  :active {
    background: rgba(45, 47, 48, 0.16);
  }
`;
