import colors from 'constants/colors';
import styled from 'styled-components';

export const BetaInfosContainer = styled.div`
  display: flex;
  padding: 10px 8px;
  margin: 8px;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 5px;
  background: ${colors.blue50};
`;

export const Title = styled.div`
  color: ${colors.gray850};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 142.857%
  letter-spacing: 0.16px;
`;

export const Infos = styled.div`
  align-self: stretch;
  color: ${colors.gray850};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const LearnMore = styled.a`
  color: ${colors.blue400};
  &:hover {
    color: ${colors.blue300};
  }
  font-weight: 500;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
`;
