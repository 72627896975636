import styled from 'styled-components';

export const Container = styled.div`
  min-width: 24px;
`;

export const DropdownSelector = styled.div`
  padding: 0px;
  user-select: none;
  width: 24px;
  text-align: center;
`;
