import colors from 'constants/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link).attrs({
  className: 'subnav-element',
})`
  color: ${colors.gray500};

  :hover {
    color: ${colors.gray500};
    text-decoration: none;
  }

  :active {
    color: ${colors.gray500};
    text-decoration: none;
  }
`;

export const Container = styled.div`
  color: ${colors.gray500};
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  line-height: 18px;

  border-radius: 5px;
  padding: 6px;
  transition: background 120ms ease-in 0s;

  :hover {
    background-color: rgba(146, 148, 151, 0.08);
  }

  :active {
    background-color: rgba(45, 47, 48, 0.1);
  }

  :first-of-type {
    margin-left: -6px;
  }
`;
