import colors from 'constants/colors';

export type CurvedChartIconProps = {
  color?: string;
};

export const CurvedChartIcon = ({
  color = colors.gray500,
}: CurvedChartIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1.5" y="1.5" width="13" height="13" rx="2.125" stroke="#929497" />
    <path
      d="M1.5 7.99978C2.66667 6.16645 5.6 3.59978 8 7.99978C10.4 12.3998 13.3333 9.83311 14.5 7.99978"
      stroke={color}
    />
  </svg>
);
