import classNames from 'classnames';
import colors from 'constants/colors';
import hexToRgba from 'hex-to-rgba';
import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

type InputProps = {
  big?: boolean;
  veryBig?: boolean;
  large?: boolean;
  fullWidth?: boolean;
  withIcon?: boolean;
  variant: 'primary' | 'secondary';
};

const Input = styled.textarea<InputProps>`
  color: ${(props) => (props.variant === 'primary' ? '#495057' : '#000')};
  background: ${(props) =>
    props.variant === 'primary' ? colors.white : colors.lightGrey};
  border: 0.5px solid ${colors.gray300};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 8px;
  ${(props) => (props.withIcon ? 'padding-left: 26px;' : '')}
  outline: none;
  min-height: 60px;
  max-height: 300px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${(props) => (props.large ? 'min-width: 300px;' : '')}
  ${(props) => (props.fullWidth ? 'width: 100%;' : '')}
  ${(props) => (props.big ? 'height: 60px;' : '')}
  ${(props) => (props.veryBig ? 'height: 120px;' : '')}
  ::placeholder {
    color: ${colors.gray500};
  }
  :-ms-input-placeholder {
    color: ${colors.gray500};
  }
  ::-ms-input-placeholder {
    color: ${colors.gray500};
  }

  &:disabled {
    background: ${colors.shadyGrey};
    cursor: not-allowed;
  }

  &:focus {
    border-color: ${colors.blue100};
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 0.15rem ${hexToRgba(colors.blue400, 0.25)};
  }

  &.invalid {
    border-color: ${colors.red300};
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 0.15rem ${hexToRgba(colors.red300, 0.25)};
  }
`;

export const TextAreaWrapper = styled.div<{
  fullWidth: boolean;
}>`
  position: relative;
  ${(props) => (props.fullWidth ? 'width: 100%;' : '')}
`;

type TextAreaProps = {
  big?: boolean;
  veryBig?: boolean;
  fullWidth?: boolean;
  large?: boolean;
  valid?: boolean;
  variant?: 'primary' | 'secondary';
  onReset?: () => void;
  inputClassName?: string;
} & InputHTMLAttributes<HTMLTextAreaElement>;

export const TextArea = forwardRef(
  (props: TextAreaProps, ref: React.Ref<any>) => {
    const {
      big = false,
      veryBig = false,
      large = false,
      className,
      valid = true,
      fullWidth = false,
      variant = 'primary',
      onReset,
      inputClassName,
      ...extraProps
    } = props;
    return (
      <TextAreaWrapper fullWidth={fullWidth} className={className}>
        <Input
          big={big}
          className={classNames({ invalid: !valid }, inputClassName)}
          fullWidth={fullWidth}
          large={large}
          ref={ref}
          variant={variant}
          veryBig={veryBig}
          {...extraProps}
        />
      </TextAreaWrapper>
    );
  },
);
