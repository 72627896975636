import layout from 'modules/layout';
import { ReactNode } from 'react';

import {
  HeroHeaderSubtitle,
  HeroHeaderTitle,
  ImageContainer,
  Left,
} from './HeroHeader.css';

export type HeroHeaderProps = {
  title: string;
  subtitle?: string;
  imageUrl?: string;
  children?: ReactNode;
};

export const HeroHeader = ({
  title,
  subtitle = undefined,
  imageUrl = undefined,
  children = undefined,
}: HeroHeaderProps) => {
  return (
    <layout.FullPageBigHeader>
      <layout.FullPageSection>
        <Left>
          <HeroHeaderTitle>{title}</HeroHeaderTitle>
          {subtitle && <HeroHeaderSubtitle>{subtitle}</HeroHeaderSubtitle>}
          {children}
        </Left>
        {imageUrl && (
          <ImageContainer>
            <img src={imageUrl} alt={imageUrl} />
          </ImageContainer>
        )}
      </layout.FullPageSection>
    </layout.FullPageBigHeader>
  );
};
