import colors from 'constants/colors';

export type EditIconProps = {
  color?: string;
};

export const EditIcon = ({ color = colors.gray850 }: EditIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25488 8.92666L11.5721 2.60939L12.6211 3.65838L6.30386 9.97564L5.25488 8.92666Z"
      stroke={color}
    />
    <path
      d="M4.293 9.18143L6.00519 10.8936L4.20578 11.2753C4.02999 11.3126 3.87402 11.1566 3.91131 10.9808L4.293 9.18143Z"
      fill={color}
    />
    <line
      x1="2.5"
      y1="12.5"
      x2="13.5"
      y2="12.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
