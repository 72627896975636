import colors from 'constants/colors';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  background: #ffffff;
`;

export const FooterSectionContainer = styled.div``;

export const FooterSection = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid ${colors.gray300};
`;

export const FreemiumButtonWrapper = styled.div`
  margin-top: 10px;
  button {
    margin: auto;
    width: 95%;
  }
`;

export const FreemiumSubtitle = styled.div`
  color: ${colors.purple500};
  font-size: 12px;
  margin-left: 5px;
`;
