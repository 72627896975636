import colors from 'constants/colors';

export type EyeCrossedIconProps = {
  size?: number;
  color?: string;
};

export const EyeCrossedIcon = ({
  size = 16,
  color = colors.gray500,
}: EyeCrossedIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.54731 8.48193C1.48423 8.35542 1.48423 8.20654 1.54731 8.08003C2.7894 5.58911 5.18532 3.92993 7.90939 3.92993C10.6335 3.92993 13.0294 5.58911 14.2715 8.08003C14.3346 8.20654 14.3346 8.35542 14.2715 8.48193C13.0294 10.9729 10.6335 12.632 7.9094 12.632C5.18532 12.632 2.78941 10.9729 1.54731 8.48193Z"
      stroke={color}
    />
    <circle cx="8" cy="8.13208" r="2" stroke={color} />
    <path
      d="M2.70032 13.4319L13.2998 2.8324"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
