import colors from 'constants/colors';
import styled from 'styled-components';

export const InfosContainer = styled.div<{
  marginBottom?: number;
  marginTop?: number;
  fullWidth: boolean;
}>`
  background: ${colors.blue50};
  padding: 8px;
  width: ${(props) => (props.fullWidth ? '100%' : 'fit-content')};
  display: flex;
  border-radius: 5px;
  white-space: pre-wrap;
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom}px;`}
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}px;`}
`;

export const InfosIconContainer = styled.div`
  display: flex;
  margin-right: 8px;
  padding-top: 2px;
`;

export const InfosIconText = styled.div<{ fontSize?: number }>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  text-align: left;
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px;`}
`;
