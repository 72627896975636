import colors from 'constants/colors';
import { TextInput } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SearchAndLoadMultiselectOption } from './types';

const ActionBar = styled.div`
  margin-top: 4px;
  margin-left: -3px;

  span + span {
    margin-left: 8px;
  }
`;

const Separator = styled.span`
  user-select: none;
`;

const ActionWrapper = styled.div`
  padding: 8px;
`;

const Action = styled.span`
  color: ${colors.gray500};
  font-size: 12px;
  line-height: 14px;
  padding: 3px;

  cursor: pointer;
  transition: background 120ms ease-in 0s;

  :hover {
    background-color: rgba(56, 58, 60, 0.08);
    border-radius: 3px;
  }

  :active {
    background-color: rgba(146, 148, 151, 0.2);
  }

  user-select: none;
`;

const ResultsNb = styled.div`
  margin-top: 16px;
  color: ${colors.gray500};
  font-size: 12px;
`;

export const SearchAndLoadOptionsActions = ({
  searchText,
  showSelection,
  nbSelected,
  options,
  onUpdate,
  onSearch,
}: {
  searchText: string;
  showSelection: boolean;
  nbSelected: number;
  options: SearchAndLoadMultiselectOption[];
  onUpdate: (p: any) => void;
  onSearch: (p: string) => void;
}) => {
  const { t } = useTranslation('commonUi');

  const selectAll = () => {
    onUpdate(options);
  };

  const unselectAll = () => {
    onUpdate([]);
  };

  const showSelectAll = !showSelection && options.length > 0;
  const showUnselectAll = nbSelected > 0;
  return (
    <ActionWrapper>
      <TextInput
        placeholder={t('searchAndLoadMultiselect.options.searchPlaceholder')}
        value={searchText}
        onChange={(e) => onSearch(e.target.value)}
        variant="secondary"
        large
      />
      <ResultsNb>
        {showSelection
          ? t('searchAndLoadMultiselect.options.resultsNb.selected', {
              count: nbSelected,
            })
          : t('searchAndLoadMultiselect.options.resultsNb.total', {
              count: options.length,
            })}
      </ResultsNb>
      <ActionBar>
        {showSelectAll && (
          <Action
            onClick={() => {
              selectAll();
            }}
          >
            {t('searchAndLoadMultiselect.options.selectAll')}
          </Action>
        )}
        {showUnselectAll && (
          <>
            {showSelectAll && <Separator>·</Separator>}
            <Action
              onClick={() => {
                unselectAll();
              }}
            >
              {t('searchAndLoadMultiselect.options.unselectAll', {
                count: nbSelected,
              })}
            </Action>
          </>
        )}
      </ActionBar>
    </ActionWrapper>
  );
};
