import MdArrowBack from 'react-icons/lib/md/arrow-back';
import MdArrowForward from 'react-icons/lib/md/arrow-forward';

import {
  CurrentPageInput,
  Left,
  PageInfo,
  PaginationNavigationButton,
  PaginationNavigationWrapper,
  PaginationWrapper,
  Right,
  RowsPerPage,
  TotalPages,
} from './index.css';

type PaginationProps = {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageCount: number;
  gotoPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (size: number) => void;
  pageIndex: number;
  pageSize: number;
};

const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

export const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}: PaginationProps) => {
  return (
    <PaginationWrapper>
      <Left>
        <RowsPerPage
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {PAGE_SIZE_OPTIONS.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </RowsPerPage>
      </Left>
      <Right>
        <PageInfo>
          <CurrentPageInput
            type="number"
            value={pageIndex + 1}
            min={1}
            max={pageCount}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
          {'/'}
          <TotalPages>{pageCount}</TotalPages>
        </PageInfo>
        <PaginationNavigationWrapper>
          <PaginationNavigationButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <MdArrowBack />
          </PaginationNavigationButton>
          <PaginationNavigationButton
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <MdArrowForward />
          </PaginationNavigationButton>
        </PaginationNavigationWrapper>
      </Right>
    </PaginationWrapper>
  );
};
