export type LineChartIconProps = { color?: string };

export const LineChartIcon = ({ color = '#B1B4B7' }: LineChartIconProps) => (
  <svg
    width="15"
    height="8"
    viewBox="0 0 15 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 7L5.5 2.5L9.5 5.5L14 1"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
