export function parseAssetId(assetId?: string) {
  let skip = true;
  let parsedAssetId = -1;

  if (assetId) {
    skip = false;
    parsedAssetId = +assetId;
  }

  return { parsedAssetId, skip };
}

export const getAssetStatusFromGQLType = (typename: string) => {
  switch (typename) {
    case 'ArchivedAssetMetadata':
      return 'ARCHIVED';
    default:
      return 'ACTIVE';
  }
};

export const isAssetActive = (assetStatus: string) => assetStatus === 'ACTIVE';
export const isAssetArchived = (assetStatus: string) =>
  assetStatus === 'ARCHIVED';
