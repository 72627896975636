import React from 'react';
import Select, { GroupBase, StylesConfig } from 'react-select';

import { Category } from 'modules/common-ui';

import { SingleOption } from './index.css';
import { buildCategoryOptions, buildCustomStyle } from './utils';

export type CategoryType = {
  id: string;
  name: string;
};

export type CategoryPickerProps = {
  value?: CategoryType | null;
  id?: string;
  categories: CategoryType[];
  isValid?: boolean;
  onChange: (value: CategoryType | null) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  placeholder: string;
  styles?: Partial<StylesConfig<CategoryType, false, GroupBase<CategoryType>>>;
  showIndicator?: boolean;
  showSelectedCategoryCSSTransition?: boolean;
  [propName: string]: any;
};

export const CategoryPicker = ({
  value,
  id,
  categories,
  isValid = true,
  onChange,
  onBlur,
  placeholder,
  styles = {},
  showIndicator = false,
  showSelectedCategoryCSSTransition = false,
  ...rest
}: CategoryPickerProps) => {
  const categoryOptions = buildCategoryOptions(categories);

  const Option = ({ innerProps, isFocused, ...props }: any) => {
    return (
      <SingleOption {...innerProps} isFocused={isFocused}>
        <Category fullSize category={props.data} isOption={true} />
      </SingleOption>
    );
  };

  const SingleValue = (props: any) => (
    <Category
      fullSize
      category={props.data}
      showTextTransition={showSelectedCategoryCSSTransition}
    />
  );

  const indicatorStyle = showIndicator
    ? {
        valueContainer: (provided: any) => ({
          ...provided,
          padding: '0 4px',
        }),
        dropdownIndicator: (provided: any) => ({
          ...provided,
          paddingTop: '4px',
          paddingBottom: '4px',
        }),
        clearIndicator: (provided: any) => ({
          ...provided,
          paddingTop: '4px',
          paddingBottom: '4px',
        }),
        indicatorsContainer: (provided: any) => ({
          ...provided,
          display: 'flex',
        }),
      }
    : {};

  return (
    <Select
      inputId={id}
      components={{ Option, SingleValue }}
      isClearable={true}
      maxMenuHeight={300}
      closeMenuOnSelect={true}
      value={value}
      options={categoryOptions}
      menuPlacement="auto"
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      styles={{ ...buildCustomStyle(!isValid), ...indicatorStyle, ...styles }}
      {...rest}
    />
  );
};
