import colors from 'constants/colors';

export type FormatNumberIconProps = {
  color?: string;
};

export const FormatNumberIcon = ({
  color = colors.gray850,
}: FormatNumberIconProps) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.62759 0C4.11741 0 3.70391 0.413505 3.70391 0.92358V2.90079H1.72679C1.21661 2.90079 0.803101 3.31429 0.803101 3.82439C0.803101 4.33447 1.21661 4.74798 1.72679 4.74798H3.70391V7.25197H1.72679C1.21661 7.25197 0.803101 7.6655 0.803101 8.17558C0.803101 8.68569 1.21661 9.09917 1.72679 9.09917H3.70391V11.0763C3.70391 11.5864 4.11741 11.9999 4.62759 11.9999C5.13736 11.9999 5.55087 11.5864 5.55087 11.0763V9.09917H8.05491V11.0763C8.05491 11.5864 8.46842 11.9999 8.97861 11.9999C9.48879 11.9999 9.90227 11.5864 9.90227 11.0763V9.09917H11.8794C12.3896 9.09917 12.8031 8.68569 12.8031 8.17558C12.8031 7.6655 12.3896 7.25197 11.8794 7.25197H9.90227V4.74798H11.8794C12.3896 4.74798 12.8031 4.33447 12.8031 3.82439C12.8031 3.31429 12.3896 2.90079 11.8794 2.90079H9.90227V0.92358C9.90227 0.413505 9.48879 0 8.97861 0C8.46842 0 8.05491 0.413505 8.05491 0.92358V2.90079H5.55087V0.92358C5.55087 0.413505 5.13736 0 4.62759 0ZM5.55087 7.25197V4.74798H8.05491V7.25197H5.55087Z"
      fill={color}
    />
  </svg>
);
