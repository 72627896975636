import { FleetSegment } from 'modules/analytics/components/AssetFilter/types';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption } from '../Dropdown';
import { EllipsisIcon } from '../Icons';
import { Container, DropdownSelector } from './SegmentActions.css';

export enum SegmentAction {
  EDIT = 'EDIT_SEGMENT',
  DELETE = 'DELETE_SEGMENT',
}

type SegmentActionsProps = {
  onTrigger: (action: SegmentAction, segment: FleetSegment) => void;
  segment: FleetSegment;
  isLastElement: boolean;
  segmentIsHover: boolean;
};

export const SegmentActions = ({
  onTrigger,
  segment,
  isLastElement,
  segmentIsHover,
}: SegmentActionsProps) => {
  const { t } = useTranslation('fleetSegment');

  const options: Array<DropdownOption> = [
    {
      label: t('actions.edit'),
      value: SegmentAction.EDIT,
    },
    {
      label: t('actions.delete'),
      value: SegmentAction.DELETE,
      variant: 'danger',
    },
  ];

  const onChange = (value: SegmentAction) => {
    onTrigger(value, segment);
  };

  return (
    <Container>
      {segmentIsHover && (
        <Dropdown
          options={options}
          value={null}
          onChange={onChange}
          selectStyle={{
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
            height: 'auto',
          }}
          customSelector={
            <DropdownSelector>
              <EllipsisIcon size={16} />
            </DropdownSelector>
          }
          align="right"
          openTop={isLastElement}
        />
      )}
    </Container>
  );
};
