export type CheckOutlineIconProps = { color?: string; size?: number };

export const CheckOutlineIcon = ({ color, size }: CheckOutlineIconProps) => (
  <svg
    fill="none"
    stroke={color || 'none'}
    height={size || 10}
    viewBox="0 0 24 24"
    width={size || 6}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="11.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color || 'none'}
      d="M6 12.4506L7.2 11.2967L10.2857 14.2637L16.8 8L18 9.15385L10.2857 16.5714L6 12.4506Z"
    />
  </svg>
);
