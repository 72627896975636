import config from 'config';
import { apiClient } from 'modules/api-client';

export async function procoreGenerateAuthorizeUrl(
  path: string,
  urlParams?: {
    [key: string]: string;
  },
) {
  const { clientId } = await apiClient.oauth.getClientIdProcorePublic();
  const params = Object.keys(urlParams || {})
    .map((key) => {
      if (key && urlParams && urlParams[key]) {
        return `${key}=${urlParams[key]}`;
      }
      return '';
    })
    .filter((k) => !!k)
    .join('&');

  const callbackOriginURL = `${window.location.origin}${path}${
    params ? `?${params}` : ''
  }`;
  const encodedCallbackURL = window.encodeURIComponent(callbackOriginURL);
  const url = `https://login${
    config.env !== 'production' ? '-sandbox' : ''
  }.procore.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodedCallbackURL}`;

  return url;
}
