import styled from 'styled-components';

import colors from 'constants/colors';

const BTN_HEIGHT = '36px';

const places = {
  topLeft: `bottom: ${BTN_HEIGHT}; right: 0;`,
  topRight: `bottom: ${BTN_HEIGHT}; left: 0;`,
  bottomLeft: `top: ${BTN_HEIGHT}; right: 0;`,
  bottomRight: `top: ${BTN_HEIGHT}; left: 0;`,
};

export const FreemiumTooltipWrapper = styled.div<{
  place?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
}>`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 9999;
  min-width: 200px;
  max-width: 600px;
  width: max-content;
  box-shadow: 0px 0px 0px rgba(15, 15, 15, 0.05),
    0px 3px 6px rgba(15, 15, 15, 0.1), 0px 9px 24px rgba(15, 15, 15, 0.2);
  border: 1px solid ${colors.gray300};
  background: ${colors.white};
  display: flex-start;
  flex-direction: column;
  padding: 8px 10px 8px 10px;
  overflow-y: auto;
  border-radius: 5px;
  :hover {
    opacity: 1 !important;
    visibility: visible;
  }
  a,
  a:hover,
  a:visited,
  a:active {
    color: inherit;
    text-decoration: none;
  }

  ${(props) => (props.place ? places[props.place] : '')}

  transition: all 0.5s ease-in-out;
`;

export const FreemiumTooltipTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${colors.gray850};
`;

export const FreemiumTooltipDescription = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 26px;
  color: ${colors.gray500};
  margin-bottom: 10px;
`;
