import { categoryColors } from 'constants/colors';
import {
  AssetSharingInfos,
  AssetSharingInfosModelFields,
} from 'modules/sharing/models/AssetSharingInfos/AssetSharingInfos.model';

type Category = {
  id: number;
  name: string;
  color: keyof typeof categoryColors;
};

export const AssetFoundStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
} as const;

type AssetFoundFields = {
  id: number;
  name: string;
  make: string;
  model: string;
  status: keyof typeof AssetFoundStatus;
  category: Category | null;
  sharingInfos: AssetSharingInfosModelFields | null;
};

export class AssetFound {
  public readonly id: AssetFoundFields['id'];
  public readonly name: AssetFoundFields['name'];
  public readonly make: AssetFoundFields['make'];
  public readonly model: AssetFoundFields['model'];
  public readonly status: AssetFoundFields['status'];
  public readonly category: AssetFoundFields['category'];
  public readonly sharingInfos: AssetSharingInfos | null;

  constructor(fields: AssetFoundFields) {
    this.id = fields.id;
    this.name = fields.name;
    this.make = fields.make;
    this.model = fields.model;
    this.status = fields.status;
    this.category = fields.category;
    this.sharingInfos = fields.sharingInfos
      ? new AssetSharingInfos(fields.sharingInfos)
      : null;
  }

  isArchived(): boolean {
    return this.status === AssetFoundStatus.ARCHIVED;
  }

  toJSON(): AssetFoundFields & { type: 'asset' } {
    return {
      id: this.id,
      name: this.name,
      make: this.make,
      model: this.model,
      status: this.status,
      category: this.category,
      sharingInfos: this.sharingInfos
        ? {
            sharedBy: this.sharingInfos.sharedBy,
            sharedWith: this.sharingInfos.sharedWith,
          }
        : null,
      type: 'asset',
    };
  }
}
