export type ChevronUpProps = {
  color?: string;
};

export const ChevronUpIcon = ({ color }: ChevronUpProps) => (
  <svg
    width="20"
    height="11"
    viewBox="0 0 20 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.85721 9.73582L10.3001 1.29297L18.7429 9.73583"
      stroke={color || '#2D2F30'}
      strokeWidth="1.82929"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
