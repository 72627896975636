import FileSaver from 'file-saver';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type UseExporterConfig = {
  filename?: string;
  sheetName?: string;
  toastMessages?: boolean;
  exportInProgresToast?: string;
  exportFinishedToast?: string;
};

type ExportAsyncType = (data: any) => Promise<void>;

// you need to convert the Excel data file to an ArrayBuffer.
// https://stackoverflow.com/questions/34993292/how-to-save-xlsx-data-to-file-as-a-blob

function stringTo2ArrayBuffer(s: string) {
  const buf: ArrayBuffer = new ArrayBuffer(s.length);
  const view: Uint8Array = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

const exportXLSX = async (sheetName: string, filename: string, data: any) => {
  const XLSX = await import('node-xlsx');
  const buffer = XLSX.build([{ name: sheetName, data, options: {} }]);
  FileSaver.saveAs(
    new Blob([stringTo2ArrayBuffer(buffer.toString())], {
      type: 'application/vnd.ms-excel',
    }),
    `${filename}.xlsx`,
  );
};

export const useExporter = (
  config?: UseExporterConfig,
): [boolean, ExportAsyncType] => {
  const [t] = useTranslation('translation');

  const [loading, setLoading] = useState(false);

  const {
    filename = `Export_${moment().format('l')}.xlsx`,
    sheetName = 'Sheet 1',
    toastMessages = true,
    exportInProgresToast,
    exportFinishedToast,
  } = config || {};

  const exportInProgresToastMsg = exportInProgresToast || t('common.exporting');
  const exportFinishedToastMsg =
    exportFinishedToast || t('common.exportFinished');

  const exportAsync = useCallback(
    async (data: any) => {
      setLoading(true);
      if (toastMessages) {
        toast.success(exportInProgresToastMsg, {
          position: 'top-right',
        });
      }

      await exportXLSX(sheetName, filename, data);

      setLoading(false);
      if (toastMessages) {
        toast.success(exportFinishedToastMsg, {
          position: 'top-right',
        });
      }
    },
    [
      filename,
      sheetName,
      exportInProgresToastMsg,
      exportFinishedToastMsg,
      toastMessages,
    ],
  );

  return [loading, exportAsync];
};
