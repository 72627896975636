import colors from 'constants/colors';

export type AppStoreIconProps = {
  size?: number;
  color?: string;
};

export const AppStoreIcon = ({
  size = 16,
  color = colors.gray500,
}: AppStoreIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 16 16`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="2" r="1.5" fill={color} />
    <circle cx="7" cy="2" r="1.5" fill={color} />
    <circle cx="12" cy="2" r="1.5" fill={color} />

    <circle cx="2" cy="7" r="1.5" fill={color} />
    <circle cx="7" cy="7" r="1.5" fill={color} />
    <circle cx="12" cy="7" r="1.5" fill={color} />

    <circle cx="2" cy="12" r="1.5" fill={color} />
    <circle cx="7" cy="12" r="1.5" fill={color} />
    <circle cx="12" cy="12" r="1.5" fill={color} />
  </svg>
);
