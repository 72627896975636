import React from 'react';

export type ExternalLinkIconProps = {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
};

export const ExternalLinkIcon = ({
  size,
  color,
  style,
}: ExternalLinkIconProps) => (
  <svg
    style={style}
    height={size || 16}
    width={size || 16}
    viewBox="0 0 16 16"
    fill={color || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 2V5M14 2H11M14 2L5.6 10.4M14 8V11.6C14 12.9255 12.9255 14 11.6 14H4.4C3.07452 14 2 12.9255 2 11.6V4.4C2 3.07452 3.07452 2 4.4 2H8"
      stroke="black"
    />
  </svg>
);
