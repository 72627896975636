export type AssetChronologyAction =
  | ReturnType<typeof filter>
  | ReturnType<typeof setVisibleColumns>;

export const ASSET_CHRONOLOGY = {
  FILTER: 'FILTER' as const,
  RECEIVED_CHRONOLOGY_DATA: 'RECEIVED_CHRONOLOGY_DATA' as const,
  SET_VISIBLE_COLUMNS: 'SET_VISIBLE_COLUMNS' as const,
};

export function filter(filters: { [key: string]: any }) {
  return {
    type: ASSET_CHRONOLOGY.FILTER,
    filters,
  };
}

export function setVisibleColumns(columns: string[]) {
  return {
    type: ASSET_CHRONOLOGY.SET_VISIBLE_COLUMNS,
    columns,
  };
}
