import styled from 'styled-components';

export type CardTitleProps = {
  big?: boolean;
  bigAndBold?: boolean;
};

export const CardTitle = styled.div<CardTitleProps>`
  color: #2d2f30;
  font-size: ${(props) => (props.big || props.bigAndBold ? '16px' : '14px')};
  line-height: ${(props) => (props.big || props.bigAndBold ? '22px' : '18px')};
  font-weight: ${(props) => (props.bigAndBold ? '700' : '500')};
  display: flex;
  gap: 5px;
  align-items: center;
`;
