import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StarIcon } from 'modules/common-ui';
import { Store } from 'reducers';

import { Step, Stepper } from '../stepper';
import { HeaderContainer, Subtitle, Title } from './index.css';

export const Header = () => {
  const { t } = useTranslation('onboarding');

  const user = useSelector((state: Store) => state.user);

  const steps: Step[] = [
    {
      index: 1,
      label: 1,
      title: t('onboarding.onboardingSteps.addYourFirstDataSource'),
    },
    {
      index: 2,
      label: 2,
      title: t('onboarding.onboardingSteps.setupYourFleet'),
    },
    {
      index: 3,
      label: 3,
      title: t('onboarding.onboardingSteps.accessYourAnalytics'),
    },
    {
      index: 4,
      label: <StarIcon />,
      title: t('onboarding.onboardingSteps.takeBetterDecision'),
    },
  ];

  return (
    <HeaderContainer>
      <Title>{t('onboarding.pagetitle', { firstName: user.firstName })}</Title>
      <Subtitle>{t('onboarding.pageSubtitle')}</Subtitle>
      <Stepper steps={steps} />
    </HeaderContainer>
  );
};
