import { Provider } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { IntercomProvider } from 'react-use-intercom';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import persistState from 'redux-localstorage';
import { createTracker } from 'redux-segment';
import thunk from 'redux-thunk';

import { INTERCOM_TOKEN, REDUX_PERSIST_STATE_KEY } from 'config/constants';
import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import { ContextProviders } from 'context/ContextProviders';

// Global lib configuration
import './charts';
import './modules/analytics/lib/highcharts';
import './moment.config';

import { RouterWrapper } from './RouterWrapper';
import { reducers } from './reducers';

const tracker = createTracker();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(
  applyMiddleware(thunk, tracker),
  persistState(['user'], {
    key: REDUX_PERSIST_STATE_KEY,
    slicer: (paths) => (state) => {
      const subset = {};

      paths.forEach((path) => {
        // ensure we never persist the pending or error status
        const forgettableProperties = ['pending', 'error'];

        subset[path] = { ...state[path] };

        if (subset[path] && subset[path].hasOwnProperty) {
          forgettableProperties.forEach((prop) => {
            if (Object.prototype.hasOwnProperty.call(subset[path], prop)) {
              subset[path][prop] = null;
            }
          });
        }
      });

      return subset;
    },
    deserialize: (serializedData) => {
      const deserializedData = JSON.parse(serializedData);

      return deserializedData;
    },
  }),
);

const store = createStore(
  combineReducers({
    ...reducers,
  }),
  enhancer,
);

// Strict mode is disabled for now due to the following issue:
// - tooltips are not working properly (they stay visible after the mouse leaves the element)
const App = function App() {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_START });

  return (
    // <StrictMode>
    <Provider store={store}>
      <ContextProviders>
        <IntercomProvider
          appId={INTERCOM_TOKEN}
          autoBoot
          autoBootProps={{ hideDefaultLauncher: isMobile }}
        >
          <RouterWrapper />
        </IntercomProvider>
      </ContextProviders>
    </Provider>
    // </StrictMode>
  );
};

export default App;
