export const DocumentIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.2 2C1.54 2 1.006 2.54 1.006 3.2L1 12.8C1 13.46 1.534 14 2.194 14H9.4C10.06 14 10.6 13.46 10.6 12.8V5.6L7 2H2.2ZM6.55 6.2C6.46716 6.2 6.4 6.13284 6.4 6.05V3.26213C6.4 3.1285 6.56157 3.06157 6.65607 3.15607L9.44393 5.94393C9.53843 6.03843 9.4715 6.2 9.33787 6.2H6.55Z"
        fill={color || '#929497'}
      />
    </svg>
  );
};
