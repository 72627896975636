import styled from 'styled-components';

import colors from 'constants/colors';

export const Text = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: center;

  span {
    font-weight: 500;
  }
`;

export const SupportText = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 12px;
  a {
    color: ${colors.gray500};
    text-decoration: underline;
  }
`;
