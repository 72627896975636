import moment from 'moment';

export type PricingPlanLabel =
  | 'noplan'
  | 'freemium'
  | 'starter'
  | 'pro'
  | 'enterprise';

export class PricingPlan {
  constructor(
    private pricingPlan: string,
    private freeTrial: boolean,
    private freeTrialStart: Date | null,
    private freeTrialEnd: Date | null,
  ) {}

  getPricingPlan(): PricingPlanLabel {
    const pricingPlanMap = new Map<string, PricingPlanLabel>([
      ['enterprise', 'enterprise'],
      ['pro', 'pro'],
      ['starter', 'starter'],
      ['freemium', 'freemium'],
    ]);

    return pricingPlanMap.get(this.pricingPlan) || 'noplan';
  }

  isFreemium(): boolean {
    return this.pricingPlan === 'freemium';
  }

  isEnterprise(): boolean {
    return this.pricingPlan === 'enterprise';
  }

  isFreeTrial(): boolean {
    return this.freeTrial;
  }

  isFreeTrialActive(): boolean {
    if (!this.isFreeTrial()) return false;
    if (this.freeTrialStart) {
      const startInThePast = moment(this.freeTrialStart).isBefore(moment());
      if (!startInThePast) return false;
    }
    if (this.freeTrialEnd) {
      return moment(this.freeTrialEnd).isAfter(moment());
    }
    return true;
  }

  isFreeTrialOver(): boolean {
    return !!(
      this.freeTrialEnd && moment(this.freeTrialEnd).isBefore(moment())
    );
  }

  isUnlimitedFreeTrial(): boolean {
    return this.isFreeTrial() && !this.freeTrialEnd;
  }

  getFreeTrialRemainingDays(): number | null {
    if (this.freeTrialEnd) {
      const endDate = moment(this.freeTrialEnd);
      const todayDate = moment();
      return endDate.diff(todayDate, 'days');
    }
    return null;
  }
}
