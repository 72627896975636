import colors from 'constants/colors';

export type BackArrowIconProps = { color?: string; size?: number };

export const BackArrowIcon = ({ color, size }: BackArrowIconProps) => (
  <svg
    width={size || '24'}
    height={size || '24'}
    viewBox="0 0 24 24"
    fill={color || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 18L7.5 12L13.5 6"
      stroke={colors.gray850}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
