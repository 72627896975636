export type AssetSharingInfosModelFields = {
  sharedBy: {
    id: number;
    organizationName: string;
  } | null;
  sharedWith: {
    id: number;
    organizationName: string;
  }[];
};

export class AssetSharingInfos {
  public readonly sharedBy: AssetSharingInfosModelFields['sharedBy'];
  public readonly sharedWith: AssetSharingInfosModelFields['sharedWith'];

  constructor(props: AssetSharingInfosModelFields) {
    this.sharedBy = props.sharedBy;
    this.sharedWith = props.sharedWith;
  }

  isShared(): boolean {
    return this.isSharedWithSomeBeneficiaries() || this.isSharedByItsOwner();
  }

  isSharedWithSomeBeneficiaries(): boolean {
    return this.sharedWith.length > 0;
  }

  isSharedByItsOwner(): boolean {
    return Boolean(this.sharedBy);
  }

  getOwnerOrganizationName(): string {
    return this.sharedBy?.organizationName || '';
  }

  getBeneficiariesOrganizationNames(): string[] {
    return this.sharedWith.map((beneficiary) => beneficiary.organizationName);
  }
}
