import colors from 'constants/colors';

export type HamburgerIconProps = { size?: number; color?: string };

export const HamburgerIcon = ({
  color = colors.gray850,
  size,
}: HamburgerIconProps) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill={color || 'currentColor'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="5.25" width="18" height="1.5" rx="0.75" fill={color} />
    <rect x="3" y="11.25" width="18" height="1.5" rx="0.75" fill={color} />
    <rect x="3" y="17.25" width="18" height="1.5" rx="0.75" fill={color} />
  </svg>
);
