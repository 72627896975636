import colors from 'constants/colors';
import { TYPEFORM_FREEMIUM_LINKS } from 'constants/externalLinks';
import { GraphQLUser } from 'modules/types/graphql';
import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'reducers';

import i18n from 'i18n';
import { ArrowUpIconFreemium } from 'modules/common-ui';
import { StyledButton } from 'modules/common-ui/components/Button/index.css';

import {
  FreemiumTooltipDescription,
  FreemiumTooltipTitle,
  FreemiumTooltipWrapper,
} from './index.css';

export type FreemiumTooltip2Props = {
  id?: string;
  className?: string;
  title?: string;
  place?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  description?: string;
  buttonLabel?: string;
};

export const FreemiumTooltip2 = React.forwardRef(
  (props: FreemiumTooltip2Props, ref: React.Ref<any>) => {
    const { id, className, title, place, description, buttonLabel } = props;

    const userLanguage = i18n.resolvedLanguage;
    const pricingLinkConstant =
      userLanguage === 'fr'
        ? TYPEFORM_FREEMIUM_LINKS.FREEMIUM_GET_IN_TOUCH_FR
        : TYPEFORM_FREEMIUM_LINKS.FREEMIUM_GET_IN_TOUCH_EN;
    const user = useSelector<Store>((state) => state.user) as GraphQLUser & {
      organizationName: string;
    };
    let pricingLink;

    if (user && pricingLinkConstant) {
      pricingLink = `${pricingLinkConstant}firstname=${user.firstName}&email=${user.email}&organization=${user.organizationName}`;
    }
    return (
      <FreemiumTooltipWrapper place={place} className={className}>
        <FreemiumTooltipTitle>{title}</FreemiumTooltipTitle>
        <FreemiumTooltipDescription>{description}</FreemiumTooltipDescription>
        <a href={pricingLink} target="_blank" rel="noopener noreferrer">
          <StyledButton
            id={`${id}-freemium`}
            block={false}
            disabled={false}
            ref={ref}
            size={'sm'}
            type={'button'}
            variant={'outline-freemium'}
            color={colors.purple500}
          >
            <ArrowUpIconFreemium />

            {buttonLabel}
          </StyledButton>
        </a>
      </FreemiumTooltipWrapper>
    );
  },
);

FreemiumTooltip2.displayName = 'Button';

export default FreemiumTooltip2;
