import styled from 'styled-components';

import colors from 'constants/colors';
import { HeaderLeft } from 'modules/common-ui/components/Drawer/index.css';

export const WSHeaderLeft = styled(HeaderLeft)`
  justify-content: 'flex-start';
  font-size: '20px';
  line-height: '26px';
  font-weight: 500;
  color: ${colors.gray800};
`;
