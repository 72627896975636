import { fromJS } from 'immutable';

import { ActionType, SUBNAV } from '../actions';

const initialState = fromJS({
  renderId: 1,
});

export default function subnavReducer(
  state = initialState,
  action: ActionType,
) {
  switch (action.type) {
    case SUBNAV.REFRESH: {
      return state.set('renderId', state.get('renderId') + 1);
    }

    default:
      return state;
  }
}
