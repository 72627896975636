import i18next from 'i18next';
import moment from 'moment';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import colors from 'constants/colors';
import segmentTrackedEvents from 'constants/segmentTrackedEvents';

import { apiClient, UserApi } from 'modules/api-client';
import layout from 'modules/layout';
import { userLoginSuccess } from 'modules/user/actions';
import { buildContext, isHibooUser } from 'modules/utils';

import {
  Button,
  Card,
  EyeCrossedIcon,
  EyeIcon,
  Field,
  FieldInputWrapper,
  FieldName,
  FieldValue,
  HibooIcon,
  HibooLogoLarge,
  TextInput,
} from 'modules/common-ui';
import { USER_UNIT } from 'utils/unit';

import { FormErrorMessage } from 'modules/common-ui/components/FormErrorMessage/FormErrorMessage';
import { IconWrapper } from 'modules/common-ui/components/IconWrapper/IconWrapper';
import { useNavigate } from 'react-router-dom';
import {
  Footer,
  Form,
  Header,
  LogoWrapper,
  StyledLink,
  WhatsHibooLink,
} from '../index.css';

export type LoginProps = {
  userLoginSuccess: typeof userLoginSuccess;
};

const LoginComponent = ({ userLoginSuccess }: LoginProps) => {
  const navigate = useNavigate();
  const [t] = useTranslation('user');
  const [form, setForm] = useState({ email: '', password: '' });
  const [pending, setPending] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const onUserLoginSuccess = (user: UserApi) => {
    i18next.changeLanguage(user.language);
    moment.locale(i18next.language);
    localStorage.setItem(USER_UNIT, user.unit);

    userLoginSuccess(user);

    if (!isHibooUser(user.email)) {
      analytics.track(
        segmentTrackedEvents.USER_EVENTS.SIGNED_IN,
        {
          first_name: user.firstName,
          last_name: user.lastName,
          integration_count: user.integrationCount,
          asset_count: user.assetCount,
          user_job: user.jobTitle,
        },
        {
          context: buildContext(user),
        },
      );
    }

    setPending(false);

    // const urlParams = new URLSearchParams(window.location.search);
    let path = null;
    if (window.location.search.startsWith('?r=')) {
      path = window.location.search.substr(3);
    }

    if (path && path.length) {
      navigate(path);
    } else {
      navigate('/');
    }
  };

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.email && form.password && !pending) {
      setPending(true);
      try {
        const userData = await apiClient.user.login(form.email, form.password);
        onUserLoginSuccess(userData);
      } catch (err) {
        setError((err as Error).message);
        setPending(false);
      }
    }
  };

  return (
    <layout.Page
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LogoWrapper>
        <HibooLogoLarge />
      </LogoWrapper>
      <Card
        style={{
          alignItems: 'center',
          flex: 'unset',
          maxWidth: '400px',
          padding: '24px',
          width: 'calc(100% - 32px)',
        }}
      >
        <HibooIcon />
        <Header>{t('user.login.header')}</Header>
        <Form data-testid="login-form" onSubmit={submitForm}>
          <Field>
            <FieldName>
              <label htmlFor="login-username" className="col-form-label">
                {t('user.login.form.username.label')}
              </label>
            </FieldName>
            <FieldValue>
              <FieldInputWrapper width="100%">
                <TextInput
                  id="login-username"
                  name="email"
                  value={form.email}
                  placeholder={t('user.login.form.username.placeholder')}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  required
                  autoFocus
                  fullWidth
                  type="email"
                  variant="secondary"
                  autoComplete="email"
                  data-testid="username"
                />
              </FieldInputWrapper>
            </FieldValue>
          </Field>
          <Field>
            <FieldName>
              <label htmlFor="login-password" className="col-form-label">
                {t('user.login.form.password.label')}
              </label>
            </FieldName>
            <FieldValue>
              <FieldInputWrapper width="100%">
                <TextInput
                  id="login-password"
                  name="password"
                  value={form.password}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  style={{
                    marginLeft: '0px',
                    paddingRight: '30px',
                  }}
                  placeholder={t('user.login.form.password.placeholder')}
                  required
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  variant="secondary"
                  autoComplete="password"
                  data-testid="password"
                />
                <IconWrapper
                  active={showPassword}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? <EyeCrossedIcon /> : <EyeIcon />}
                </IconWrapper>
              </FieldInputWrapper>
            </FieldValue>
          </Field>
          {error && <FormErrorMessage>{error}</FormErrorMessage>}
          <Button
            block
            variant="outline-primary"
            type="submit"
            disabled={!form.email || !form.password || pending}
            style={{ marginTop: '8px' }}
          >
            {!pending
              ? t('user.login.form.submit.label')
              : t('user.login.form.submit.loading')}
          </Button>
          <StyledLink to={`/reset-password?email=${form.email}`}>
            {t('user.login.resetPassword')}
          </StyledLink>
        </Form>
        <Footer>
          <WhatsHibooLink href="https://www.hiboo.io" target="_blank">
            <HibooIcon small color={colors.gray500} />
            <span>{t('whatsHiboo')}</span>
          </WhatsHibooLink>
        </Footer>
      </Card>
    </layout.Page>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators({ userLoginSuccess }, dispatch);

export const Login = connect(undefined, mapDispatchToProps)(LoginComponent);
