import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import styled from 'styled-components';

export type FieldInputWrapperProps = {
  width?: string;
};

export const FieldInputWrapper = styled.div<FieldInputWrapperProps>`
  position: relative;
  @media (min-width: ${BREAKPOINT_DESKTOP_START}px) {
    ${(props) => (props.width ? `width: ${props.width}` : `width: 25rem`)};
  }
`;
