import React from 'react';

import { To } from 'react-router-dom';
import {
  IconLeftWrapper,
  IconRightWrapper,
  StyledInlineLink,
} from './index.css';

export type ExternalLinkProps = {
  children: React.ReactNode;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  onClick?: () => void;
  small?: boolean;
  style?: React.CSSProperties;
  target?: string;
  testId?: string;
  to?: To;
};

export const InlineLink: React.FC<
  React.PropsWithChildren<ExternalLinkProps>
> = ({
  children,
  iconLeft,
  iconRight,
  onClick,
  small,
  style,
  target = '_blank',
  testId,
  to,
}) => {
  if (!to) {
    return (
      <StyledInlineLink
        as="div"
        id={testId}
        data-testid={testId}
        onClick={onClick}
        small={small}
        style={style}
      >
        {iconLeft && <IconLeftWrapper>{iconLeft}</IconLeftWrapper>}
        {children}
        {iconRight && <IconRightWrapper>{iconRight}</IconRightWrapper>}
      </StyledInlineLink>
    );
  }

  if (typeof to === 'string' && /^https?:\/\//.test(to)) {
    return (
      <StyledInlineLink
        as="a"
        id={testId}
        data-testid={testId}
        href={to}
        onClick={onClick}
        small={small}
        style={style}
        target={target}
      >
        {iconLeft && <IconLeftWrapper>{iconLeft}</IconLeftWrapper>}
        {children}
        {iconRight && <IconRightWrapper>{iconRight}</IconRightWrapper>}
      </StyledInlineLink>
    );
  }

  return (
    <StyledInlineLink
      id={testId}
      data-testid={testId}
      onClick={onClick}
      small={small}
      style={style}
      target={target}
      to={to as any}
    >
      {iconLeft && <IconLeftWrapper>{iconLeft}</IconLeftWrapper>}
      {children}
      {iconRight && <IconRightWrapper>{iconRight}</IconRightWrapper>}
    </StyledInlineLink>
  );
};
