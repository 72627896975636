import { DoubleChevronRightIcon, InlineLink } from 'modules/common-ui';
import layout from 'modules/layout';
import { ReactNode } from 'react';
import { SidePanelHideContainer } from './SidePanel.css';

export type SidePanelProps = {
  display: boolean;
  onHide: () => void;
  children?: ReactNode;
};

/**
 * example:
 * <Page withSidePanel>
 *   <layout.MainPanelContainer> // if you use regular
 *   <layout.MainPanel>
 *      .... what you would do in the main frame
 *   </layout.MainPanel>
 *   </layout.MainPanelContainer> // if you use regular
 *
 *   <SidePanel display={valueFromState} onHide={setterFromState}>
 *     .... whatever you want
 *   </SidePanel>
 * </Page>
 * @param param0
 * @returns
 */
export const SidePanel = ({ display, onHide, children }: SidePanelProps) => {
  return (
    <layout.SidePanelContainer show={display}>
      <SidePanelHideContainer>
        <InlineLink>
          {' '}
          <DoubleChevronRightIcon onClick={onHide} />
        </InlineLink>
      </SidePanelHideContainer>
      {children}
    </layout.SidePanelContainer>
  );
};
