import colors from 'constants/colors';
import { Tooltip } from 'modules/common-ui';

import { StyledTag } from './Tag.css';

export type TagProps = {
  label: string;
  color?: string;
  height?: number;
  maxWidth?: number;
  variant?: 'outline';
  tooltip?: string;
};

export const Tag = ({
  color = colors.black,
  height = 16,
  maxWidth,
  variant,
  label,
  tooltip,
}: TagProps) => {
  if (tooltip) {
    const tooltipId = `tag-${Math.round(Math.random() * 100000)}`;
    return (
      <div data-for={tooltipId} data-tip={tooltip}>
        <Tooltip id={tooltipId} />
        <StyledTag
          color={color}
          height={height}
          maxWidth={maxWidth}
          variant={variant}
          translate="no"
        >
          {label}
        </StyledTag>
      </div>
    );
  }

  return (
    <StyledTag
      color={color}
      height={height}
      maxWidth={maxWidth}
      variant={variant}
      translate="no"
    >
      {label}
    </StyledTag>
  );
};
