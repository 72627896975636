import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import styled from 'styled-components';

export const FieldValue = styled.div`
  @media (min-width: ${BREAKPOINT_DESKTOP_START}px) {
    .geocoder {
      width: 25rem;
    }
  }
`;
