import colors from 'constants/colors';
import styled from 'styled-components';

export const Left = styled.div`
  flex: 1;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 32px;
`;

export const HeroHeaderTitle = styled.div`
  color: ${colors.gray850};
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 16px;
`;

export const HeroHeaderSubtitle = styled.div`
  color: ${colors.gray850};
  font-size: 14px;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    height: 180px;
  }
`;
