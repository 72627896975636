import styled from 'styled-components';

import { DropdownMenuItem } from 'modules/common-ui/styled-components/index.css';

export const SingleOption = styled(DropdownMenuItem)<{ isFocused?: boolean }>`
  align-items: center;
  display: flex;
  font-weight: normal;
  min-height: 28px;
  padding: 4px 8px;
  white-space: pre;

  ${(props) => props.isFocused && ` background: rgba(45, 47, 48, 0.08);`};
`;
