import { Box, Button } from './index.css';

export type SwitchProps = {
  disableToggling?: boolean;
  value: boolean;
  onChange: () => any;
};

export const Switch = (props: SwitchProps) => {
  const { disableToggling, value, onChange } = props;

  return (
    <Box
      switchOn={value}
      disabled={disableToggling}
      onClick={(e) => (disableToggling ? e.stopPropagation() : onChange())}
    >
      <Button switchOn={value} />
    </Box>
  );
};
