import styled from 'styled-components';

import colors from 'constants/colors';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const StepContainer = styled.div<{ isCurrent: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 87px;
  line-height: 13px;
  text-align: center;
  div {
    border: none;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;
    font-size: 14px;
    ${(props) =>
      props.isCurrent
        ? `
          background-color: ${colors.blue500};
          color: white;
      `
        : `
          color: ${colors.gray400};
          background-color: ${colors.gray50};
          border: 1px solid ${colors.gray400};
        `};
  }
  p {
    color: ${(props) => (props.isCurrent ? colors.gray850 : colors.gray600)};
    font-size: 11px;
    line-height: 13px;
    margin: 0;
    padding: 0 8px;
  }
`;

export const StepperProgress = styled.div`
  border-top: 1px dashed ${colors.gray500};
  position: absolute;
  top: 17px;
  left: 43px;
  right: 43px;
  z-index: -1;
`;
