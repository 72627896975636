import styled from 'styled-components';
import colors from 'constants/colors';

import { NAVITEM_BASE_STYLE } from 'modules/sidenav/components/NavItem/index.css';

export const NavigationGroup = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid ${colors.gray300};
`;

export const Logout = styled.div`
  align-items: center;
  color: ${colors.gray850};
  display: flex;
  ${NAVITEM_BASE_STYLE}
  padding-left: 16px;
`;

export const Line = styled.div`
  display: flex;
  ${NAVITEM_BASE_STYLE}
  padding-left: 16px;
`;

export const ExternalLink = styled.a`
  color: ${colors.gray850};

  :hover {
    color: ${colors.gray850};
    text-decoration: none;
  }

  :active {
    color: ${colors.gray850};
    text-decoration: none;
  }
`;
