import styled from 'styled-components';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import colors from 'constants/colors';
import { DropdownMenuWrapper } from 'modules/common-ui';

export const ColumnTogglerWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const ColumnTogglerMask = styled.div`
  position: fixed;
  z-index: 99;
  height: 100%;
  width: 100%;
  inset: 0;
`;

export const ListContainer = styled(DropdownMenuWrapper)`
  box-sizing: content-box;
  margin-top: 2px;
  max-height: 261px;
  overflow-y: auto;
  padding: 8px 0;
  position: absolute;
  right: 0;
  width: 269px;
  z-index: 1001;
`;

export const ListContainerMobile = styled.div`
  width: 100%;
  height: 100%;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    margin-top: 18px;
  }
`;

export const Column = styled.div<{ isDragging?: boolean }>`
  background: ${colors.white};
  display: flex;
  min-height: 28px;
  padding: 4px 14px 4px 5px;
  transition: background 120ms ease-in 0s;
  ${(props) =>
    props.isDragging ? `box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.1)` : ''};

  :hover {
    background: rgba(146, 148, 151, 0.08);
  }

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    align-items: center;
    margin-bottom: 4px;
    padding: 8px 16px;
  }
`;

export const ColumnSwitch = styled.div<{ disabled?: boolean }>`
  align-items: center;
  color: ${(props) => (props.disabled ? colors.gray500 : colors.gray850)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: calc(100% - 24px);

  > * {
    ${(props) => props.disabled && 'cursor: not-allowed;'}
  }
`;

export const Label = styled.div<{ htmlFor?: string }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const DragIconWrapper = styled.div<{ disabled?: boolean }>`
  align-items: center;
  color: ${(props) => (props.disabled ? colors.gray300 : colors.gray500)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'grab')};
  display: flex;
  height: 16px;
  margin-right: 8px;
  margin-top: 2px;
  width: 16px;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    margin-right: 16px;
    margin-top: 0;
  }
`;
