import colors from 'constants/colors';

export type FormatSelectIconProps = {
  color?: string;
};

export const FormatSelectIcon = ({
  color = colors.gray850,
}: FormatSelectIconProps) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.8031 12C10.1166 12 12.8031 9.31371 12.8031 6C12.8031 2.68629 10.1166 0 6.8031 0C3.48962 0 0.803101 2.68629 0.803101 6C0.803101 9.31371 3.48962 12 6.8031 12ZM3.55408 4.32278C3.45203 4.19142 3.54578 4 3.71179 4H9.89441C10.0604 4 10.1542 4.19142 10.0521 4.32278L6.96081 8.29703C6.88074 8.39998 6.72546 8.39998 6.64539 8.29703L3.55408 4.32278Z"
      fill={color}
    />
  </svg>
);
