import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { apiClient } from 'modules/api-client';

import {
  Button,
  Card,
  Field,
  FieldInputWrapper,
  FieldName,
  FieldValue,
  HibooIcon,
  TextInput,
} from 'modules/common-ui';

import { Form, Header, Message, StyledLink } from '../../index.css';

type SendTokenFormProps = {
  email?: string;
  locale?: string;
};

export const SendToken = ({
  email = '',
  locale = 'fr',
}: SendTokenFormProps) => {
  const [t] = useTranslation('user');
  const [form, setForm] = useState({
    email,
  });
  const [resetTokenSent, setResetTokenSent] = useState(false);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null as string | null);

  const submitSendTokenForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.email && !pending) {
      setPending(true);
      apiClient.user
        .sendResetToken(form.email.toLowerCase(), locale)
        .then((response) => {
          setPending(false);
          if (response.success) {
            setResetTokenSent(true);
          } else {
            setError('An error occured, please try again later.');
          }
        })
        .catch((err: Error) => {
          setPending(false);
          setError(err.message);
        });
    }
  };

  const renderMessage = () => (
    <Message>{t('user.resetPassword.tokenConfirmation.message')}</Message>
  );

  const renderForm = () => {
    return (
      <>
        <Form onSubmit={submitSendTokenForm}>
          <Field>
            <FieldName>
              <label htmlFor="send_token-email" className="col-form-label">
                {t('user.resetPassword.tokenForm.email.label')}
              </label>
            </FieldName>
            <FieldValue>
              <FieldInputWrapper width="100%">
                <TextInput
                  id="send_token-email"
                  name="email"
                  value={form.email}
                  placeholder={t(
                    'user.resetPassword.tokenForm.email.placeholder',
                  )}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  required
                  autoFocus
                  fullWidth
                  type="email"
                  variant="secondary"
                  autoComplete="off"
                />
              </FieldInputWrapper>
            </FieldValue>
          </Field>
          <Button
            block
            variant="outline-primary"
            type="submit"
            disabled={!form.email || pending}
            style={{ marginTop: '8px' }}
          >
            {!pending
              ? t('user.resetPassword.tokenForm.submit.label')
              : t('user.resetPassword.tokenForm.submit.loading')}
          </Button>
          <StyledLink to={`/login`}>
            {t('user.resetPassword.tokenForm.navBack')}
          </StyledLink>
          {error && <div className="text-danger my-3">{error}</div>}
        </Form>
      </>
    );
  };

  return (
    <Card
      style={{
        alignItems: 'center',
        flex: 'unset',
        maxWidth: '400px',
        padding: '24px',
        width: 'calc(100% - 32px)',
      }}
    >
      <HibooIcon />
      <Header>
        {!resetTokenSent
          ? t('user.resetPassword.tokenForm.header')
          : t('user.resetPassword.tokenConfirmation.header')}
      </Header>
      {!resetTokenSent ? renderForm() : renderMessage()}
    </Card>
  );
};
