import { initialize } from '@procore/procore-iframe-helpers';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import colors from 'constants/colors';
import { Group as Workspace, Organization, User } from 'graphql/types';
import { apiClient } from 'modules/api-client';
import {
  Button,
  Card,
  CircularSpinner,
  HibooIcon,
  HibooLogoLarge,
  NiceLink,
} from 'modules/common-ui';
import layout from 'modules/layout';

import {
  Footer,
  Form,
  Header,
  LogoWrapper,
  Message,
  WhatsHibooLink,
} from 'modules/user/containers/index.css';

import { Link } from 'react-router-dom';
import { procoreGenerateAuthorizeUrl } from './utils';

// eslint-disable-next-line max-statements
export const ProcoreConfiguration = () => {
  const { t } = useTranslation('procore');
  const [dataCreated, setDataCreated] = useState(false);
  const [pending, setPending] = useState(false);
  const [authenticationError, setAuthenticationError] = useState('');
  const [user, setUser] = useState<User>();
  const [organization, setOrganization] = useState<Organization>();
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);

  const urlParams = new URLSearchParams(window.location.search);
  const procoreCompanyId = urlParams.get('procoreCompanyId') || '';
  const code = urlParams.get('code');

  const context = initialize();

  async function login() {
    const url = await procoreGenerateAuthorizeUrl('/procore/configure', {
      procoreCompanyId,
    });

    context.authentication.authenticate({
      // Some URL on your domain that will start the authentication process
      url,

      // A function to run if authentication is successful. Payload, you will see
      // below, is sent by you at the last stage of the authentication flow.
      onSuccess: function (payload) {
        setPending(true);
        apiClient.oauth
          .getAccessTokenProcorePublic(payload.code, procoreCompanyId)
          .then(({ user, organization, workspaces }) => {
            setUser(user);
            setOrganization(organization);
            setWorkspaces(workspaces);

            setDataCreated(true);
            setPending(false);
          });
      },

      // A function to run if authentication fails
      onFailure: function (error) {
        setAuthenticationError(error);
        setPending(false);
      },
    });
  }
  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await login();
  };

  useEffect(() => {
    if (code) {
      context.authentication.notifySuccess({ code });
    }
  }, [code, context.authentication]);

  let content = (
    <>
      <Message>{t('configuration.explanation')}</Message>
      <Form data-testid="login-form" onSubmit={submitForm}>
        <Button
          variant="outline-alternate"
          type="submit"
          disabled={pending}
          style={{ marginTop: '8px', width: '100%' }}
          iconLeft={pending ? <CircularSpinner /> : null}
        >
          {!pending
            ? t('signInWithProcore')
            : t('user:user.login.form.submit.loading')}
        </Button>
        <div>{authenticationError}</div>
      </Form>
    </>
  );

  if (dataCreated) {
    content = (
      <Message>
        <span>{t('configuration.success.header')}</span>
        <div>&nbsp;</div>
        <div>
          <div>
            {`${t('configuration.success.user.header')}: ${user?.email}`}
          </div>
        </div>
        <div>&nbsp;</div>
        <div>
          <div>{`${t('configuration.success.organization.header')}: ${
            organization?.name
          }`}</div>
        </div>
        <div>&nbsp;</div>
        <div>
          <div>{t('configuration.success.workspace.header')}</div>
          {workspaces.map((workspace) => (
            <div key={workspace.id || undefined}>- {workspace.name}</div>
          ))}
          <div>&nbsp;</div>
          <Link
            to={`/procore/signin?procoreCompanyId=${procoreCompanyId}`}
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="outline-alternate"
              style={{ marginTop: '8px', width: '100%' }}
            >
              {t('goToHiboo')}
            </Button>
          </Link>
        </div>
      </Message>
    );
  }

  return (
    <layout.Page
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LogoWrapper>
        <HibooLogoLarge />
      </LogoWrapper>
      <Card
        style={{
          alignItems: 'center',
          flex: 'unset',
          maxWidth: '400px',
          padding: '24px',
          width: 'calc(100% - 32px)',
        }}
      >
        <HibooIcon />
        <Header>{t('user:user.login.header')}</Header>
        {content}
        <Footer>
          <WhatsHibooLink href="https://www.hiboo.io" target="_blank">
            <HibooIcon small color={colors.gray500} />
            <span>{t('user:whatsHiboo')}</span>
          </WhatsHibooLink>
          <NiceLink to={`/procore/signin?procoreCompanyId=${procoreCompanyId}`}>
            <span
              style={{
                color: colors.gray500,
                fontSize: 12,
                lineHeight: '16px',
              }}
            >
              {t('goToLogin')}
            </span>
          </NiceLink>
        </Footer>
      </Card>
    </layout.Page>
  );
};
