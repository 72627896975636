import colors from 'constants/colors';
import { DropdownMenuWrapper } from 'modules/common-ui';
import styled from 'styled-components';

export const WorkspaceSwitcherContainer = styled(DropdownMenuWrapper)`
  box-sizing: border-box;
  margin-left: 4px;
  max-height: 85vh;
  padding: 0;
  position: absolute;
  width: 360px;
  z-index: 10;
  user-select: none;
`;

export const HeaderRow = styled.div`
  color: ${colors.gray850};
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid ${colors.gray300};
`;

export const TreeContainer = styled.div`
  overflow-y: auto;
  max-height: 70vh;
`;
