import { Progress, ProgressBarContainer } from './index.css';

export type ProgressBarProps = {
  percent: number;
  height?: number;
};

export const ProgressBar = ({ percent, height = 8 }: ProgressBarProps) => {
  return (
    <ProgressBarContainer height={height}>
      <Progress percent={percent} height={height} />
    </ProgressBarContainer>
  );
};
