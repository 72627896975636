import styled from 'styled-components';

export const SelectContainer = styled.div<{
  marginRight?: number;
  maxWidth?: string;
}>`
  min-width: 236px;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}` : '700px')};
  ${(props) => props.marginRight && `margin-right:${props.marginRight}px;`}
`;
