import { EventTypes } from 'redux-segment';

import segmentTrackedEvents from 'constants/segmentTrackedEvents';
import { UserApi } from 'modules/api-client';
import { isHibooUser } from 'modules/utils';
import i18next from '../../i18n';

export type UserAction =
  | ReturnType<typeof userLoginSuccess>
  | ReturnType<typeof disconnectUser>
  | ReturnType<typeof userSwitchToken>
  | { type: 'CHOOSE_LANGUAGE'; [key: string]: any }
  | ReturnType<typeof updateIntegrationCount>
  | ReturnType<typeof updateOrgData>;

export function userLoginSuccess({
  id,
  token,
  email,
  firstName,
  lastName,
  admin,
  organizationId,
  organizationName,
  adminOfOrganization,
  organizationPlan,
  workspaceId,
  group,
  integrationCount,
  assetCount,
  hasOwnedAsset,
  jobTitle,
  signUpInProgress = false,
}: UserApi) {
  const analytics = [] as any[];

  if (!isHibooUser(email)) {
    analytics.push({
      eventType: EventTypes.identify,
      eventPayload: {
        userId: `${id}`,
        traits: {
          id: `${id}`,
          email,
          firstName,
          lastName,
          language: i18next.language,
          currentWorkspace: group,
          adminOfWorkspace: admin,
          adminOfOrganization,
          jobTitle,
          organizationId,
          company: {
            id: `${organizationId}`,
            name: organizationName,
            plan: organizationPlan,
          },
        },
      },
    });
    analytics.push({
      eventType: EventTypes.track,
      eventPayload: {
        event: segmentTrackedEvents.USER_EVENTS.SIGNED_IN,
        properties: {
          first_name: firstName,
          last_name: lastName,
          integration_count: integrationCount,
          asset_count: assetCount,
          user_job: jobTitle,
        },
      },
    });
  }
  return {
    type: 'USER_LOGIN_SUCCEEDED' as 'USER_LOGIN_SUCCEEDED',
    token,
    email,
    firstName,
    lastName,
    id,
    admin,
    organizationId,
    organizationName,
    organizationPlan,
    hasOwnedAsset,
    adminOfOrganization,
    workspaceId,
    group,
    integrationCount,
    assetCount,
    jobTitle,
    signUpInProgress: signUpInProgress,
    meta: {
      analytics,
    },
  };
}

export function disconnectUser() {
  return {
    type: 'USER_DISCONNECTED' as 'USER_DISCONNECTED',
    meta: {
      analytics: {
        eventType: EventTypes.reset,
      },
    },
  };
}

export function userSwitchToken(
  token: string,
  id: number,
  admin: boolean,
  adminOfOrganization: boolean,
  workspaceId: number,
  group: string,
  email: string,
) {
  const analytics = [] as any[];

  if (!isHibooUser(email)) {
    analytics.push({
      eventType: EventTypes.identify,
      eventPayload: {
        userId: `${id}`,
        traits: {
          id: `${id}`,
          currentWorkspace: group,
          language: i18next.language,
          adminOfWorkspace: admin,
          adminOfOrganization,
        },
      },
    });
  }

  return {
    type: 'USER_SWITCH_TOKEN' as 'USER_SWITCH_TOKEN',
    data: {
      token,
      admin,
      adminOfOrganization,
      workspaceId,
      group,
    },
    meta: {
      analytics,
    },
  };
}

export function updateOrgData(
  hasOwnedAsset: boolean,
  assetCount: number | null,
  integrationCount: number | null,
) {
  return {
    type: 'USER_UPDATE_ORG_DATA' as 'USER_UPDATE_ORG_DATA',
    data: {
      hasOwnedAsset,
      assetCount,
      integrationCount,
    },
  };
}

export function updateIntegrationCount(integrationCount: number) {
  return {
    type: 'USER_UPDATE_INTEGRATION_COUNT' as const,
    integrationCount,
  };
}
