import colors from 'constants/colors';
import styled from 'styled-components';

export const NoDataContainer = styled.div<{ noButton?: boolean }>`
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  ${(props) => (props.noButton ? '' : 'top: 55%;')};
  z-index: 1;
`;

export const NoData = styled.div`
  color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  pointer-events: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.gray500};
`;
