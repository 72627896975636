import {
  FAVORITES_FILTER_VALUES,
  FILTER_TYPES,
  FilterTypeKey,
} from 'constants/filter';
import { FleetSegmentGQL } from 'modules/analytics/components/AssetFilter/gql';
import { AssetFilterOptions } from 'modules/analytics/components/AssetFilter/types';
import {
  AssetCustomFieldV2ValueType,
  CustomFieldV2SelectValueType,
} from 'modules/types';
import { FiltersGQL } from 'modules/types/graphql/filters';
import { formatSource } from 'utils/i18n';
import { FAULT_LEVEL_LABELS } from '../FaultLevel';
import { FilterObject, FilterOption, SelectedFilter } from './Filter';

type PeriodType = {
  since: string;
  until: string;
};

export type CustomFieldsFilterOption = {
  id: number;
  label: string;
  options: FilterOption[];
};

export type FilterOptionsList = {
  names: FilterOption[];
  makers: FilterOption[];
  enterprises: FilterOption[];
  models: FilterOption[];
  categories: FilterOption[];
  sources: FilterOption[];
  customFieldsFilters: Array<CustomFieldsFilterOption>;
  zones?: FilterOption[];
  faultSeverities?: FilterOption[];
  sharingReferences?: FilterOption[];
  sharedByOrganizations?: FilterOption[];
  sharedWithOrganizations?: FilterOption[];
};

export const extractCustomFieldV2Filters = (filters: any) => {
  const filterCustomField: Array<{
    id: number;
    values: Array<string | number | CustomFieldV2SelectValueType>;
  }> = [];

  const customFieldFilterKeys = Object.keys(filters).filter((key) =>
    key.startsWith(FILTER_TYPES.CUSTOM_FIELD_V2),
  );

  if (customFieldFilterKeys.length) {
    customFieldFilterKeys.forEach((cf) => {
      const customFieldId = cf.split('_')[1];

      if (customFieldId && Array.isArray(filters[cf])) {
        const values = filters[cf].map((cfv: AssetCustomFieldV2ValueType) => {
          return cfv.value;
        });
        filterCustomField.push({
          id: +customFieldId,
          values,
        });
      }
    });
  }

  return filterCustomField;
};

const getValues = <ValueType>(arr?: { value: ValueType }[]): ValueType[] =>
  (arr || []).map(({ value }) => value);

export const computeFilterParams = (filters: any) => {
  const { since, until }: PeriodType = filters.period || {};

  const filterCategory = getValues<number>(filters.category);
  const filterNames = getValues<string>(filters.assetName);
  const filterMaker = getValues<string>(filters.brand);
  const filterModel = getValues<string>(filters.model);
  const filterSource = getValues<string>(filters.source);
  const filterFaultSeverity = getValues<number>(filters.faultSeverity);
  const filterEnterprise = getValues<string>(filters.enterprise);
  const filterZone = getValues<string>(filters.zone);

  let filterFavorite: boolean | undefined;
  if (filters.favorite) {
    filterFavorite =
      filters.favorite === FAVORITES_FILTER_VALUES.YES ? true : false;
  }

  const filterCustomFieldV2 = extractCustomFieldV2Filters(filters);

  return {
    since,
    until,
    filterCategory,
    filterMaker,
    filterModel,
    filterSource,
    filterFaultSeverity,
    filterEnterprise,
    filterFavorite,
    filterCustomFieldV2,
    filterZone,
    filterNames,
    ..._computeSharingFilterParams(filters),
  };
};

export const _computeSharingFilterParams = (filters: {
  sharingReference: { key: number; label: string; value: string }[];
  sharedByOrganization: { key: number; label: string; value: string }[];
  sharedWithOrganization: { key: number; label: string; value: string }[];
}): {
  filterSharingReference: string[];
  filterSharedByOrganization: number[];
  filterSharedWithOrganization: number[];
} => {
  const filterSharingReference = getValues<string>(filters.sharingReference);
  const filterSharedByOrganization = getValues<string>(
    filters.sharedByOrganization,
  ).map(Number);
  const filterSharedWithOrganization = getValues<string>(
    filters.sharedWithOrganization,
  ).map(Number);
  return {
    filterSharingReference,
    filterSharedByOrganization,
    filterSharedWithOrganization,
  };
};

export const buildFilterOptionArray = (options: string[]): FilterOption[] => {
  return options.map((option) => ({
    label: option,
    value: option,
  }));
};

export const buildFilterOptionsFromGql = (filtersGQL?: FiltersGQL) => {
  if (!filtersGQL)
    return {
      names: [],
      makers: [],
      enterprises: [],
      models: [],
      categories: [],
      sources: [],
      customFieldsFilters: [],
      zones: [],
      faultSeverities: [],
      sharingReferences: [],
      sharedByOrganizations: [],
      sharedWithOrganizations: [],
    };

  const customFieldV2Filters = filtersGQL.customFieldsV2
    .filter((cf) => cf.format === 'select')
    .map((cf) => ({
      id: cf.id,
      label: cf.name,
      options:
        cf.meta && 'options' in cf.meta
          ? cf.meta.options.map((cv: CustomFieldV2SelectValueType) => ({
              label: cv.label,
              value: cv.label,
            }))
          : [],
    }));

  const filterOptions: FilterOptionsList = {
    names: buildFilterOptionArray(filtersGQL.allNames),
    makers: buildFilterOptionArray(filtersGQL.makers),
    enterprises: buildFilterOptionArray(filtersGQL.allEnterprises),
    models: buildFilterOptionArray(filtersGQL.allModels),
    categories: filtersGQL.linkedCategories.map((category) => ({
      label: category.name,
      value: category.id,
    })),
    sources: filtersGQL.allSources.map((source) => {
      return {
        label: formatSource(source),
        value: source,
      };
    }),
    customFieldsFilters: customFieldV2Filters,
  };
  if (filtersGQL.zones) {
    filterOptions.zones = filtersGQL.zones.map((zone) => ({
      label: zone.name,
      value: zone.id,
    }));
  }
  if (filtersGQL.allFaultSeverities) {
    filterOptions.faultSeverities = filtersGQL.allFaultSeverities.map((fs) => ({
      label: FAULT_LEVEL_LABELS[fs.id] || fs.id,
      value: fs.id,
    }));
  }
  if (filtersGQL.sharingReferences) {
    filterOptions.sharingReferences = buildFilterOptionArray(
      filtersGQL.sharingReferences,
    );
  }
  if (filtersGQL.sharedByOrganizations) {
    filterOptions.sharedByOrganizations = filtersGQL.sharedByOrganizations.map(
      (organization) => ({
        label: organization.name,
        value: organization.id,
      }),
    );
  }
  if (filtersGQL.sharedWithOrganizations) {
    filterOptions.sharedWithOrganizations =
      filtersGQL.sharedWithOrganizations.map((organization) => ({
        label: organization.name,
        value: organization.id,
      }));
  }
  return filterOptions;
};

const segmenFilterKeyToFilterTypeKey = (
  segmentFilterKey: string,
): FilterTypeKey => {
  switch (segmentFilterKey) {
    case 'categories':
      return FILTER_TYPES.CATEGORY;
    case 'makers':
      return FILTER_TYPES.BRAND;
    case 'models':
      return FILTER_TYPES.MODEL;
    case 'sources':
      return FILTER_TYPES.SOURCE;
    case 'enterprises':
      return FILTER_TYPES.ENTERPRISE;
    case 'customfields':
      return FILTER_TYPES.CUSTOM_FIELD_V2;
    case 'names':
      return FILTER_TYPES.ASSET_NAME;
    case 'favorite':
      return FILTER_TYPES.FAVORITE;
    default:
      throw new Error(`Unknown segment filter key "${segmentFilterKey}"`);
  }
};

export const segmentToFiltersAdapter = (
  filters: FilterObject[],
  segment: FleetSegmentGQL,
): SelectedFilter[] => {
  const selectedFilters: SelectedFilter[] = [];
  Object.keys(segment.filters).forEach((filterKey) => {
    // don't handle this field if it is null
    if (segment.filters[filterKey as keyof FleetSegmentGQL['filters']] === null)
      return;
    switch (filterKey) {
      case 'makers':
      case 'models':
      case 'sources':
      case 'enterprises':
      case 'names':
        selectedFilters.push({
          type: segmenFilterKeyToFilterTypeKey(filterKey),
          value: buildFilterOptionArray(segment.filters[filterKey]!),
        });
        break;
      case 'categories':
        const categoryFilter = filters.find(
          (filter) => filter.type === 'category',
        );
        if (!categoryFilter) return;
        const categoryValues = segment.filters[filterKey]!.map((value) => {
          const option = categoryFilter.options.find(
            (option) => option.value === value,
          );
          return (
            option || {
              label: value,
              value,
            }
          );
        });
        selectedFilters.push({
          type: segmenFilterKeyToFilterTypeKey(filterKey),
          value: categoryValues,
        });
        break;
      case 'customfields':
        const customFields = segment.filters.customfields!;
        customFields.forEach((cf) => {
          selectedFilters.push({
            type: `${segmenFilterKeyToFilterTypeKey(filterKey)}_${cf.id}`,
            value: buildFilterOptionArray(cf.values),
          });
        });
        break;
      case 'favorite':
        selectedFilters.push({
          type: segmenFilterKeyToFilterTypeKey(filterKey),
          value:
            segment.filters.favorite === true
              ? FAVORITES_FILTER_VALUES.YES
              : FAVORITES_FILTER_VALUES.NO,
        });
        break;
      default:
        break;
    }
  });
  return selectedFilters;
};

export const appliedFiltersToSelectedFilters = (
  appliedFilters: AssetFilterOptions,
): SelectedFilter[] => {
  if (
    !appliedFilters ||
    (appliedFilters && !Object.keys(appliedFilters).length)
  ) {
    return [{ type: '' }];
  }

  const newSelectedFilters: SelectedFilter[] = [];

  for (const type in appliedFilters) {
    if (
      type === FILTER_TYPES.CUSTOM_FIELD_V2 &&
      appliedFilters.customFieldsV2?.length
    ) {
      for (const customField of appliedFilters.customFieldsV2) {
        newSelectedFilters.push({
          type: `${FILTER_TYPES.CUSTOM_FIELD_V2}_${customField.id}`,
          isCustomField: true,
          customFieldId: customField.id,
          value: customField.values,
        });
      }
    } else {
      newSelectedFilters.push({
        type,
        value: appliedFilters[type as keyof AssetFilterOptions],
      });
    }
  }
  return newSelectedFilters;
};
