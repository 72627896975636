import styled from 'styled-components';

import colors from 'constants/colors';

export const Tag = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusWrapper = styled.p<{
  noPadding?: boolean;
  noColor?: boolean;
  smallFont?: boolean;
}>`
  margin: 0;
  padding-left: ${(props) => (props.noPadding ? '0' : '4px')};
  color: ${(props) => (props.noColor ? colors.black : colors.gray500)};
  font-weight: 400;
  font-size: ${(props) => (props.smallFont ? '12px' : '14px')};
  line-height: ${(props) => (props.smallFont ? '16px' : '18px')};
`;

type DotProps = {
  gray?: boolean;
  blue?: boolean;
  brown?: boolean;
  red?: boolean;
  orange?: boolean;
  green?: boolean;
  noMargin?: boolean;
};

export const Dot = styled.div<DotProps>`
  background-color: ${(props) => {
    if (props.gray) return colors.gray400;
    if (props.blue) return colors.blue400;
    if (props.brown) return colors.brown100;
    if (props.red) return colors.red300;
    if (props.orange) return colors.yellow600;
    if (props.green) return colors.green300;
    return colors.gray850;
  }};
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: ${(props) => (props.noMargin ? '4px' : '8px')};
  min-width: 8px;
  min-height: 8px;
`;
