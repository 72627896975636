import colors from 'constants/colors';
import styled from 'styled-components';

export const OwlImage = styled.img`
  height: 'auto';
  width: 400px;
  justify-content: center;
  margin: 0 auto;
`;

export const ErrorTitle = styled.div`
  font-size: 36px;
  font-weight: 500;
`;

export const HelpMessage = styled.div`
  font-size: 16px;
  margin-bottom: 32px;
  color: ${colors.gray700};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  justify-content: center;

  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: both;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate3d(0, 2%, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
`;

export const LogoContainer = styled.div`
  margin-top: 11px;
  margin-left: 16px;
`;
