import styled from 'styled-components';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import colors from 'constants/colors';
import {
  HeaderLeft,
  HeaderRight,
} from 'modules/common-ui/components/Drawer/index.css';

export const Container = styled.div`
  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    width: 100%;
  }
`;

export const DropdownHeaderLeft = styled(HeaderLeft)`
  color: ${colors.gray800};
  font-size: 20px;
  font-weight: 500;
  justify-content: center;
  line-height: 26px;
`;

export const DropdownHeaderRight = styled(HeaderRight)`
  color: ${colors.cyan400};
  font-size: 14px;
  line-height: 18px;
`;

export const FreemiumWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  overflow-y: auto;

  button {
    height: 40px;
  }
`;

export const FreemiumTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${colors.gray850};
  margin-bottom: 4px;
`;

export const FreemiumDescription = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.gray500};
  margin-bottom: 18px;
`;
