import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors, { primaryColors } from 'constants/colors';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const FooterContainer = styled.div`
  padding-bottom: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 42px;
`;

export const Card = styled.div<{ width?: number }>`
  width: ${(props) => (props.width ? props.width + 'px' : 'fit-content')};
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 0.5px solid #e5e8eb;
  box-sizing: border-box;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 44px;
  padding-right: 44px;
  margin: auto;
`;

export const CardHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.gray200};
`;
export const CardHeaderLogo = styled.div`
  height: 30px;
  margin-bottom: 16px;
`;

export const CardHeaderTitle = styled.div`
  height: 26px;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: ${colors.gray850};
`;

export const FieldTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;

  color: ${colors.gray850};
`;

export const CardFooterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  flex-direction: column;
  margin-top: 24px;

  button {
    width: 100%;
    margin-bottom: 8px;
  }
`;

export const PrivacyPolicyContainer = styled.div`
  width: 370px;
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${colors.gray500};

  a {
    color: ${colors.gray500};
    text-decoration: underline;
    transition: color 120ms ease-in 0s;

    :hover,
    :focus {
      color: ${primaryColors.main};
    }
  }
`;

export const StyledLink = styled(Link)`
  color: ${colors.gray500};
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border-radius: 5px;
  margin-top: 8px;
  text-decoration: underline;
  transition: color 120ms ease-in 0s;

  :hover,
  :focus {
    color: ${primaryColors.main};
  }
`;

export const BadInput = styled.div`
  height: 12px;
  color: ${colors.red300};
  font-size: 10px;
`;

export const CheckboxContainer = styled.div`
  margin-top: 16px;
`;
