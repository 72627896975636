import colors from 'constants/colors';
import { NAVITEM_BASE_STYLE } from 'modules/sidenav/components/NavItem/index.css';
import styled, { keyframes } from 'styled-components';

const slideInDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  20% {
    opacity: 1;
    transform: translateY(0px);
  }
  85% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
  }
`;

export const NavItemWrapper = styled.div`
  a {
    padding-left: 36px;
  }
`;

export const NavGroupContainer = styled.div`
  margin-bottom: 0px;
  color: ${colors.gray850};
`;

const removeButtonDefaultStyle = `
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const GroupLabel = styled.button<{
  inactive: boolean;
  selected: boolean;
}>`
  ${removeButtonDefaultStyle}
  width: 100%;
  ${NAVITEM_BASE_STYLE}
  padding-left: 16px;
  display: flex;
  justify-content: space-between;

  :focus {
    outline: none;
  }
  :focus-visible {
    outline: auto;
  }

  ${({ inactive }) =>
    inactive
      ? `:hover {
    cursor: auto;
    background: transparent;
  }`
      : null}
`;

export const GroupIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ToggleIcon = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  transition: transform 0.2s ease-in-out;

  ${(props) =>
    props.isOpen
      ? `
      transform: rotate(180deg);
    `
      : `
      transform: rotate(0);
    `}
`;

export const Children = styled.div`
  animation: 0.5s ${slideInDown};
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
`;
