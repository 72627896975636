export const REGULAR_WIDTH = '954px';
export const LARGE_WIDTH = '1440px';
export const PAGE_LATERAL_MARGIN = '32px';
export const MOBILE_PAGE_LATERAL_MARGIN = '16px';
export const PAGE_TOP_MARGIN = '32px';
export const SECTION_SMALL_BOTTOM_MARGIN = '4px';

export const DEFAULT_PADDING = '8px';
export const DEFAULT_SPACING = '20px';

export const SUB_NAV_BOTTOM_MARGIN = '16px';
export const SUB_NAV_PADDING = '8px';
