import colors from 'constants/colors';
import * as Highcharts from 'highcharts';
import HC_exportData from 'highcharts/modules/export-data';
import HC_exporting from 'highcharts/modules/exporting';

// Enable highchart exporting module
HC_exporting(Highcharts);
// Enable CSV exporting module
HC_exportData(Highcharts);

export const HCDefaultOptions: Highcharts.Options = {
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
  chart: {
    backgroundColor: 'transparent',
    reflow: true,
    zooming: {
      type: 'x',
    },
    style: {
      fontFamily: 'Inter',
    },
  },
};

export const HCDefaultTooltipOptions: Highcharts.TooltipOptions = {
  shape: 'square',
  backgroundColor: colors.gray850,
  borderColor: 'transparent',
  shadow: false,

  style: {
    color: colors.gray50,
    fontSize: '12px',
  },
};

const HCDefaultAxisOptions: Highcharts.AxisOptions = {
  labels: {
    style: {
      color: colors.gray700,
    },
  },
};

export const HCDefaultXAxisOptions: Highcharts.XAxisOptions = {
  ...(HCDefaultAxisOptions as Highcharts.XAxisOptions),
};

export const HCDefaultYAxisOptions: Highcharts.YAxisOptions = {
  ...(HCDefaultAxisOptions as Highcharts.YAxisOptions),
  gridLineColor: colors.gray300,
  gridLineDashStyle: 'ShortDot',
};

export const HCDefaultAreasplineOptions: Highcharts.PlotAreasplineOptions = {
  marker: {
    enabled: false,
    lineColor: 'transparent',
    symbol: 'circle',
    radius: 4,
  },
  zoneAxis: 'x',
};

export const HCDefaultPlotOptions: Highcharts.PlotOptions = {
  series: {
    states: {
      hover: {
        halo: null,
      },
    },
  },
};

export default Highcharts;
