/**
 * Charts configuration
 */
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Interaction,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  defaults as chartDefaults,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';

// Work around, modifying chartDefaults cause jest to bug
if (process.env.NODE_ENV !== 'test') {
  /** charts defaults */
  chartDefaults.font.family = `-apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif`;

  /** register plugins */
  Chart.register(
    Tooltip,
    CrosshairPlugin,
    CategoryScale,
    Filler,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    BarElement,
    BarController,
    Title,
  );
  (Interaction.modes as any).interpolate = Interpolate;

  /** crosshair default config */
  (chartDefaults.plugins as any).crosshair = {
    line: {
      color: 'rgba(0, 0, 0, 0)',
      dashPattern: [3, 3],
    },
    sync: {
      enabled: false,
    },
    zoom: {
      enabled: false,
    },
  };
}

export default Chart;
