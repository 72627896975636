import colors from 'constants/colors';

export type CommentIconProps = {
  size?: number;
  color?: string;
};

export const CommentIcon = ({
  size = 16,
  color = colors.gray500,
}: CommentIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 16 16`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 3.23684H5.22884L5.37841 3.06365L6.5 1.76497L7.62159 3.06365L7.77116 3.23684H8H10.5H13C13.8284 3.23684 14.5 3.90842 14.5 4.73684V12C14.5 12.8284 13.8284 13.5 13 13.5H3C2.17157 13.5 1.5 12.8284 1.5 12V4.73684C1.5 3.90842 2.17157 3.23684 3 3.23684H5Z"
      stroke={color}
    />
    <line x1="3" y1="6" x2="12" y2="6" stroke={color} />
    <line x1="3" y1="11" x2="10" y2="11" stroke={color} />
    <line x1="3" y1="8.5" x2="8" y2="8.5" stroke={color} />
  </svg>
);
