export type LineStrokeIconProps = {
  size?: number;
};

export const LineStrokeIcon = ({ size = 16 }: LineStrokeIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.10495 10.4186L12.7637 1.75984L14.398 3.39419L5.7393 12.0529L4.10495 10.4186Z"
      stroke="#2D2F30"
    />
    <path
      d="M3.05664 10.7583L5.33956 13.0412L2.94035 13.5501C2.70597 13.5999 2.498 13.3919 2.54772 13.1575L3.05664 10.7583Z"
      fill="#2D2F30"
    />
    <line
      x1="0.5"
      y1="15.3496"
      x2="15.5"
      y2="15.3496"
      stroke="#2D2F30"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
