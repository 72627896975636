import { Button, HibooLogoLarge } from 'modules/common-ui';
import Layout from 'modules/layout';
import { useTranslation } from 'react-i18next';
import {
  ErrorTitle,
  HelpMessage,
  LogoContainer,
  MainContainer,
  OwlImage,
} from './styles.css';

type ErrorPageProps = {
  resetErrorBoundary(): void;
};

export const ErrorPage = (props: ErrorPageProps) => {
  const { t } = useTranslation('applicationError');

  return (
    <Layout.Root>
      <LogoContainer>
        <HibooLogoLarge />
      </LogoContainer>
      <Layout.PageContainer>
        <MainContainer>
          <OwlImage src="/images/hoowhat-error.gif" alt="error" />
          <ErrorTitle>Houhoups..?!</ErrorTitle>
          <HelpMessage>
            {t('description')} <br />
            {t('reset')}
          </HelpMessage>
          <Button
            variant="primary"
            onClick={() => {
              props.resetErrorBoundary();
            }}
          >
            {t('btnHome')}
          </Button>
        </MainContainer>
      </Layout.PageContainer>
    </Layout.Root>
  );
};
