import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssetSearchFragment = {
  __typename: 'AssetSearchResult';
  id: string;
  name: string;
  make?: string | null;
  model?: string | null;
  status: Types.AssetSearchResultStatus;
  category?: {
    __typename?: 'AssetCategorySearchResult';
    id?: string | null;
    name?: string | null;
    color?: string | null;
  } | null;
  sharingInfos: {
    __typename?: 'AssetSharingInfos';
    sharedBy?: {
      __typename?: 'SharedAssetOwnerOrganization';
      id: number;
      organizationName: string;
    } | null;
    sharedWith: Array<{
      __typename?: 'SharedAssetBeneficiaryOrganization';
      id: number;
      organizationName: string;
    }>;
  };
};

export type ZoneSearchFragment = {
  __typename: 'ZoneSearchResult';
  id: string;
  name: string;
  category?: {
    __typename?: 'ZoneCategorySearchResult';
    id?: string | null;
    name?: string | null;
    color?: string | null;
  } | null;
};

export type QueryGlobalSearchQueryVariables = Types.Exact<{
  text: Types.Scalars['String']['input'];
  limit: Types.Scalars['Int']['input'];
}>;

export type QueryGlobalSearchQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id?: string | null;
    workspace?: {
      __typename?: 'Group';
      id?: string | null;
      globalSearch?: Array<
        | {
            __typename: 'AssetSearchResult';
            id: string;
            name: string;
            make?: string | null;
            model?: string | null;
            status: Types.AssetSearchResultStatus;
            category?: {
              __typename?: 'AssetCategorySearchResult';
              id?: string | null;
              name?: string | null;
              color?: string | null;
            } | null;
            sharingInfos: {
              __typename?: 'AssetSharingInfos';
              sharedBy?: {
                __typename?: 'SharedAssetOwnerOrganization';
                id: number;
                organizationName: string;
              } | null;
              sharedWith: Array<{
                __typename?: 'SharedAssetBeneficiaryOrganization';
                id: number;
                organizationName: string;
              }>;
            };
          }
        | {
            __typename: 'ZoneSearchResult';
            id: string;
            name: string;
            category?: {
              __typename?: 'ZoneCategorySearchResult';
              id?: string | null;
              name?: string | null;
              color?: string | null;
            } | null;
          }
        | null
      > | null;
    } | null;
  } | null;
};

export const AssetSearchFragmentDoc = gql`
    fragment AssetSearch on AssetSearchResult {
  id
  name
  make
  model
  status
  category {
    id
    name
    color
  }
  sharingInfos {
    sharedBy {
      id
      organizationName
    }
    sharedWith {
      id
      organizationName
    }
  }
  __typename
}
    `;
export const ZoneSearchFragmentDoc = gql`
    fragment ZoneSearch on ZoneSearchResult {
  id
  name
  category {
    id
    name
    color
  }
  __typename
}
    `;
export const QueryGlobalSearchDocument = gql`
    query queryGlobalSearch($text: String!, $limit: Int!) {
  viewer {
    id
    workspace {
      id
      globalSearch(text: $text, limit: $limit) {
        ...AssetSearch
        ...ZoneSearch
      }
    }
  }
}
    ${AssetSearchFragmentDoc}
${ZoneSearchFragmentDoc}`;

/**
 * __useQueryGlobalSearchQuery__
 *
 * To run a query within a React component, call `useQueryGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGlobalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGlobalSearchQuery({
 *   variables: {
 *      text: // value for 'text'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useQueryGlobalSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryGlobalSearchQuery,
    QueryGlobalSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryGlobalSearchQuery,
    QueryGlobalSearchQueryVariables
  >(QueryGlobalSearchDocument, options);
}
export function useQueryGlobalSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryGlobalSearchQuery,
    QueryGlobalSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryGlobalSearchQuery,
    QueryGlobalSearchQueryVariables
  >(QueryGlobalSearchDocument, options);
}
export type QueryGlobalSearchQueryHookResult = ReturnType<
  typeof useQueryGlobalSearchQuery
>;
export type QueryGlobalSearchLazyQueryHookResult = ReturnType<
  typeof useQueryGlobalSearchLazyQuery
>;
export type QueryGlobalSearchQueryResult = Apollo.QueryResult<
  QueryGlobalSearchQuery,
  QueryGlobalSearchQueryVariables
>;
