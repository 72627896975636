// hack to define column width based on the content

// eslint-disable-next-line max-statements
export const getColumnWidth = (
  rows: readonly any[],
  accessor: any,
  headerText?: string,
) => {
  const minWidth = 200;
  const maxWidth = 400;

  const magicSpacing = 12; // Heuristically choosen ~ pixels count for a character

  let cellMaxWidth = 0;

  for (let i = 0; i < rows.length; i++) {
    const cellWidth =
      (accessor && accessor(rows[i]) ? `${accessor(rows[i])}` : '').length *
      magicSpacing;

    if (cellWidth >= maxWidth) {
      return maxWidth;
    }

    if (cellWidth > cellMaxWidth) {
      cellMaxWidth = cellWidth;
    }
  }

  // Empty column
  if (cellMaxWidth === 0) {
    return minWidth;
  }

  const headerWidth = (headerText ? `${headerText}` : '').length * magicSpacing;

  if (cellMaxWidth < minWidth && cellMaxWidth < headerWidth) {
    return headerWidth < maxWidth ? headerWidth : minWidth;
  }

  return Math.min(maxWidth, cellMaxWidth);
};
