import { FieldPolicy } from '@apollo/client';

import { Reference } from '@apollo/client/utilities';
import { TypedTypePolicies } from 'graphql/apollo-helpers';

function paginatedAssetOffsetLimitPagination<T = Reference>(
  keyArgs: FieldPolicy<any>['keyArgs'] = false,
): FieldPolicy<{ total: number; rows: T[] }> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const merged =
        existing && Array.isArray(existing.rows) ? existing.rows.slice(0) : [];
      if (incoming && Array.isArray(incoming.rows)) {
        if (args) {
          // Assume an offset of 0 if args.offset is omitted.
          // The offset could be in a pagination object or as a separate argument
          const { offset = 0 } = args.pagination || args;
          for (let i = 0; i < incoming.rows.length; ++i) {
            merged[offset + i] = incoming.rows[i];
          }
        } else {
          merged.push(...incoming.rows);
        }
      }

      return {
        ...incoming,
        ...existing,
        rows: merged,
      };
    },
  };
}

export const typePolicies: TypedTypePolicies = {
  AssetCustomFieldType: {
    keyFields: ['id', 'value'],
  },
  FeatureManagement: {
    keyFields: [],
  },
  WorkspaceTyreSnapshot: {
    merge: true,
  },
  Co2Report: {
    keyFields: [],
  },
  UsageHoursReport: {
    keyFields: [],
  },
  FleetData: {
    keyFields: [],
  },
  Sharing: {
    keyFields: [],
    fields: {
      shareableAssets: paginatedAssetOffsetLimitPagination([
        'assetFilters',
        'search',
      ]),
    },
  },
  IssuedSharing: {
    fields: {
      sharedAssets: paginatedAssetOffsetLimitPagination(),
    },
  },
  ReceivedSharing: {
    fields: {
      sharedAssets: paginatedAssetOffsetLimitPagination(),
    },
  },
  Group: {
    fields: {
      listAssets: paginatedAssetOffsetLimitPagination([
        'assetFilters',
        'search',
      ]),
    },
  },
  AssetSharingDataFilters: {
    keyFields: [],
  },
  IssuedSharingDataFilters: {
    keyFields: [],
  },
  SharingInfos: {
    keyFields: [],
  },
};
