import styled from 'styled-components';

import colors from 'constants/colors';
import { BATTERY_INDICATOR_LEVEL } from 'constants/device';
import { CondensedCardSection } from 'modules/assets/overview/components/details/components/index.css';

export const DeviceBatteryLevelDot = styled.div<{ color?: string }>`
  height: 8px;
  width: 8px;
  border-radius: 50%;

  background: ${({ color }) => {
    if (color === BATTERY_INDICATOR_LEVEL.GREEN) {
      return colors.green400;
    }
    if (color === BATTERY_INDICATOR_LEVEL.ORANGE) {
      return colors.yellow400;
    }
    if (color === BATTERY_INDICATOR_LEVEL.RED) {
      return colors.red300;
    }

    return colors.gray200;
  }};
`;

export const DeviceBatteryLevelDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  ${DeviceBatteryLevelDot} + ${DeviceBatteryLevelDot} {
    margin-left: 4px;
  }
`;

export const DeviceBatteryLevelLabel = styled.div<{ red?: boolean }>`
  font-weight: 400;
  font-size: 14px;

  ${({ red }) => (red ? 'color:' + colors.red300 : '')};

  ${CondensedCardSection} & {
    font-size: 12px;
  }
`;
