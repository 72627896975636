import colors from 'constants/colors';

export type DestinationsIconProps = {
  size?: number;
  color?: string;
};

export const DestinationsIcon = ({
  size = 16,
  color = colors.gray500,
}: DestinationsIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.90039 8L14.0251 8" stroke={color} strokeLinecap="round" />
    <path d="M8 8H7.99996" stroke={color} strokeLinecap="round" />
    <path
      d="M12.2974 9.72754L14.0249 7.99997"
      stroke={color}
      strokeLinecap="round"
    />
    <path d="M12.2974 6.27243L14.0249 8" stroke={color} strokeLinecap="round" />
    <path
      d="M10.9795 4.46056C10.075 3.55803 8.82659 3 7.44787 3C4.68645 3 2.44787 5.23858 2.44787 8C2.44787 10.7614 4.68645 13 7.44787 13C8.8256 13 10.0732 12.4428 10.9775 11.5414"
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
);
