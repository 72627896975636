import styled from 'styled-components';

import colors from 'constants/colors';
import { DropdownMenuWrapper } from 'modules/common-ui/styled-components/index.css';

export const Select = styled.div<{ hoverEffect?: boolean; light?: boolean }>`
  align-items: center;
  border-radius: 5px;
  color: ${colors.gray850};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  ${(props) => props.light && `height: 38px;`}
  line-height: 18px;
  overflow-wrap: break-word;
  transition: background 120ms ease-in 0s;
  white-space: normal;
  word-wrap: break-word;

  ${(props) =>
    props.hoverEffect &&
    `
  :hover {
    background: rgba(45, 47, 48, 0.08);
  }

  :active {
    background: rgba(45, 47, 48, 0.16);
  }`}
`;

export const Left = styled.div<{ isPlaceholder: boolean }>`
  flex: 1;
  padding: 8px;
  text-align: left;
  user-select: none;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) => props.isPlaceholder && `color: ${colors.gray500};`}
`;

export const Right = styled.div`
  padding: 7px 8px 7px 8px;
  user-select: none;
  width: 32px;
`;

export const ListContainer = styled.div`
  z-index: 1;
`;

export const List = styled(DropdownMenuWrapper)`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 275px;
`;

export const ListHeader = styled.div`
  color: ${colors.gray500};
  padding-left: 12px;
  user-select: none;
  text-wrap: wrap;
`;

export const ListItem = styled.div`
  align-items: center;
  color: ${colors.gray850};
  display: flex;
  flex-direction: row;
  font-weight: normal;
  justify-content: space-between;
  min-height: 32px;
  user-select: none;
  text-wrap: wrap;
`;

export const Label = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: flex-start;
  line-height: 18px;
  margin-right: 7px;
  padding-left: 12px;
`;

export const FilterMask = styled.div`
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  inset: 0;
`;
