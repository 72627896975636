import colors from 'constants/colors';
import styled from 'styled-components';

type DropzoneContainerProps = {
  isDragActive?: boolean;
  danger?: boolean;
  accepted?: boolean;
  isMobile?: boolean;
};

const getBorderColor = (props: DropzoneContainerProps) => {
  if (props.danger) {
    return colors.red300;
  }
  if (props.isDragActive || props.accepted) {
    return colors.blue400;
  }
  return colors.gray500;
};

export const DropzoneContainer = styled.div<DropzoneContainerProps>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${colors.gray500};
  border-radius: 5px;
  border-color: ${(props) => getBorderColor(props)};
  background-color: ${(props) =>
    props.isDragActive || props.accepted ? colors.blue50 : colors.white};
  outline: none;

  min-height: 120px;
  ${(props) => !props.isMobile && 'min-width: 553px;'}

  cursor: pointer;

  &:hover {
    background-color: ${colors.gray50};
    border-color: ${colors.gray500};
  }

  transition: background-color 0.2s ease-in-out;
`;

export const RejectedMessage = styled.div`
  color: ${colors.red300};
  font-size: 12px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
`;

export const SupportedFileTypes = styled.div`
  font-size: 12px;
  color: ${colors.gray500};
`;

export const MaxFileSize = styled.div`
  font-size: 12px;
  color: ${colors.gray500};
`;

export const FileAccepted = styled.div`
  display: flex;
  svg {
    margin-top: 2px;
    margin-right: 4px;
  }
`;

export const FileSize = styled.div`
  margin-left: 4px;
  margin-top: 2px;
  font-size: 12px;
  color: ${colors.gray500};
`;

export const DropMessage = styled.div`
  color: ${colors.gray500};
  strong {
    font-weight: normal;
    color: ${colors.blue400};
  }
`;
