import styled from 'styled-components';

export const NavigationGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavigationContainer = styled.div`
  background: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
