import colors from 'constants/colors';

export type WarningIconProps = {
  color?: string;
  size?: number;
};

export const WarningIcon = ({
  color = colors.red400,
  size = 16,
}: WarningIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 16 16`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.69991 2.7675C7.89186 2.43159 8.37621 2.43159 8.56815 2.76749L13.845 12.0019C14.0354 12.3353 13.7948 12.75 13.4109 12.75H2.85721C2.4733 12.75 2.23262 12.3353 2.42309 12.0019L7.69991 2.7675Z"
      stroke={color}
    />
    <path
      d="M7.54541 6.04199C7.54541 5.74266 7.78807 5.5 8.0874 5.5C8.38674 5.5 8.6294 5.74266 8.6294 6.04199L8.62939 8.09894C8.62939 8.39827 8.38674 8.64093 8.0874 8.64093C7.78807 8.64093 7.54541 8.39827 7.54541 8.09894L7.54541 6.04199ZM8.71729 10.3229C8.71729 10.4987 8.66455 10.6472 8.55908 10.7683C8.44971 10.8894 8.28955 10.95 8.07861 10.95C7.86768 10.95 7.70752 10.8894 7.59814 10.7683C7.48877 10.6472 7.43408 10.4987 7.43408 10.3229C7.43408 10.1471 7.48877 10.0006 7.59814 9.88337C7.70752 9.76616 7.86768 9.70756 8.07861 9.70756C8.28955 9.70756 8.44971 9.76616 8.55908 9.88337C8.66455 10.0006 8.71729 10.1471 8.71729 10.3229Z"
      fill={color}
    />
  </svg>
);
