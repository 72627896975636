import md5 from 'md5';
import ReactImageFallback from 'react-image-fallback';

export type AvatarProps = {
  email: string;
  size?: number;
  fallbackImage?: any;
  initialImage?: any;
  onError?: () => void;
};

export const Avatar = ({
  email,
  size = 32,
  fallbackImage = null,
  initialImage = null,
  onError,
}: AvatarProps) => {
  const gravatarUrl = `https://gravatar.com/avatar/${md5(
    email.toLowerCase().trim(),
  )}?size=${size}&default=404`;

  return (
    <ReactImageFallback
      src={gravatarUrl}
      fallbackImage={fallbackImage || '/images/user-circle.svg'}
      initialImage={initialImage || '/images/user-circle.svg'}
      alt="user profile"
      style={{ height: `${size}px`, borderRadius: '50%' }}
      onError={onError}
    />
  );
};

export default Avatar;
