import React from 'react';
import FaSearch from 'react-icons/lib/fa/search';
import OutsideClickHandler from 'react-outside-click-handler';
import Select, {
  ActionMeta,
  components,
  InputActionMeta,
  OnChangeValue,
} from 'react-select';
import { SelectContainer } from './index.css';

import colors from 'constants/colors';

export type MultiselectOption = {
  key: string;
  label: string;
  value: string;
};

export type MultiselectProps = {
  options?: MultiselectOption[];
  value?: MultiselectOption[];
  placeholder?: string;
  onInputChange:
    | ((newValue: string, actionMeta: InputActionMeta) => void)
    | undefined;
  onChange:
    | ((
        value: OnChangeValue<MultiselectOption, true>,
        action: ActionMeta<MultiselectOption>,
      ) => void)
    | undefined;
  onOutsideClick?: ((e: MouseEvent) => void) | undefined;
  marginRight?: number;
  style?: React.CSSProperties;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaSearch />
    </components.DropdownIndicator>
  );
};

export const Multiselect = ({
  options = [],
  placeholder = '',
  onInputChange,
  onChange,
  value,
  marginRight,
  style = {},
  onOutsideClick = undefined,
}: MultiselectProps) => {
  const styleControl = {
    background: colors.gray50,
    border: `1px solid ${colors.gray300}`,
    boxShadow: 0,
    '&:hover': {
      border: `1px solid ${colors.gray300}`,
    },
    // minHeight: '32px',
    // height: '32px',
  };

  const SelectComponent = (
    <Select
      isMulti={true}
      menuIsOpen={false}
      options={options}
      value={value}
      placeholder={placeholder}
      onInputChange={onInputChange}
      onChange={onChange}
      components={{ DropdownIndicator }}
      styles={{
        control: (base: any) => ({
          ...base,
          ...styleControl,
        }),
      }}
    />
  );

  return (
    <SelectContainer marginRight={marginRight} style={style}>
      {onOutsideClick === undefined && SelectComponent}
      {onOutsideClick && (
        <OutsideClickHandler onOutsideClick={onOutsideClick}>
          {SelectComponent}
        </OutsideClickHandler>
      )}
    </SelectContainer>
  );
};
