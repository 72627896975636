import colors from 'constants/colors';
import styled, { css } from 'styled-components';

const interacted = css`
  border: 1px solid ${colors.gray100};
  background: ${colors.gray100};
  visibility: visible;
  transition: 0.3s;
`;

export const EllipsisIconWrapper = styled.div<{ active?: boolean }>`
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 3px;
  vertical-align: middle;

  &: hover {
    ${interacted}
  }

  &: active {
    border: 1px solid ${colors.gray300};
    background: ${colors.gray300};
  }

  ${(props) => (props.active ? interacted : ``)};
`;
