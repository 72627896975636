import { primaryColors } from 'constants/colors';

export type CheckCircularIconProps = { color?: string; size?: number };

export const CheckCircularIcon = ({
  color = primaryColors.main,
  size = 18,
}: CheckCircularIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="9" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 9.45056L4.2 8.29671L7.28572 11.2637L13.8 5L15 6.15385L7.28572 13.5714L3 9.45056Z"
      fill="white"
    />
  </svg>
);
