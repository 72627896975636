import styled from 'styled-components';

import colors from 'constants/colors';

export const SidebarWrapper = styled.aside`
  background: ${colors.white};
  position: fixed;
  left: -316px;
  transition-timing-function: cubic-bezier(0.45, 0, 1, 1);
  transition: 0.45s;
  top: 0;
  z-index: 1004;
  height: 100vh;

  &.open {
    width: 100%;
    max-width: 316px;
    left: 0;
    display: flex;
    flex-direction: column;
  }
`;

export const ToggleButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

export const LogoContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  background: ${colors.white};
`;

export const CustomLogo = styled.img`
  width: 100%;
  max-height: 100px;
`;

export const HibooLogo = styled.div`
  height: 22px;
`;

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid ${colors.gray300};
`;

export const SidebarContent = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const SidebarFooter = styled.div`
  margin-top: auto;
  padding-bottom: 20px;
`;

export const WorkspaceTitle = styled.span`
  width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
