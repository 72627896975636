export type ActionType = {
  type: string;
};

export const SIDENAV = {
  REFRESH: 'SIDENAV_REFRESH',
};

export const refresh = () => ({
  type: SIDENAV.REFRESH,
});
