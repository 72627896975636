import colors from 'constants/colors';
import styled from 'styled-components';

export const WorkspaceSelectButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 32px;
  max-width: 100%;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
  user-select: none;
  :hover {
    background: ${colors.gray300Hover};
  }
  :active {
    background: ${colors.gray300};
  }

  transition: background 0.1s;
`;

export const WorkspacesLabel = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
`;

export const TextContainer = styled.div<{ hasResetIcon: boolean }>`
  display: flex;
  max-width: ${(props) => (props.hasResetIcon ? '96%' : '100%')};
`;

export const ChevronContainer = styled.div`
  padding: 4px;
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Left = styled.div`
  padding: 7px 0px 8px 7px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  max-width: 96%;
`;

export const Placeholder = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding-left: 4px;
  font-weight: 400;
  color: ${colors.gray500};
`;

export const ResetContainer = styled.div`
  margin-top: 4px;
  padding-right: 10px;
  padding-top: 4px;
  display: flex;
  margin-left: auto;
  border-right: 1px solid ${colors.gray300};
  svg {
    color: ${colors.gray300};
    width: 10px;
    height: 10px;

    &:hover {
      color: ${colors.gray500};
    }
  }
`;
