import React, { useState } from 'react';

import { Container, StepperProgress } from './index.css';
import { Step as StepComponent } from './Step';

type StepperProps = {
  steps: Step[];
};

export type Step = {
  index: number;
  label: React.ReactNode;
  title: string;
};

export const Stepper: React.FC<StepperProps> = ({ steps }) => {
  const [currentIndex] = useState(1);

  return (
    <Container>
      <StepperProgress />
      {steps.map((step) => (
        <StepComponent
          key={step.index}
          step={step}
          isCurrent={currentIndex === step.index}
        />
      ))}
    </Container>
  );
};
