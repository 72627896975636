import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import colors from 'constants/colors';

const scaleAnimation = keyframes`
  0% { transform: scale(0.75); opacity: 0; }
  100% { transform: scale(1); opacity: 100%; }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 197px 590px 180px;
  grid-template-rows: auto auto;
  padding-bottom: 40px;
`;

export const PlaceholderContainer = styled.div<{ animate: boolean }>`
  align-items: center;
  color: ${colors.gray850};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  ${({ animate }) =>
    animate &&
    css`
      animation-name: ${scaleAnimation};
      animation-duration: 300ms;
    `}
`;

export const ImgContainer = styled.div`
  position: relative;
  margin-bottom: 44px;

  img {
    max-width: 368px;
    max-height: 258px;
  }
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;
