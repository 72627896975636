import colors from 'constants/colors';

export type ArchiveIconProps = {
  size?: number;
  color?: string;
};

export const ArchiveIcon = ({
  size = 16,
  color = colors.gray850,
}: ArchiveIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.88 2H2.12C2.05373 2 2 2.05373 2 2.12V4.7H14V2.12C14 2.05373 13.9463 2 13.88 2Z"
      stroke={color}
    />
    <path d="M2.59961 4.3999V13.9999H13.3996V4.3999" stroke={color} />
    <rect
      x="4.70039"
      y="7.0998"
      width="6.6"
      height="0.6"
      rx="0.3"
      stroke={color}
      strokeWidth="0.6"
    />
  </svg>
);
