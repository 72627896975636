import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CircularSpinner, HModal } from 'modules/common-ui';

import { ButtonProps } from '../Button';
import {
  ConfirmModalBodyContainer,
  ConfirmModalFooterContainer,
  ConfirmModalHeader,
} from './ConfirmModal.css';

export type ConfirmModalProps = {
  isOpen: boolean;
  headerLabel: string;
  message: string | ReactElement;
  onSubmit: () => any;
  onCancel: () => any;
  submitLabel: string;
  cancelLabel?: string;
  submitBtnVariant?: ButtonProps['variant'];
  isLoading?: boolean;
  style?: React.CSSProperties;
};

export const ConfirmModal = ({
  isOpen,
  headerLabel,
  message,
  submitLabel,
  cancelLabel,
  onSubmit,
  onCancel,
  submitBtnVariant,
  isLoading = false,
  style = {},
}: ConfirmModalProps) => {
  const [t] = useTranslation('translation');

  return (
    <HModal
      isOpen={isOpen}
      style={{ width: 400, ...style }}
      onRequestClose={onCancel}
    >
      <HModal.Header>
        <ConfirmModalHeader>{headerLabel}</ConfirmModalHeader>
      </HModal.Header>
      <HModal.Body>
        <ConfirmModalBodyContainer>{message}</ConfirmModalBodyContainer>
      </HModal.Body>
      <ConfirmModalFooterContainer>
        <Button
          disabled={isLoading}
          variant={submitBtnVariant ? submitBtnVariant : 'outline-primary'}
          onClick={() => {
            onSubmit();
          }}
        >
          {isLoading ? <CircularSpinner /> : submitLabel}
        </Button>
        <Button onClick={onCancel} disabled={isLoading}>
          {cancelLabel ? cancelLabel : t('common.cancel')}
        </Button>
      </ConfirmModalFooterContainer>
    </HModal>
  );
};
