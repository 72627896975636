import styled from 'styled-components';

export const DataFetchTimeoutContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const ImgContainer = styled.div`
  position: relative;
  margin-bottom: 44px;

  img {
    max-width: 368px;
    max-height: 258px;
  }
`;
