import colors from 'constants/colors';
import styled from 'styled-components';

const variants: { [k: string]: (c: string) => string } = {
  classic: (color: string) =>
    `
      background-color: ${color};
      color: ${color === colors.black ? colors.white : colors.black};
    `,
  outline: (color: string) =>
    `
      background-color: none;
      color: ${color};
      border: 1px solid ${color};
    `,
};

export const StyledTag = styled.div<{
  color: string;
  height: number;
  maxWidth?: number;
  variant?: string;
  disabled?: boolean;
}>`
  align-items: center;
  border-radius: 3px;
  display: flex;
  line-height: ${(props) => `${props.height}px`};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
  padding: 2px 6px;
  position: relative;
  user-select: none;
  ${(props) => (props.disabled ? 'opacity: 0.5;' : '')}
  ${(props) =>
    props.variant
      ? variants[props.variant](props.color)
      : variants.classic(props.color)}
`;
