import React from 'react';

import { CheckIcon, Label, StyledCheckbox, Wrapper } from './index.css';

export type CheckboxProps = {
  checked: boolean;
  onChange: (event: React.MouseEvent<HTMLInputElement>) => void;
  label?: string;
  isDanger?: boolean;
  disabled?: boolean;
  color?: string;
};

// ignore JSX warning
export const Checkbox = ({
  checked,
  onChange,
  label = '',
  disabled = false,
  isDanger = false,
  color = undefined,
}: CheckboxProps) => {
  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (!disabled) onChange(e);
  };

  return (
    <Wrapper>
      <StyledCheckbox
        color={color}
        checked={checked}
        danger={isDanger}
        disabled={disabled}
        onClick={handleClick}
        className={`checkbox${checked ? '-checked' : ''}`}
      >
        <CheckIcon visibility={checked ? 'visible' : 'hidden'} />
      </StyledCheckbox>
      <Label danger={isDanger} onClick={disabled ? () => {} : onChange}>
        {label}
      </Label>
    </Wrapper>
  );
};
