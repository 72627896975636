import { BREAKPOINT_TABLET } from 'constants/breakpoints';
import colors from 'constants/colors';
import styled from 'styled-components';

export const FaultCountItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MetricWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const MetricLabel = styled.div`
  color: ${colors.gray500};
  text-transform: lowercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;

export const MetricCounter = styled.div`
  font-style: normal;
`;

export const FaultsCountWrapper = styled.div<{ $size: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${(props) =>
    props.$size === 'small' &&
    `
    margin: 4px;
    ${MetricWrapper} {
      font-size: 14px;
      line-height: 18px;
      ${MetricCounter} {
        font-weight: 400;
      }
    }
  `}
  ${(props) =>
    props.$size === 'big' &&
    `
    ${MetricWrapper} {
      font-size: 18px;
      line-height: 22px;
      ${MetricCounter} {
        font-weight: 500;
      }
    }
  `}
`;

export const FaultsCountSection = styled.div<{ $size: string }>`
  display: flex;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  ${(props) => props.$size === 'big' && `padding: 15px;`}
`;

export const FaultsCountSectionDivider = styled.hr`
  width: 100%;
  margin: 0;
  @media (min-width: ${BREAKPOINT_TABLET}px) {
    display: none;
  }
`;
