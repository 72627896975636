import { useTranslation } from 'react-i18next';

import { Card, CardHeader } from 'modules/signup/common';

import { SupportText, Text } from './ConfirmEmail.css';

export type ConfirmEmailProps = {
  email: string;
};

export const ConfirmEmail = ({ email }: ConfirmEmailProps) => {
  const [t] = useTranslation('signup');

  return (
    <Card width={420}>
      <CardHeader displayLogo={true} title={t('confirmEmail.title')} />

      <Text
        dangerouslySetInnerHTML={{
          __html: t('confirmEmail.text1', {
            email,
            interpolation: { escapeValue: false },
          }),
        }}
      />
      <Text>{t('confirmEmail.text2')}</Text>
      <SupportText>
        {t('confirmEmail.support.text')}
        <a href="mailto:support@hiboo.io" rel="noreferrer">
          {t('confirmEmail.support.link')}
        </a>
        .
      </SupportText>
    </Card>
  );
};
