import colors from 'constants/colors';
import { IconLeftWrapper } from 'modules/common-ui/components/Button/index.css';
import styled from 'styled-components';

export const AddWorkspaceRowContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid ${colors.gray300};
  button {
    width: 100%;

    ${IconLeftWrapper} {
      width: 28px;
      margin-right: 0px;
    }
  }
`;
