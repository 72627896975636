import styled from 'styled-components';

export const Footer = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 16px;

  button + button {
    margin-top: 8px;
  }
`;
