import styled from 'styled-components';

import colors, { primaryColors } from 'constants/colors';
import { GenericStyledInlineLinkCSS } from 'modules/common-ui/components/InlineLink/index.css';

export const DRAWER_TRANSITION_MS = 350;

export const Wrapper = styled.div`
  z-index: 1004;
  width: 100%;
  bottom: 0;
  flex: 1;
  height: 0;
  left: 0;
  transition: height ${DRAWER_TRANSITION_MS}ms; /* transition at closing */
  position: fixed;
  background: ${colors.white};
  overflow-y: scroll;

  &.open {
    height: calc(100vh - calc(100vh - 100%));
    transition: height ${DRAWER_TRANSITION_MS}ms; /* transition at opening */
  }
`;

export const Header = styled.div<{ isFixed?: boolean }>`
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 26px;
  padding: 11px 16px;
  border-bottom: 1px solid ${colors.gray300};
  width: 100%;

  ${(props) =>
    props.isFixed &&
    `
      position: fixed;
      background: ${colors.white};
      z-index: 1;
    `}
`;

export const HeaderLeft = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  text-align: center;
  min-width: 0;
  overflow: hidden;
  justify-content: center;
`;

export const HeaderTitle = styled.h3`
  margin: 0;
  padding: 0;
  color: ${colors.gray800};
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const HeaderRight = styled.div`
  ${GenericStyledInlineLinkCSS}
  justify-content: flex-end;
  color: ${primaryColors.main};
  overflow: visible;
  margin-right: -8px;
`;

export const Content = styled.div<{ withHeaderPadding?: boolean }>`
  ${(props) =>
    props.withHeaderPadding &&
    `
      padding-top: 48px;
  `}
`;
