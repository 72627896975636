import styled from 'styled-components';

import colors from 'constants/colors';

type ProgressBarContainerProps = {
  height: number;
};

export const ProgressBarContainer = styled.div<ProgressBarContainerProps>`
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: ${colors.gray200};
  border-radius: 5px;
`;

type ProgressProps = {
  percent: number;
  height: number;
};

export const Progress = styled.div<ProgressProps>`
  height: ${(props) => props.height}px;
  background-color: ${colors.cyan300};
  width: ${(props) => props.percent}%;
  border-radius: 5px;
`;
