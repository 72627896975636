import styled from 'styled-components';

import colors from 'constants/colors';

export const CrossIconWrapper = styled.div<{
  color?: string;
  hoverColor?: string;
}>`
  svg {
    cursor: pointer;
    path {
      fill: ${(props) => props.color || colors.gray300};
    }

    &:hover {
      path {
        fill: ${(props) => props.hoverColor || colors.gray500};
      }
    }
  }
`;
