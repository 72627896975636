import colors from 'constants/colors';

export type HibooSmIconProps = {
  small?: boolean;
  color?: string;
};

export const HibooIcon = ({
  small = false,
  color = colors.gray850,
}: HibooSmIconProps) => (
  <svg
    width={small ? 9 : 22}
    height={small ? 13 : 30}
    viewBox={small ? '0 0 9 13' : '0 0 22 30'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {small ? (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.08008 12.3872C1.71613 10.4695 0 7.51103 0 4.54574C0 3.23168 0.323795 1.99322 0.895987 0.905706L4.08008 2.86736L7.25441 0.887207C7.83268 1.97916 8.16016 3.22423 8.16016 4.54574C8.16016 7.51103 6.41018 10.4695 4.08008 12.3872ZM3.25332 3.37969L1.28666 2.16807C1.01283 2.9213 0.870161 3.72276 0.870161 4.54574C0.870161 6.89953 2.10929 9.40383 4.07648 11.2324C6.04124 9.38637 7.29002 6.87971 7.29002 4.54574C7.29002 3.71739 7.14548 2.91085 6.86816 2.15338L4.8787 3.39441C5.09007 3.69197 5.22092 4.10409 5.22092 4.55948C5.22092 5.4668 4.70148 6.20233 4.06072 6.20233C3.41996 6.20233 2.90052 5.4668 2.90052 4.55948C2.90052 4.09653 3.03575 3.67831 3.25332 3.37969ZM4.51996 4.55948C4.51996 4.91863 4.31435 5.20977 4.06071 5.20977C3.80708 5.20977 3.60147 4.91863 3.60147 4.55948C3.60147 4.20033 3.80708 3.90918 4.06071 3.90918C4.31435 3.90918 4.51996 4.20033 4.51996 4.55948Z"
        fill={color}
      />
    ) : (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0001 29.6371C4.90782 24.695 0.485107 17.0705 0.485107 9.42856C0.485107 6.04205 1.31957 2.85035 2.79419 0.0476757L11.0001 5.10313L19.1808 0C20.671 2.81413 21.515 6.02283 21.515 9.42856C21.515 17.0705 17.0051 24.695 11.0001 29.6371ZM8.86939 6.42348L3.80102 3.30097C3.0953 5.24215 2.72764 7.30764 2.72764 9.42856C2.72764 15.4946 5.92105 21.9485 10.9908 26.661C16.0542 21.9036 19.2725 15.4435 19.2725 9.42856C19.2725 7.29378 18.9 5.21521 18.1853 3.26312L13.0582 6.46142C13.6029 7.22828 13.9402 8.29036 13.9402 9.46396C13.9402 11.8023 12.6015 13.6978 10.9502 13.6978C9.29883 13.6978 7.96016 11.8023 7.96016 9.46396C7.96016 8.27088 8.30866 7.19306 8.86939 6.42348ZM12.1337 9.46397C12.1337 10.3895 11.6038 11.1399 10.9501 11.1399C10.2965 11.1399 9.76661 10.3895 9.76661 9.46397C9.76661 8.53839 10.2965 7.78806 10.9501 7.78806C11.6038 7.78806 12.1337 8.53839 12.1337 9.46397Z"
        fill={color}
      />
    )}
  </svg>
);
