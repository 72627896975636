export const ArrowUpTrendIcon = ({
  color,
  backgroundColor,
  style,
}: {
  color: string;
  backgroundColor: string;
  style?: React.CSSProperties;
}) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <rect
      x="1.74023"
      y="1.13574"
      width="14"
      height="14"
      rx="7"
      fill={backgroundColor}
    />
    <path
      d="M8.74023 11.1357V5.13574"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.04736 7.82866L8.74028 5.13574"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.4331 7.82866L8.74023 5.13574"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
