import { FleetSegment } from 'modules/analytics/components/AssetFilter/types';
import { useTranslation } from 'react-i18next';
import MdFilter from 'react-icons/lib/md/filter-list';
import styled from 'styled-components';
import { Button } from '../Button';
import { CircularSpinner } from '../CircularSpinner';
import { SelectedFilter } from './Filter';

const countActiveFilters = (selectedFilters: SelectedFilter[]) => {
  let count = 0;

  selectedFilters.forEach((f) => {
    if (f.type && f.value !== undefined) {
      if (Array.isArray(f.value)) {
        count += f.value.length;
      } else if (f.value !== '') {
        count += 1;
      }
    }
  });

  return count;
};

type FilterButtonProps = {
  disabled: boolean;
  activeSegment: FleetSegment | null;
  selectedFilters: SelectedFilter[];
  show: () => void;
  small: boolean;
  tooltip: string;
  loading?: boolean;
};

const TextWrapper = styled.div`
  max-width: 261px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FilterButton = ({
  disabled,
  activeSegment,
  selectedFilters,
  show,
  small,
  tooltip,
  loading = false,
}: FilterButtonProps) => {
  const activeFiltersCount = countActiveFilters(selectedFilters);
  const displayActiveSegment =
    activeSegment && activeSegment.kind !== 'default';
  const isActive = Boolean(
    displayActiveSegment || countActiveFilters(selectedFilters) > 0,
  );
  const { t } = useTranslation('commonUi');

  const renderInnerContent = () => {
    if (loading) {
      return <CircularSpinner />;
    }

    if (small) {
      return <MdFilter />;
    }

    if (displayActiveSegment) {
      return (
        <TextWrapper title={activeSegment.name}>
          {activeSegment.name}
        </TextWrapper>
      );
    }

    return t('filter.button.labelWithCount', { count: activeFiltersCount });
  };

  return (
    <Button
      disabled={disabled}
      variant={isActive ? 'primary' : 'neutral'}
      onClick={() => {
        show();
      }}
      tooltip={tooltip}
      iconLeft={!small && <MdFilter />}
    >
      {renderInnerContent()}
    </Button>
  );
};
