export type DoubleChevronRightProps = {
  size?: number;
  color?: string;
  onClick?: () => void;
};

export const DoubleChevronRightIcon = ({
  size = 14,
  color,
  onClick,
}: DoubleChevronRightProps) => (
  <svg
    width={`${size}`}
    height={`${size}`}
    viewBox={`0 0 ${size} ${size}`}
    fill={color || 'none'}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      d="M7 11.9L8.225 13.125L14 7L8.225 0.875L7 2.1L11.55 7L7 11.9ZM0 11.9L1.225 13.125L7 7L1.225 0.875L8.56743e-07 2.1L4.55 7L0 11.9Z"
      fill={color || '#37352F'}
      fillOpacity="0.4"
    />
  </svg>
);
