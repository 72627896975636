import colors from 'constants/colors';
import { Button } from '../Button';
import { ArrowRightIcon } from '../Icons';

type CardHeaderButtonProps = {
  onClick: () => void;
  label: string;
};

export const CardHeaderButton = ({ onClick, label }: CardHeaderButtonProps) => {
  return (
    <Button
      style={{ color: colors.gray500 }}
      variant="transparent"
      onClick={onClick}
      iconRight={
        <div
          style={{
            marginLeft: '-4px',
            marginRight: '-4px',
            marginTop: '4px',
          }}
        >
          <ArrowRightIcon color={colors.gray500} size={14} />
        </div>
      }
    >
      {label}
    </Button>
  );
};
