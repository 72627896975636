import { useEffect } from 'react';
import { primaryComponentsLazy, secondaryComponentsLazy } from './lazy-loader';

type ComponentLazy = {
  preload: () => Promise<any>;
};

type GroupComponentsLazy = {
  [key: string]: ComponentLazy;
};

export const useLazyPreloadComponents = () => {
  useEffect(() => {
    loadLazyComponents(primaryComponentsLazy, secondaryComponentsLazy);
  }, []);

  return {
    ...primaryComponentsLazy,
    ...secondaryComponentsLazy,
  };
};

const loadLazyComponents = async (
  mainComponentsLazy: GroupComponentsLazy,
  extraComponentsLazy: GroupComponentsLazy,
) => {
  // 1- Preload the main used components
  await Promise.all(
    Object.keys(mainComponentsLazy).map((componentName) =>
      mainComponentsLazy[componentName].preload(),
    ),
  );
  // 2- Preload the secondary components
  await Promise.all(
    Object.keys(extraComponentsLazy).map((componentName) =>
      extraComponentsLazy[componentName].preload(),
    ),
  );
};
