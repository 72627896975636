import { gql } from '@apollo/client';

export type ReceivedSharingSummaryByOrganization = {
  organizationName: string;
  countSharings: number;
  countSharedAssets: number;
};

export type GetReceivedSharingSummaryQuery = {
  viewer: {
    id: string;
    sharing: {
      receivedSharingSummary: ReceivedSharingSummaryByOrganization[];
    };
  };
};

export const getReceivedSharingSummaryQuery = gql`
  query GetReceivedSharingSummary($retainedOrganizationsCount: Int!) {
    viewer {
      id
      sharing {
        receivedSharingSummary(retainedOrganizationsCount: $retainedOrganizationsCount) {
          organizationName
          countSharings
          countSharedAssets
        }
      }
    }
  }
`;
