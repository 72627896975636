import { ReactChild, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Action, Dispatch, bindActionCreators } from 'redux';

import { UserApi } from 'modules/api-client';
import { HibooLogoLarge } from 'modules/common-ui';
import layout from 'modules/layout';
import { Store } from 'reducers';

import { userLoginSuccess } from '../../actions';
import { ResetPasswordForm } from './ResetPasswordForm';
import { SendToken } from './SendToken';

import { LogoWrapper } from '../index.css';

export type ResetPasswordProps = {
  locale: string;
  userLoginSuccess: typeof userLoginSuccess;
};

const ResetPasswordComponent = ({
  locale,
  userLoginSuccess,
}: ResetPasswordProps) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [email] = useState(params.get('email') || '');
  const [token] = useState(params.get('token') || '');

  const onResetPasswordSuccess = (user: UserApi) => {
    userLoginSuccess(user);
  };

  let content = null as ReactChild | null;
  if (!token) {
    content = <SendToken email={email} locale={locale} />;
  } else {
    content = (
      <ResetPasswordForm
        token={token}
        email={email}
        onSuccess={onResetPasswordSuccess}
      />
    );
  }

  return (
    <layout.Page
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LogoWrapper>
        <HibooLogoLarge />
      </LogoWrapper>
      {content}
    </layout.Page>
  );
};

const mapStateToProps = (state: Store) => ({
  locale: state.language.language,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators({ userLoginSuccess }, dispatch);

export const ResetPassword = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordComponent);
