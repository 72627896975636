import colors from 'constants/colors';

export type CircleIconProps = { color?: string; size?: number; fill?: string };

export const CircleIcon = ({ color, size, fill }: CircleIconProps) => (
  <svg
    width={size || '12'}
    height={size || '12'}
    viewBox="0 0 12 12"
    fill={color || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="6"
      cy="6"
      r="5"
      stroke={fill || colors.gray850}
      strokeWidth="2"
    />
  </svg>
);
