import styled from 'styled-components';

export const Message = styled.div`
  margin-bottom: 16px;
  text-align: center;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 156px;
  padding: 16px;
  font-size: 14px;
  line-height: 18px;

  button + button {
    margin-top: 8px;
  }
`;
