import { AssetFound } from 'modules/global-search/models/AssetFound.model';
import { ZoneFound } from 'modules/global-search/models/ZoneFound.model';
import {
  AssetSearchFragment,
  ZoneSearchFragment,
} from '../getGlobalSearch/gql.generated';
import { assetSearchResultToAssetFound } from './assetSearchResultToAssetFound';
import { zoneSearchResultToZoneFound } from './zoneSearchResultToZoneFound';

type GlobalSearchResult = AssetSearchFragment | ZoneSearchFragment | null;

export const globalSearchResultsToEntities = (
  globalSearchResults: Array<GlobalSearchResult>,
): Array<AssetFound | ZoneFound> => {
  const entities: Array<AssetFound | ZoneFound> = [];
  for (const searchResult of globalSearchResults) {
    if (!searchResult) {
      continue;
    }
    switch (searchResult.__typename) {
      case 'AssetSearchResult':
        entities.push(assetSearchResultToAssetFound(searchResult));
        break;
      case 'ZoneSearchResult':
        entities.push(zoneSearchResultToZoneFound(searchResult));
        break;
      default:
        console.warn('Unknown search result type:', searchResult);
        break;
    }
  }
  return entities;
};
