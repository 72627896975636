import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ProgressCircular } from '../Icons';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    flex-shrink: 0;
  }

  span {
    margin-left: 8px;
  }
`;

export const IntegrationInProgress = () => {
  const [t] = useTranslation('translation');

  const messages = [
    t('integration.status.IN_PROGRESS.message1'),
    t('integration.status.IN_PROGRESS.message2'),
    t('integration.status.IN_PROGRESS.message3'),
  ];

  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((mi) => {
        return (mi + 1) % messages.length;
      });
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <ProgressCircular />
      <span>{messages[messageIndex]}</span>
    </Container>
  );
};
