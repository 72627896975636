import colors from 'constants/colors';

export type QuestionMarkProps = {
  size?: number;
  color?: string;
};

export const QuestionMark = ({
  size = 16,
  color = colors.gray500,
}: QuestionMarkProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={size / 2} cy={size / 2} r={(size - 3) / 2} stroke={color} />
    <path
      d="M7.36081 9.32058C7.36771 8.90973 7.41432 8.58519 7.50064 8.34697C7.58695 8.10874 7.76303 7.84462 8.02887 7.55461L8.7073 6.85547C8.99731 6.52748 9.14231 6.17533 9.14231 5.799C9.14231 5.43649 9.04737 5.15338 8.85748 4.94968C8.66759 4.74253 8.39139 4.63895 8.02887 4.63895C7.67672 4.63895 7.39361 4.73217 7.17955 4.91861C6.9655 5.10504 6.85847 5.35535 6.85847 5.66953H5.90039C5.9073 5.11022 6.10582 4.65967 6.49595 4.31787C6.88954 3.97261 7.40051 3.79999 8.02887 3.79999C8.6814 3.79999 9.18892 3.97607 9.55144 4.32822C9.91741 4.67693 10.1004 5.15683 10.1004 5.76793C10.1004 6.37212 9.82074 6.96768 9.26143 7.55461L8.69694 8.11392C8.4449 8.39358 8.31889 8.7958 8.31889 9.32058H7.36081ZM7.31938 10.9623C7.31938 10.8069 7.36599 10.6774 7.45921 10.5738C7.55588 10.4668 7.69743 10.4133 7.88387 10.4133C8.0703 10.4133 8.21186 10.4668 8.30853 10.5738C8.4052 10.6774 8.45353 10.8069 8.45353 10.9623C8.45353 11.1176 8.4052 11.2471 8.30853 11.3507C8.21186 11.4508 8.0703 11.5009 7.88387 11.5009C7.69743 11.5009 7.55588 11.4508 7.45921 11.3507C7.36599 11.2471 7.31938 11.1176 7.31938 10.9623Z"
      fill={color}
    />
  </svg>
);
