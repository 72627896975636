import { useState } from 'react';

export const useClosableBannerLocalStorage = (closableBannerId: string) => {
  const [isClosed, setIsClosed] = useState<boolean>(
    localStorage.getItem(`banner_${closableBannerId}`) === 'true',
  );

  const close = () => {
    setIsClosed(true);
    localStorage.setItem(`banner_${closableBannerId}`, 'true');
  };

  return {
    isClosed,
    close,
  };
};
