import { ZoneFound } from 'modules/global-search/models/ZoneFound.model';
import { ZoneSearchFragment } from '../getGlobalSearch/gql.generated';

export const zoneSearchResultToZoneFound = (row: ZoneSearchFragment) => {
  const fields = {
    id: row.id,
    name: row.name,
    category:
      row.category && row.category.id
        ? {
            id: Number(row.category.id),
            name: row.category.name || '',
            color: row.category.color || '',
          }
        : null,
  };
  return new ZoneFound(fields);
};
