import { useTranslation } from 'react-i18next';

import { HibooBannerLogos } from './HibooBannerLogos';
import {
  PartnersFooterContainer,
  PartnersFooterLogos,
  PartnersFooterText,
} from './PartnersFooter.css';

export const PartnersFooter = () => {
  const [t] = useTranslation('signup');

  return (
    <PartnersFooterContainer>
      <PartnersFooterText
        dangerouslySetInnerHTML={{
          __html: t('footer.text', { interpolation: { escapeValue: false } }),
        }}
      />
      <PartnersFooterLogos style={{ height: '80px' }}>
        <HibooBannerLogos />
      </PartnersFooterLogos>
    </PartnersFooterContainer>
  );
};
