import colors from 'constants/colors';

export type CurvedArrowIconProps = {
  size?: number;
  color?: string;
};

export const CurvedArrowIcon = ({
  size = 16,
  color = colors.gray500,
}: CurvedArrowIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.5588 5.2747C64.8382 8.99366 64.1316 12.5594 63.4392 16.1251C63.2979 16.8355 63.199 17.3369 63.1708 18.2562C63.1425 18.9387 63.1142 20.3873 63.8632 20.262C64.4708 20.0948 64.8806 19.8998 65.248 18.688C66.1806 15.5541 66.6328 12.3087 67.0991 9.09116C67.5089 6.24971 67.7774 4.07684 67.9893 1.54182C68.0882 0.357883 67.4948 -0.115692 66.3926 0.023595C65.4034 0.148953 64.386 0.260382 63.4392 0.566812C60.0196 1.65325 56.6424 2.82325 53.2369 3.96541C52.8129 4.10469 52.4738 4.18827 51.9934 4.42505C51.414 4.71756 50.9759 4.89863 51.1455 5.49756C51.3009 6.06864 52.1488 6.04078 52.5727 5.94328C56.1337 5.20506 63.4534 3.56148 63.8632 3.56148C62.6762 5.38613 61.6164 7.09936 60.4577 8.75687C52.3325 20.262 43.4443 31.0985 32.0549 39.7203C23.0395 46.5454 13.3599 52.2004 2.80429 56.379C2.18253 56.6297 1.57492 56.9361 0.995556 57.2426C0.204235 57.6465 -0.134905 57.9947 0.0487941 58.4126C0.500977 59.1508 1.1086 59.0115 1.60318 58.9419C2.59233 58.8165 3.56734 58.4822 4.5141 58.1619C17.1328 53.7465 28.1831 46.7264 38.3431 38.2439C48.7715 29.5384 56.7978 18.8969 63.9338 7.54507C64.2871 6.93221 65.3045 5.49756 65.5588 5.2747Z"
      fill={color}
      stroke={color}
    />
  </svg>
);
