import colors, { categoryColors, zoneColors } from 'constants/colors';
import { SparkleIcon } from '../Icons/SparklesIcon';
import { Category as CategoryTag } from './Category';

type SuggestedCategoryProps = {
  category: {
    id: string;
    name: string;
    color: keyof typeof categoryColors | keyof typeof zoneColors;
  };
  onClick?: () => void;
};

const getDashedColor = (color: string) => {
  const colorMapping = {
    [categoryColors.default]: colors.gray500,
    [categoryColors.gray]: colors.gray400,
    [categoryColors.red]: colors.red400,
    [categoryColors.pink]: colors.pink500,
    [categoryColors.purple]: colors.purple400,
    [categoryColors.blue]: colors.blue400,
    [categoryColors.cyan]: colors.cyan400,
    [categoryColors.green]: colors.green400,
    [categoryColors.yellow]: colors.yellow400,
    [categoryColors.orange]: colors.orange500,
    [categoryColors.brown]: colors.brown500,
  };
  return colorMapping[color] || colors.gray100;
};

const renderIcon = () => {
  return (
    <SparkleIcon
      size={14}
      color={colors.black}
      htmlExtraProps={{ style: { margin: '0px 4px 0px 0px' } }}
    />
  );
};

export const SuggestedCategory = ({
  category,
  onClick,
}: SuggestedCategoryProps) => {
  const color = categoryColors[category.color] || categoryColors.default;

  return (
    <CategoryTag
      category={category}
      dashedColor={getDashedColor(color)}
      onClick={onClick}
      big={false}
      selectable={true}
      icon={() => renderIcon()}
      fullSize={false} // cut the category name if it's too long and display the tooltip
    />
  );
};
