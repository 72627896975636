import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 16px;
  display: flex;
  color: #2d2f30;
  height: 32px;
  font-weight: normal;
  align-items: center;
  cursor: pointer;

  transition: background 0.25s;
  &:hover {
    color: #2d2f30;
    background-color: rgba(45, 47, 48, 0.08);
  }
  &:active {
    color: #2d2f30;
    font-weight: 500;
    background-color: rgba(45, 47, 48, 0.08);
  }

  svg {
    margin-right: 8px;
  }
`;
