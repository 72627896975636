import browserUpdate from 'browser-update';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import './polyfill';

import App from './App';
import './styles/index.scss';

import i18n from './i18n';

browserUpdate({
  required: {
    e: -4,
    f: -3,
    o: -3,
    s: -1,
    c: -3,
  },
  insecure: true,
  mobile: false,
  unsupported: true,
  api: 2019.07,
});
moment.locale(i18n.resolvedLanguage);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
