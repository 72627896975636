import styled from 'styled-components';

type CardHeaderProps = {
  noPadding?: boolean;
  noMargin?: boolean;
};
export const CardHeader = styled.div<CardHeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
  ${(props) => (props.noPadding ? `padding: 0px;` : ``)};
  ${(props) => (props.noMargin ? `margin: 0px;` : ``)};
`;
