import styled from 'styled-components';

import colors from 'constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`;

export const HeaderTitle = styled.h3`
  margin: 0;
  padding: 0;
  color: ${colors.gray850};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.gray850};
`;

export const HeaderAction = styled.button`
  background: transparent;
  border: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: ${colors.cyan400};

  &:focus,
  &:hover {
    background: transparent;
    border: none;
  }
`;
