import colors from 'constants/colors';

export type DispatcherIconProps = {
  size?: number;
  color?: string;
};

export const DispatcherIcon = ({
  size = 16,
  color = colors.gray500,
}: DispatcherIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.48319 8.2832L3.73333 7.53333L0.533325 10.7333L3.73333 13.9333L4.48319 13.1835L2.56639 11.2667L13.3333 11.2667L13.3333 10.2L2.56639 10.2L4.48319 8.2832ZM11.5168 5.7168L12.2667 6.46667L15.4667 3.26667L12.2667 0.0666655L11.5168 0.816532L13.4336 2.73333L2.66666 2.73333L2.66666 3.8L13.4336 3.8L11.5168 5.7168Z"
      fill="#2D2F30"
    />
  </svg>
);
