import colors from 'constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 600px;
  min-width: 600px;
  max-height: 80vh;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 9px 0px 27px rgba(15, 15, 15, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

export const SearchResultsContainer = styled.div`
  border-top: 1px solid ${colors.gray200};
  display: flex;
  flex-direction: column;
  max-height: 360px;
  height: auto;
  overflow: auto;
  transition: transform 0.9s;
  transform: scaleY(1);
`;

export const NoResultsContainer = styled.div`
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const NoResultsLabel = styled.div`
  font-size: 16px;
  color: black;
`;

export const NoResultsSubtitle = styled.div`
  font-size: 12px;
  color: grey;
`;

type LoadingOverlayProps = {
  visible?: boolean;
};
export const LoadingOverlay = styled.div<LoadingOverlayProps>`
  position: fixed;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 100;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  transition: all 0.5s ease-in-out;
`;
