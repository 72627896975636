import styled from 'styled-components';

import colors from 'constants/colors';
import { CardSection } from 'modules/common-ui';

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 16px;
  width: 100%;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 316px;
`;

export const ScrollingCard = styled(CardSection)`
  max-height: 516px;
  overflow: auto;
`;

export const OptionRow = styled.div`
  align-items: center;
  color: ${colors.gray850};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
  padding: 6px 16px;
  transition: background 120ms ease-in 0s;

  :hover {
    cursor: pointer;
    background: #eff2f5;
  }
  :active {
    background: #d5d8db;
  }
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 18px;
`;
