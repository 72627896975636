import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import { FileIcon } from 'modules/common-ui';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import {
  FileType,
  MB_TO_BYTES,
  fileTypesToMimeTypes,
  getSizeMb,
} from 'utils/file';
import {
  DropMessage,
  DropzoneContainer,
  FileAccepted,
  FileSize,
  Footer,
  MaxFileSize,
  RejectedMessage,
  SupportedFileTypes,
} from './Dropzone.css';

type DropzoneProps = {
  supportedFileTypes: FileType[];
  onDrop: (files: File[]) => void;
  maxFileSizeMb: number;
};

export const Dropzone = ({
  supportedFileTypes,
  onDrop,
  maxFileSizeMb = 10,
}: DropzoneProps) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_START });
  const [t] = useTranslation('commonUi');
  const mimeTypes = fileTypesToMimeTypes(supportedFileTypes);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    rejectedFiles,
  } = useDropzone({
    accept: mimeTypes.join(', '),
    multiple: false,
    onDrop,
    maxSize: maxFileSizeMb * MB_TO_BYTES,
  });

  const renderDropMessage = () => {
    return (
      <DropMessage>
        <Trans i18nKey="commonUi:dropzone.dropMessage" />
      </DropMessage>
    );
  };

  const renderFileAccepted = (file: File) => {
    return (
      <FileAccepted>
        <FileIcon contentType={file.type} />
        <span>{file.name}</span>
        <FileSize>{`(${getSizeMb(file)}MB)`}</FileSize>
      </FileAccepted>
    );
  };

  const renderContent = () => {
    if (acceptedFiles.length) {
      const _file = acceptedFiles[0];
      return renderFileAccepted(_file);
    }
    return renderDropMessage();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {rejectedFiles.length ? (
        <RejectedMessage>{t('dropzone.rejectedMessage')}</RejectedMessage>
      ) : null}
      <DropzoneContainer
        {...getRootProps({ isDragActive, isDragAccept })}
        danger={rejectedFiles.length > 0}
        accepted={acceptedFiles.length > 0}
        isMobile={isMobile}
      >
        {renderContent()}
        <input {...getInputProps()} />
      </DropzoneContainer>
      <Footer>
        <SupportedFileTypes>
          {t('dropzone.supportedFiles', {
            types: supportedFileTypes.join(', '),
          })}
        </SupportedFileTypes>
        <MaxFileSize>
          {t('dropzone.maxFileSize', { size: maxFileSizeMb })}
        </MaxFileSize>
      </Footer>
    </div>
  );
};
