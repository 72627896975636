import React, { useEffect, useRef, useState } from 'react';

import { categoryColors, zoneColors } from 'constants/colors';
import { Tooltip } from 'modules/common-ui';

import { useSize } from 'modules/utils';
import { OptionText, Tag, Text } from './index.css';

export type CategoryProps = {
  category: {
    id: string | number;
    name: string;
    color: keyof typeof categoryColors | keyof typeof zoneColors;
  };
  big?: boolean;
  fullSize?: boolean;
  disabled?: boolean;
  showTooltip?: boolean;
  style?: React.CSSProperties;
  /**
   * Wether it is used as an option in a combobox input or a simple div.
   */
  isOption?: boolean;
  showTextTransition?: boolean;
  selectable?: boolean;
  dashedColor?: string;
  onClick?: () => void;
  icon?: () => React.ReactNode;
};

export const Category = ({
  category = { id: '', name: '', color: 'default' },
  big = false,
  disabled = false,
  fullSize = false,
  showTooltip = true,
  style,
  isOption = false,
  showTextTransition = false,
  selectable = false,
  dashedColor,
  onClick,
  icon,
}: CategoryProps) => {
  const [isEllipsis, setIsEllipsis] = useState(false);

  const textRef = useRef<HTMLOptionElement | HTMLDivElement | null>(null);

  const { width } = useSize(textRef);

  useEffect(() => {
    if (textRef.current) {
      const isHidden =
        textRef.current.scrollWidth > textRef.current.clientWidth;

      setIsEllipsis(isHidden);
    }
  }, [width]);

  if (!category) return null;
  const { id, name, color } = category;
  if (!name) return null;

  const bgColor = categoryColors[color] || categoryColors.default;

  const tooltipId = `${id}-${Math.round(Math.random() * 100000)}`;

  return (
    <Tag
      data-testid={`category-tag-${id}`}
      bgColor={bgColor}
      data-tip={isEllipsis ? name : null}
      data-for={showTooltip && isEllipsis ? tooltipId : null}
      style={style}
      big={big}
      fullSize={fullSize}
      disabled={disabled}
      dashedColor={dashedColor}
      selectable={selectable}
      onClick={onClick}
    >
      {isOption ? (
        <OptionText
          ref={textRef as React.MutableRefObject<HTMLOptionElement | null>}
          big={big}
        >
          {name}
        </OptionText>
      ) : (
        <>
          {icon && icon()}
          <Text
            ref={textRef as React.MutableRefObject<HTMLDivElement | null>}
            big={big}
            showTransition={showTextTransition}
          >
            {name}
          </Text>
        </>
      )}
      {showTooltip && isEllipsis && <Tooltip id={tooltipId} />}
    </Tag>
  );
};
