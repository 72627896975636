import { lazyWithPreload } from 'react-lazy-with-preload';

const AdministrationPagesLazy = lazyWithPreload(() =>
  import('modules/administration/pages').then((module) => ({
    default: module.default,
  })),
);
const AlertsConfigurationPagesLazy = lazyWithPreload(() =>
  import('modules/alerts/configuration/pages').then((module) => ({
    default: module.AlertsConfigurationPages,
  })),
);
const AssetPagesLazy = lazyWithPreload(() =>
  import('modules/assets/pages').then((module) => ({
    default: module.AssetPages,
  })),
);
const CatalogPageLazy = lazyWithPreload(() =>
  import('modules/catalog').then((module) => ({
    default: module.CatalogPage,
  })),
);
const DashboardPageLazy = lazyWithPreload(() =>
  import('modules/dashboard').then((module) => ({
    default: module.DashboardPage,
  })),
);
const DestinationsPageLazy = lazyWithPreload(() =>
  import('modules/destinations').then((module) => ({
    default: module.DestinationsPage,
  })),
);
const IntegrationsPageLazy = lazyWithPreload(() =>
  import('modules/integrations').then((module) => ({
    default: module.IntegrationsPage,
  })),
);
const JoinOrganizationPagesLazy = lazyWithPreload(() =>
  import('modules/join-organization').then((module) => ({
    default: module.JoinOrganizationPages,
  })),
);
const UserJobPageLazy = lazyWithPreload(() =>
  import('modules/join-organization').then((module) => ({
    default: module.UserJobPage,
  })),
);
const ProfilePageLazy = lazyWithPreload(() =>
  import('modules/profile').then((module) => ({
    default: module.ProfilePage,
  })),
);
const SettingsPageLazy = lazyWithPreload(() =>
  import('modules/settings/pages').then((module) => ({
    default: module.default,
  })),
);
const WorkspaceCreatePageLazy = lazyWithPreload(() =>
  import('modules/workspace-create').then((module) => ({
    default: module.WorkspaceCreatePage,
  })),
);
const ZoneCreatePageLazy = lazyWithPreload(() =>
  import('modules/zones2/pages').then((module) => ({
    default: module.ZoneCreatePage,
  })),
);
const ZoneEditPageLazy = lazyWithPreload(() =>
  import('modules/zones2/pages').then((module) => ({
    default: module.ZoneEditPage,
  })),
);
const ZonePageLazy = lazyWithPreload(() =>
  import('modules/zones2/pages').then((module) => ({
    default: module.ZonePage,
  })),
);
const ZonesListPageLazy = lazyWithPreload(() =>
  import('modules/zones2/pages').then((module) => ({
    default: module.ZonesListPage,
  })),
);

const CsmSecretPageLazy = lazyWithPreload(() =>
  import('modules/temp/CsmSecretPage').then((module) => ({
    default: module.CsmSecretPage,
  })),
);

const CarbonEmissionsFleetPageLazy = lazyWithPreload(() =>
  import('modules/reports/carbon-emissions').then((module) => ({
    default: module.CarbonEmissionsFleetPage,
  })),
);
const CarbonEmissionsPageLazy = lazyWithPreload(() =>
  import('modules/reports/carbon-emissions').then((module) => ({
    default: module.CarbonEmissionsPage,
  })),
);

const CarbonEmissionsPageNewLazy = lazyWithPreload(() =>
  import('modules/analytics/reports/carbonEmissions/CarbonEmissions').then(
    (module) => ({ default: module.CarbonEmissionsPage }),
  ),
);
const CarbonEmissionsPlaceholderNewLazy = lazyWithPreload(() =>
  import(
    'modules/analytics/reports/carbonEmissions/CarbonEmissionsPlaceholder'
  ).then((module) => ({ default: module.CarbonEmissionsPlaceholder })),
);
const HourlyConsumptionPageNewLazy = lazyWithPreload(() =>
  import('modules/analytics/reports/hourlyConsumption/HourlyConsumption').then(
    (module) => ({ default: module.HourlyConsumptionPage }),
  ),
);
const HourlyConsumptionPlaceHolderNewLazy = lazyWithPreload(() =>
  import(
    'modules/analytics/reports/hourlyConsumption/HourlyConsumptionPlaceholder'
  ).then((module) => ({ default: module.HourlyConsumptionPlaceholder })),
);
const IdleRatePageNewLazy = lazyWithPreload(() =>
  import('modules/analytics/reports/idleRate/IdleRate').then((module) => ({
    default: module.IdleRatePage,
  })),
);
const IdleRatePlaceholderNewLazy = lazyWithPreload(() =>
  import('modules/analytics/reports/idleRate/IdleRatePlaceholder').then(
    (module) => ({ default: module.IdleRatePlaceholder }),
  ),
);
const UsageHoursPageNewLazy = lazyWithPreload(() =>
  import('modules/analytics/reports/usageHours/UsageHours').then((module) => ({
    default: module.UsageHoursPage,
  })),
);
const UsageHoursPlaceholderNewLazy = lazyWithPreload(() =>
  import('modules/analytics/reports/usageHours/UsageHoursPlaceholder').then(
    (module) => ({ default: module.UsageHoursPlaceholder }),
  ),
);

const NewFleetDataPageLazy = lazyWithPreload(() =>
  import('modules/analytics/reports/fleetData/NewFleetData').then((module) => ({
    default: module.NewFleetDataPage,
  })),
);
const FleetUsagePageLazy = lazyWithPreload(() =>
  import('modules/reports/fleet-usage').then((module) => ({
    default: module.FleetUsagePage,
  })),
);

const AllConnectedTyresPageLazy = lazyWithPreload(() =>
  import('modules/reports/tyre-conditions').then((module) => ({
    default: module.AllConnectedTyresPage,
  })),
);
const RiskyTyresPageLazy = lazyWithPreload(() =>
  import('modules/reports/tyre-conditions').then((module) => ({
    default: module.RiskyTyresPage,
  })),
);
const TyreConditionsPlaceholderLazy = lazyWithPreload(() =>
  import('modules/reports/tyre-conditions').then((module) => ({
    default: module.TyreConditionsPlaceholder,
  })),
);

const HourlyConsumptionPageLazy = lazyWithPreload(() =>
  import('modules/reports/hourly-consumption').then((module) => ({
    default: module.HourlyConsumptionPage,
  })),
);
const IdleRatePageLazy = lazyWithPreload(() =>
  import('modules/reports/idle-rate').then((module) => ({
    default: module.IdleRatePage,
  })),
);

const InventoryPagesLazy = lazyWithPreload(
  () => import('modules/inventory_v2/pages'),
);
const MapPageLazy = lazyWithPreload(() =>
  import('modules/map').then((module) => ({ default: module.MapPage })),
);
const EquipmentFaultsLazy = lazyWithPreload(
  () => import('modules/reports/equipment-faults/EquipmentFaultsPage'),
);
const SharingPagesLazy = lazyWithPreload(() => import('modules/sharing/pages'));

export const primaryComponentsLazy = {
  InventoryPagesLazy,
  DashboardPageLazy,
  MapPageLazy,
  ZonePageLazy,
  AssetPagesLazy,
  ZonesListPageLazy,
  NewFleetDataPageLazy,
  FleetUsagePageLazy,
  IntegrationsPageLazy,
  CatalogPageLazy,
  CarbonEmissionsFleetPageLazy,
  CarbonEmissionsPageLazy,
  CarbonEmissionsPageNewLazy,
  CarbonEmissionsPlaceholderNewLazy,
  HourlyConsumptionPageNewLazy,
  HourlyConsumptionPlaceHolderNewLazy,
  IdleRatePageNewLazy,
  IdleRatePlaceholderNewLazy,
  UsageHoursPageNewLazy,
  UsageHoursPlaceholderNewLazy,
  AllConnectedTyresPageLazy,
  RiskyTyresPageLazy,
  TyreConditionsPlaceholderLazy,
  HourlyConsumptionPageLazy,
  IdleRatePageLazy,
  EquipmentFaultsLazy,
};

export const secondaryComponentsLazy = {
  CsmSecretPageLazy,
  UserJobPageLazy,
  ZoneCreatePageLazy,
  ZoneEditPageLazy,
  DestinationsPageLazy,
  SettingsPageLazy,
  AlertsConfigurationPagesLazy,
  ProfilePageLazy,
  JoinOrganizationPagesLazy,
  WorkspaceCreatePageLazy,
  AdministrationPagesLazy,
  SharingPagesLazy,
};
