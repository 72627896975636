import ContentLoader from 'react-content-loader';

import { Card } from '../Card';
import { InfoIcon } from '../Icons';
import { Tooltip } from '../Tooltip';
import { Caption, Metric, Title, TooltipWrapper } from './SimpleMetric.css';

type TooltipType = {
  text: string;
  link?: {
    text: string;
    url: string;
  };
};

type SimpleMetricPropsType = {
  caption?: JSX.Element | string;
  loading: boolean;
  noBorder?: boolean;
  noPadding?: boolean;
  noShadow?: boolean;
  statusTag?: JSX.Element;
  title: string;
  tooltip?: TooltipType;
  value?: string | number;
};

export const SimpleMetric = ({
  caption,
  loading,
  noBorder,
  noPadding,
  noShadow,
  statusTag,
  title,
  tooltip,
  value,
}: SimpleMetricPropsType) => {
  const renderData = () => {
    const finalValue = value ?? '-';
    return (
      <>
        <Metric>
          {statusTag}
          {finalValue}
        </Metric>
        {caption && <Caption>{caption}</Caption>}
      </>
    );
  };

  const renderSkeletonLoader = () => {
    return (
      <ContentLoader
        height={46}
        width={121}
        speed={2}
        backgroundColor="#F6F9FC"
        foregroundColor="#E5E8EB"
        style={{
          width: '100%',
          height: '46px',
        }}
      >
        <rect x="0" y="4" rx="4" ry="4" width="58" height="22" />
        <rect x="0" y="30" rx="4" ry="4" width="121" height="16" />
      </ContentLoader>
    );
  };

  return (
    <Card
      noBorder={noBorder}
      noShadow={noShadow}
      style={{
        padding: `${noPadding ? '11px 0' : '32px'}`,
        minHeight: '128px',
        justifyContent: 'center',
      }}
    >
      <Title>
        {title}
        {tooltip && (
          <TooltipWrapper>
            <span data-for={`simpleMetric-${title}`} data-tip>
              <InfoIcon size={16} />
              <Tooltip
                id={`simpleMetric-${title}`}
                place="bottom"
                multilineHtml
              >
                <p>{tooltip.text}</p>
                {tooltip.link && (
                  <a
                    href={tooltip.link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {tooltip.link.text}
                  </a>
                )}
              </Tooltip>
            </span>
          </TooltipWrapper>
        )}
      </Title>
      {loading ? renderSkeletonLoader() : renderData()}
    </Card>
  );
};
