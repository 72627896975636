import colors from 'constants/colors';

export type FatForbiddenIconProps = {
  color?: string;
};

export const FatForbiddenIcon = ({
  color = colors.red400,
}: FatForbiddenIconProps) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.42041 0C3.10672 0 0.42041 2.68628 0.42041 6C0.42041 9.31372 3.10672 12 6.42041 12C9.7341 12 12.4204 9.31369 12.4204 6C12.4204 2.68631 9.73413 0 6.42041 0ZM9.56818 2.85223C11.1516 4.43565 11.2617 6.8558 10.0684 8.55312L3.86726 2.352C5.56575 1.1579 7.98564 1.2697 9.56818 2.85223ZM3.27264 9.14777C1.68922 7.56435 1.57909 5.1442 2.77239 3.44688L8.97355 9.648C7.2751 10.8421 4.85518 10.7303 3.27264 9.14777Z"
      fill={color}
    />
  </svg>
);
