import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ONBOARDING_EXTERNAL_LINKS } from 'constants/externalLinks';
import i18n from 'i18n';
import { Button, Card, CardTitle } from 'modules/common-ui';

import { useSelector } from 'react-redux';
import { Store } from 'reducers';
import { Card as VideoCard } from '../card';
import { SharingSummary } from '../sharing/SharingSummary';
import {
  AddFirstSourceContainer,
  CardSubtitle,
  Container,
  SharingSummaryContainer,
  StyledLink,
} from './index.css';

export const MainContent: React.FC = () => {
  const { t } = useTranslation('onboarding');

  const { hasOwnedAsset, assetCount } = useSelector<
    Store,
    {
      hasOwnedAsset: boolean | null;
      assetCount: number | null;
    }
  >((state) => {
    return {
      hasOwnedAsset: state.user.hasOwnedAsset,
      assetCount: state.user.assetCount,
    };
  });

  const displaySharingSummary =
    !!assetCount && assetCount > 0 && !hasOwnedAsset;

  const userLanguage = i18n.resolvedLanguage;
  const exploreVideo =
    userLanguage === 'fr'
      ? ONBOARDING_EXTERNAL_LINKS.EXPLORE_VIDEO_FR
      : ONBOARDING_EXTERNAL_LINKS.EXPLORE_VIDEO_EN;

  const videos = [
    {
      id: 0,
      caption: t('onboarding.videos.getStarted'),
      src: ONBOARDING_EXTERNAL_LINKS.GETTING_STARTED_VIDEO,
      thumbnail: '/images/video-1.jpg',
    },
    {
      id: 1,
      caption: t('onboarding.videos.explore'),
      src: exploreVideo,
      thumbnail: '/images/video-2.png',
    },
  ];

  return (
    <Container>
      <div className="videos-list">
        {videos.map((video) => (
          <VideoCard key={video.id} video={video} />
        ))}
      </div>
      {displaySharingSummary && (
        <SharingSummaryContainer>
          <SharingSummary />
        </SharingSummaryContainer>
      )}
      <AddFirstSourceContainer>
        <NavLink to="/catalog" style={{ textDecoration: 'none' }}>
          <Button variant="primary">
            {t('onboarding.addYourFirstDataSource')}
          </Button>
        </NavLink>
      </AddFirstSourceContainer>
      <Card style={{ alignItems: 'flex-start', padding: '16px' }}>
        <CardTitle>{t('onboarding.footerCard.title')}</CardTitle>
        <CardSubtitle>{t('onboarding.footerCard.description')}</CardSubtitle>
        <StyledLink
          href={ONBOARDING_EXTERNAL_LINKS.BOOK_A_DEMO}
          target="_blank"
        >
          <Button variant="outline-primary">
            {t('onboarding.footerCard.bookDemo')}
          </Button>
        </StyledLink>
      </Card>
    </Container>
  );
};
