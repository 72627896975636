export function round(value: number, precision = 0) {
  const multiplier = 10 ** precision;
  return Math.round(value * multiplier) / multiplier;
}

export function format(
  value?: number | string,
  thousandSeparator = ' ',
  decimalSeparator = '.',
): string {
  if (value === undefined) return '';
  const [whole, decimal] = value.toString().split('.');

  if (!whole) {
    return `${value}`;
  }

  const formattedValue = whole.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    `$1${thousandSeparator}`,
  );

  return `${formattedValue}${decimal ? `${decimalSeparator}${decimal}` : ''}`;
}
