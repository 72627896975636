import { capitalize } from 'utils/string';
import i18n from '../i18n';

export function formatSource(source: string): string {
  if (!source) {
    return '';
  }

  return i18n.exists(`translation:deviceSources.${source}`)
    ? i18n.t(`translation:deviceSources.${source}`)
    : source
        .split('_')
        .map((s) => capitalize(s))
        .join(' ');
}
