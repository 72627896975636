export const PositionMarkerIcon = ({
  size,
  color,
}: {
  size: number;
  color: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4039 6.34207C11.4039 7.74531 10.8332 9.49535 9.86136 11.2169C8.96247 12.8093 7.74423 14.3346 6.39383 15.4889C5.04343 14.3346 3.82519 12.8093 2.9263 11.2169C1.95448 9.49535 1.38379 7.74531 1.38379 6.34207C1.38379 3.5751 3.62686 1.33203 6.39383 1.33203C9.1608 1.33203 11.4039 3.5751 11.4039 6.34207Z"
        fill={color}
        stroke="white"
      />
      <circle
        cx="6.39381"
        cy="6.34254"
        r="1.83668"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};
