import { DEFAULT_PADDING, DEFAULT_SPACING } from 'modules/layout';
import styled from 'styled-components';

export const CARD_SMALL_SPACING = '16px';

export type CardProps = {
  horizontal?: boolean;
  noBorder?: boolean;
  noPadding?: boolean;
  noShadow?: boolean;
  position?: string;
};

export const Card = styled.div.attrs({
  className: 'layout-card',
})<CardProps>`
  flex: 1;
  display: flex;
  ${(props) => (props.position ? `position: ${props.position}` : '')};
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
  background: #ffffff;
  ${(props) =>
    props.noShadow ? '' : 'box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1)'};
  border-radius: 5px;
  ${(props) => (props.noBorder ? '' : 'border: 0.5px solid #e5e8eb')};
  box-sizing: border-box;
  ${(props) => (props.noPadding ? '' : `padding: ${DEFAULT_PADDING}`)};

  .layout-section-right & + & {
    margin-top: ${DEFAULT_SPACING};
  }

  .layout-section-right &:last-child {
    margin-bottom: ${DEFAULT_SPACING};
  }

  .layout-section-left & + & {
    margin-left: ${CARD_SMALL_SPACING};
  }
`;
