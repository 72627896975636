export type TimesIconProps = { size?: number; color?: string };

export const TimesIcon = ({ size, color }: TimesIconProps) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill={color || 'currentColor'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="17.9976"
      height="1.4998"
      rx="0.749901"
      transform="matrix(0.7072 0.707013 -0.7072 0.707013 6.30005 5.10004)"
      fill="#2D2F30"
    />
    <rect
      width="17.9976"
      height="1.4998"
      rx="0.749901"
      transform="matrix(-0.7072 0.707013 -0.7072 -0.707013 18.5886 6.16052)"
      fill="#2D2F30"
    />
  </svg>
);
