export type ShareIconProps = {
  color?: string;
  size?: number;
};

export const ShareIcon = ({ size = 16, color }: ShareIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.10412 2.40984C5.65057 1.86339 6.53655 1.86339 7.08301 2.40984L7.43985 2.76668L6.78022 3.42631L6.42338 3.06947C6.24123 2.88732 5.9459 2.88732 5.76375 3.06947L1.56947 7.26375C1.38732 7.4459 1.38732 7.74123 1.56947 7.92338L5.76375 12.1177C5.9459 12.2998 6.24123 12.2998 6.42338 12.1177L6.78022 11.7608L7.43985 12.4204L7.08301 12.7773C6.53655 13.3237 5.65057 13.3237 5.10412 12.7773L0.909842 8.58301C0.363386 8.03655 0.363386 7.15057 0.909842 6.60412L5.10412 2.40984Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9817 2.40984C10.4352 1.86339 9.54923 1.86339 9.00278 2.40984L4.8085 6.60412C4.26204 7.15057 4.26204 8.03655 4.8085 8.58301L9.00278 12.7773C9.54923 13.3237 10.4352 13.3237 10.9817 12.7773L15.1759 8.58301C15.7224 8.03655 15.7224 7.15057 15.1759 6.60412L10.9817 2.40984ZM9.66241 3.06947C9.84456 2.88732 10.1399 2.88732 10.322 3.06947L14.5163 7.26375C14.6985 7.4459 14.6985 7.74123 14.5163 7.92338L10.322 12.1177C10.1399 12.2998 9.84456 12.2998 9.66241 12.1177L5.46813 7.92338C5.28598 7.74123 5.28598 7.4459 5.46813 7.26375L9.66241 3.06947Z"
        fill={color}
      />
    </svg>
  );
};
