import colors from 'constants/colors';
import { New } from 'modules/common-ui';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import { useLocation } from 'react-router-dom';
import { NavItem } from '../NavItem';
import {
  Children,
  GroupIcon,
  GroupLabel,
  NavGroupContainer,
  NavItemWrapper,
  TitleContainer,
  ToggleIcon,
} from './index.css';

type NavItemType = {
  id: string;
  link: string;
  label: string;
  display: boolean;
  icon?: ({ color, size }: { color: string; size: number }) => JSX.Element;
  testId?: string;
  /**
   * Wether we should display a "NEW" icon next to the item.
   */
  isNew?: boolean;
  isBeta?: boolean;
};

type NavGroupProps = {
  display?: boolean;
  label: string;
  open?: boolean;
  onClick: () => void;
  testId?: string;
  id?: string;
  items: NavItemType[];
  icon: ({ color, size }: { color: string; size: number }) => JSX.Element;
  /**
   * Wether we should display a "NEW" icon next to the item.
   */
  isNew?: boolean;
};

export const NavGroup = ({
  label,
  items,
  display = true,
  open = false,
  icon,
  testId,
  id,
  onClick,
  isNew,
}: NavGroupProps) => {
  const location = useLocation();

  const hasItemSelected =
    items.findIndex((item) => location.pathname.includes(item.link)) !== -1;

  const renderItem = (item: NavItemType) => {
    return (
      <NavItemWrapper key={`nav-link-items-${item.id}`}>
        <NavItem
          icon={item.icon}
          label={item.label}
          link={item.link}
          testId={item.testId}
          onClick={() => (hasItemSelected ? () => {} : onClick())}
          isBeta={item.isBeta}
          isNew={item.isNew}
        />
      </NavItemWrapper>
    );
  };

  const renderChildren = () => {
    const filteredItems = items.filter((i) => i.display === true);

    return <Children>{filteredItems.map((i) => renderItem(i))}</Children>;
  };

  if (!display) {
    return null;
  }

  return (
    <NavGroupContainer id={id}>
      <GroupLabel
        data-testid={testId}
        selected={open}
        onClick={() => (hasItemSelected ? () => {} : onClick())}
        inactive={hasItemSelected}
      >
        <TitleContainer>
          {icon && (
            <GroupIcon>{icon({ color: colors.gray850, size: 18 })}</GroupIcon>
          )}
          {label}
          {isNew && <New />}
        </TitleContainer>
        <ToggleIcon isOpen={hasItemSelected || open}>
          <FaAngleDown size="16px" />
        </ToggleIcon>
      </GroupLabel>
      {(hasItemSelected || open) && renderChildren()}
    </NavGroupContainer>
  );
};
