import { Fragment, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import OutsideClickHandler from 'react-outside-click-handler';

import { EllipsisToolTip } from 'modules/common-ui';

import { usePositionedElement } from 'modules/common-ui/hooks/usePositionedElement';
import {
  FilterMask,
  Label,
  Left,
  List,
  ListContainer,
  ListHeader,
  ListItem,
  Right,
  Select,
} from './WorkspaceList.css';

export type WorkspaceAccessListPropType = {
  alignMenu?: 'left' | 'right';
  light?: boolean;
  listHeader?: string;
  workspaces: Array<{ id: number; name: string }>;
};

export const WorkspaceList = ({
  alignMenu = 'right',
  light = false,
  listHeader,
  workspaces,
}: WorkspaceAccessListPropType) => {
  const { t } = useTranslation('translation');

  const [display, setDisplay] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const selectRef = useRef<HTMLDivElement | null>(null);

  usePositionedElement(display, selectRef, dropdownRef, alignMenu);

  const getWorkspaceValue = useCallback(() => {
    const workspacesCount = workspaces.length;
    if (workspacesCount > 2) {
      const wsName1 = workspaces[0] ? workspaces[0].name : '';
      const wsName2 = workspaces[1] ? workspaces[1].name : '';

      return `${wsName1}, ${wsName2}, ${
        workspacesCount === 3
          ? t('common.andMore', {
              count: 1,
            })
          : t('common.andMore', {
              count: workspacesCount - 2,
            })
      }`;
    } else {
      return workspaces.map((w) => w.name).join(', ');
    }
  }, [t, workspaces]);

  const selectMode = workspaces && workspaces.length > 2;

  const onOutsideClick = (_e: MouseEvent) => {
    setDisplay(false);
  };

  const renderDropdown = () => {
    return (
      <Fragment>
        <FilterMask />
        <OutsideClickHandler onOutsideClick={onOutsideClick} useCapture={false}>
          <ListContainer ref={dropdownRef}>
            <List>
              {listHeader && <ListHeader>{listHeader}</ListHeader>}
              {workspaces.map((w) => (
                <ListItem key={`w-list-${w.id}`}>
                  <Label>{w.name}</Label>
                </ListItem>
              ))}
            </List>
          </ListContainer>
        </OutsideClickHandler>
      </Fragment>
    );
  };

  const onClick = () => {
    if (selectMode) {
      setDisplay(!display);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div>
        <div>
          <Select
            ref={selectRef}
            light={light}
            hoverEffect={selectMode}
            onClick={onClick}
          >
            <EllipsisToolTip>
              <Left
                data-tip={workspaces.length ? getWorkspaceValue() : ''}
                isPlaceholder={!workspaces.length}
              >
                {workspaces.length ? getWorkspaceValue() : ''}
              </Left>
            </EllipsisToolTip>
            {selectMode && (
              <Right>
                <FaAngleDown />
              </Right>
            )}
          </Select>
        </div>
        {display && renderDropdown()}
      </div>
    </div>
  );
};
