import { TextInput } from 'modules/common-ui';
import styled from 'styled-components';

export const SearchInputContainer = styled.div`
  min-height: 48px;
  display: flex;
`;

export const IconContainer = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
`;

export const StyledTextInput = styled(TextInput)`
  border: none;
  display: flex;
  align-items: center;
  min-height: 48px;
  input {
    font-size: 14px;
    color: #2d2f30;
    border: none;
    min-height: 48px;
    &:focus {
      box-shadow: none;
      outline: none !important;
    }
  }
  div {
    display: flex;
    align-items: center;
    padding-right: 4px;
  }
`;
