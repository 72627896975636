import { Button } from 'modules/common-ui';

import { useTranslation } from 'react-i18next';
import {
  DataFetchTimeoutContainer,
  ImgContainer,
} from './DataFetchTimeout.css';

export const DataFetchTimeout = () => {
  const [t] = useTranslation('translation');
  const placeholderImage = '/images/placeholder-error.png';

  return (
    <DataFetchTimeoutContainer>
      <ImgContainer>
        <img src={placeholderImage} alt="hiboo error" key={placeholderImage} />
      </ImgContainer>
      <p>{t('common.dataFetchError')}</p>
      <p>{t('common.tryAgain')}</p>
      <Button
        onClick={() => {
          window.location.reload();
        }}
        variant="primary"
      >
        {t('common.refreshPage')}
      </Button>
    </DataFetchTimeoutContainer>
  );
};
