import colors from 'constants/colors';
import styled from 'styled-components';

export const SearchContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;

  border-bottom: 1px solid ${colors.gray300};
`;

export const SearchResultRow = styled.div<{
  highlighted?: boolean;
}>`
  cursor: pointer;
  padding-top: 4px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  letter-spacing: 0.00938em;

  :hover {
    background: ${colors.gray50};
  }

  ${(props) => (props.highlighted ? `background: ${colors.lightGrey};` : '')}
`;

export const SearchResultItem = styled.div<{
  highlighted?: boolean;
  hasBreadcrumb?: boolean;
}>`
  padding-left: 14px;
  border-bottom: 1px solid ${colors.gray300};
  padding-top: ${(props) => (props.hasBreadcrumb ? '0' : '4px')};

  :hover {
    background: ${colors.gray50};
  }

  ${(props) => (props.highlighted ? `background: ${colors.lightGrey};` : '')}
`;

export const SearchBreadCrumbContainer = styled.div`
  padding-top: 8px;
  font-size: 11px;
  color: ${colors.gray500};
  padding-left: 14px;
`;
