import addmemberEn from './addmember/addmember.en.json';
import addmemberFr from './addmember/addmember.fr.json';

import administrationEn from './administration/administration.en.json';
import administrationFr from './administration/administration.fr.json';

import alertsEn from './alerts/alerts.en.json';
import alertsFr from './alerts/alerts.fr.json';

import assetDispatcherEn from './assetDispatcher/assetDispatcher.en.json';
import assetDispatcherFr from './assetDispatcher/assetDispatcher.fr.json';

import assetsEn from './assets/assets.en.json';
import assetsFr from './assets/assets.fr.json';

import carbonEmissionsEn from './carbonEmissions/carbonEmissions.en.json';
import carbonEmissionsFr from './carbonEmissions/carbonEmissions.fr.json';

import catalogEn from './catalog/catalog.en.json';
import catalogFr from './catalog/catalog.fr.json';

import commonUiEn from './commonUi/commonUi.en.json';
import commonUiFr from './commonUi/commonUi.fr.json';

import destinationsEn from './destinations/destinations.en.json';
import destinationsFr from './destinations/destinations.fr.json';

import filterEn from './filter/filter.en.json';
import filterFr from './filter/filter.fr.json';

import fleetUsageEn from './fleetUsage/fleetUsage.en.json';
import fleetUsageFr from './fleetUsage/fleetUsage.fr.json';

import globalSearchEn from './globalSearch/globalSearch.en.json';
import globalSearchFr from './globalSearch/globalSearch.fr.json';

import headerEn from './header/header.en.json';
import headerFr from './header/header.fr.json';

import homeEn from './home/home.en.json';
import homeFr from './home/home.fr.json';

import hourlyConsumptionEn from './hourlyConsumption/hourlyConsumption.en.json';
import hourlyConsumptionFr from './hourlyConsumption/hourlyConsumption.fr.json';

import idleRateEn from './idleRate/idleRate.en.json';
import idleRateFr from './idleRate/idleRate.fr.json';

import integrationsEn from './integrations/integrations.en.json';
import integrationsFr from './integrations/integrations.fr.json';

import inventoryEn from './inventory/inventory.en.json';
import inventoryFr from './inventory/inventory.fr.json';

import inventoryCareEn from './inventoryCare/inventoryCare.en.json';
import inventoryCareFr from './inventoryCare/inventoryCare.fr.json';

import joinOrganizationEn from './joinOrganization/joinOrganization.en.json';
import joinOrganizationFr from './joinOrganization/joinOrganization.fr.json';

import languageEn from './language/language.en.json';
import languageFr from './language/language.fr.json';

import mapEn from './map/map.en.json';
import mapFr from './map/map.fr.json';

import onboardingEn from './onboarding/onboarding.en.json';
import onboardingFr from './onboarding/onboarding.fr.json';

import pricingPlansEn from './pricingPlans/pricingPlans.en.json';
import pricingPlansFr from './pricingPlans/pricingPlans.fr.json';

import procoreEn from './procore/procore.en.json';
import procoreFr from './procore/procore.fr.json';

import profileEn from './profile/profile.en.json';
import profileFr from './profile/profile.fr.json';

import reportsEn from './reports/reports.en.json';
import reportsFr from './reports/reports.fr.json';

import settingsEn from './settings/settings.en.json';
import settingsFr from './settings/settings.fr.json';

import sharingEn from './sharing/sharing.en.json';
import sharingFR from './sharing/sharing.fr.json';

import sidenavEn from './sidenav/sidenav.en.json';
import sidenavFr from './sidenav/sidenav.fr.json';

import signalHealthEn from './signalHealth/signalHealth.en.json';
import signalHealthFr from './signalHealth/signalHealth.fr.json';

import signupEn from './signup/signup.en.json';
import signupFr from './signup/signup.fr.json';

import tableV7En from './tableV7/tableV7.en.json';
import tableV7Fr from './tableV7/tableV7.fr.json';

import translationEn from './translation/translation.en.json';
import translationFr from './translation/translation.fr.json';

import tyreConditionsEn from './tyreConditions/tyreConditions.en.json';
import tyreConditionsFr from './tyreConditions/tyreConditions.fr.json';

import userEn from './user/user.en.json';
import userFr from './user/user.fr.json';

import userWorkspaceSelectEn from './userWorkspaceSelect/userWorkspaceSelect.en.json';
import userWorkspaceSelectFr from './userWorkspaceSelect/userWorkspaceSelect.fr.json';

import vehicleTyreCardEn from './vehicleTyreCard/vehicleTyreCard.en.json';
import vehicleTyreCardFr from './vehicleTyreCard/vehicleTyreCard.fr.json';

import workspaceCreateEn from './workspaceCreate/workspaceCreate.en.json';
import workspaceCreateFr from './workspaceCreate/workspaceCreate.fr.json';

import zoneDispatcherEn from './zoneDispatcher/zoneDispatcher.en.json';
import zoneDispatcherFr from './zoneDispatcher/zoneDispatcher.fr.json';

import zonesEn from './zones/zones.en.json';
import zonesFr from './zones/zones.fr.json';

import applicationErrorEn from './applicationError/applicationError.en.json';
import applicationErrorFr from './applicationError/applicationError.fr.json';

import analyticsEn from './analytics/analytics.en.json';
import analyticsFr from './analytics/analytics.fr.json';

import fleetSegmentEn from './fleetSegment/fleetSegment.en.json';
import fleetSegmentFr from './fleetSegment/fleetSegment.fr.json';

const resources = {
  en: {
    addmember: addmemberEn,
    administration: administrationEn,
    alerts: alertsEn,
    assetDispatcher: assetDispatcherEn,
    assets: assetsEn,
    carbonEmissions: carbonEmissionsEn,
    catalog: catalogEn,
    commonUi: commonUiEn,
    destinations: destinationsEn,
    filter: filterEn,
    fleetUsage: fleetUsageEn,
    globalSearch: globalSearchEn,
    header: headerEn,
    home: homeEn,
    hourlyConsumption: hourlyConsumptionEn,
    idleRate: idleRateEn,
    integrations: integrationsEn,
    inventory: inventoryEn,
    inventoryCare: inventoryCareEn,
    joinOrganization: joinOrganizationEn,
    language: languageEn,
    map: mapEn,
    onboarding: onboardingEn,
    pricingPlans: pricingPlansEn,
    procore: procoreEn,
    profile: profileEn,
    reports: reportsEn,
    settings: settingsEn,
    sidenav: sidenavEn,
    signalHealth: signalHealthEn,
    signup: signupEn,
    tableV7: tableV7En,
    translation: translationEn,
    tyreConditions: tyreConditionsEn,
    user: userEn,
    userWorkspaceSelect: userWorkspaceSelectEn,
    vehicleTyreCard: vehicleTyreCardEn,
    workspaceCreate: workspaceCreateEn,
    zoneDispatcher: zoneDispatcherEn,
    zones: zonesEn,
    applicationError: applicationErrorEn,
    analytics: analyticsEn,
    fleetSegment: fleetSegmentEn,
    sharing: sharingEn,
  },
  fr: {
    addmember: addmemberFr,
    administration: administrationFr,
    alerts: alertsFr,
    assets: assetsFr,
    carbonEmissions: carbonEmissionsFr,
    catalog: catalogFr,
    commonUi: commonUiFr,
    destinations: destinationsFr,
    filter: filterFr,
    fleetUsage: fleetUsageFr,
    assetDispatcher: assetDispatcherFr,
    globalSearch: globalSearchFr,
    header: headerFr,
    home: homeFr,
    hourlyConsumption: hourlyConsumptionFr,
    idleRate: idleRateFr,
    integrations: integrationsFr,
    inventory: inventoryFr,
    inventoryCare: inventoryCareFr,
    joinOrganization: joinOrganizationFr,
    language: languageFr,
    map: mapFr,
    onboarding: onboardingFr,
    pricingPlans: pricingPlansFr,
    procore: procoreFr,
    profile: profileFr,
    reports: reportsFr,
    settings: settingsFr,
    sidenav: sidenavFr,
    signalHealth: signalHealthFr,
    signup: signupFr,
    tableV7: tableV7Fr,
    translation: translationFr,
    tyreConditions: tyreConditionsFr,
    user: userFr,
    userWorkspaceSelect: userWorkspaceSelectFr,
    vehicleTyreCard: vehicleTyreCardFr,
    workspaceCreate: workspaceCreateFr,
    zoneDispatcher: zoneDispatcherFr,
    zones: zonesFr,
    applicationError: applicationErrorFr,
    analytics: analyticsFr,
    fleetSegment: fleetSegmentFr,
    sharing: sharingFR,
  },
};

export default resources;
