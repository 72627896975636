export type VideoIconProps = {
  size?: number;
};

export const VideoIcon = ({ size = 16 }: VideoIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 4.91463C1.5 3.60903 2.50151 2.52634 3.79082 2.44789C6.80898 2.26424 9.20661 2.25481 12.2233 2.44084C13.5056 2.51991 14.5 3.59933 14.5 4.90058V9.93964C14.5 11.2241 13.5308 12.2892 12.2651 12.385C10.9009 12.4882 9.27614 12.5852 8 12.5852C6.72387 12.5852 5.09915 12.4882 3.73495 12.385C2.46921 12.2892 1.5 11.2241 1.5 9.93964V4.91463Z"
      stroke="#929497"
    />
    <path
      d="M10.0987 7.32336C10.4178 7.51832 10.4178 7.98168 10.0987 8.17664L7.0576 10.0351C6.72442 10.2387 6.29688 9.99894 6.29688 9.60847L6.29688 5.89153C6.29688 5.50106 6.72442 5.26128 7.0576 5.46489L10.0987 7.32336Z"
      fill="#929497"
    />
  </svg>
);
