import colors from 'constants/colors';

export type FormatURLIconProps = {
  color?: string;
};

export const FormatURLIcon = ({
  color = colors.gray850,
}: FormatURLIconProps) => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.2 2.31436H6.4C7.2824 2.31436 8 3.03196 8 3.91435C8 4.79676 7.2824 5.51436 6.4 5.51436H5.6C5.1584 5.51436 4.8 5.87316 4.8 6.31435C4.8 6.75556 5.1584 7.11436 5.6 7.11436H6.4C8.1672 7.11436 9.6 5.68156 9.6 3.91435C9.6 2.14716 8.1672 0.714355 6.4 0.714355H3.2C1.4328 0.714355 0 2.14716 0 3.91435C0 5.10636 0.6532 6.14395 1.6196 6.69475C1.6076 6.56956 1.6 6.44316 1.6 6.31435C1.6 5.78875 1.7036 5.28676 1.8888 4.82676C1.708 4.56756 1.6 4.25395 1.6 3.91435C1.6 3.03196 2.3176 2.31436 3.2 2.31436ZM10.3796 3.53356C10.3912 3.65916 10.4 3.78556 10.4 3.91435C10.4 4.43956 10.2944 4.93956 10.1084 5.39875C10.2908 5.65836 10.4 5.97355 10.4 6.31435C10.4 7.19676 9.68237 7.91436 8.80003 7.91436H5.6C4.7176 7.91436 4 7.19676 4 6.31435C4 5.43196 4.7176 4.71436 5.6 4.71436H6.4C6.8416 4.71436 7.2 4.35556 7.2 3.91435C7.2 3.47316 6.8416 3.11436 6.4 3.11436H5.6C3.8328 3.11436 2.4 4.54716 2.4 6.31435C2.4 8.08156 3.8328 9.51438 5.6 9.51438H8.80003C10.5672 9.51438 12 8.08156 12 6.31435C12 5.12236 11.3464 4.08436 10.3796 3.53356Z"
      fill={color}
    />
  </svg>
);
