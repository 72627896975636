import React from 'react';
import { InfoIcon } from '../Icons';
import { Tooltip } from '../Tooltip/Tooltip';
import { ClickWrapper } from './index.css';

type TooltipInfoProps = {
  htmlText: string;
};

export const TooltipInfo = ({ htmlText }: TooltipInfoProps) => {
  return (
    <>
      <InfoIcon dataFor="tooltip-info" marginLeft={12} />
      <ClickWrapper
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          event.stopPropagation();
        }}
      >
        <Tooltip
          place={'top'}
          delayHide={500}
          clickable={true}
          eventOff={'click'}
          globalEventOff={'click'}
          html={true}
          multilineHtml={true}
          id="tooltip-info"
          getContent={function () {
            return htmlText;
          }}
        />
      </ClickWrapper>
    </>
  );
};
