import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors, { primaryColors } from 'constants/colors';

export const LogoWrapper = styled.div`
  left: 0;
  margin: 16px;
  position: absolute;
  top: 0;
  width: 86px;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${colors.gray200};
  color: ${colors.gray850};
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  padding: 16px 0;
  text-align: center;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
`;

export const StyledLink = styled(Link)`
  color: ${colors.gray500};
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border-radius: 5px;
  margin-top: 24px;
  text-decoration: underline;
  transition: color 120ms ease-in 0s;

  :hover,
  :focus {
    color: ${primaryColors.main};
  }
`;

export const Footer = styled.div`
  align-items: center;
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const WhatsHibooLink = styled.a`
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  padding: 2px 4px;
  transition: background 120ms ease-in 0s;

  :hover,
  :focus {
    background: rgba(45, 47, 48, 0.08);
    text-decoration: none;
  }

  :active {
    background: rgba(45, 47, 48, 0.16);
  }

  svg {
    margin-right: 4px;
  }

  span {
    color: ${colors.gray500};
    font-size: 12px;
    line-height: 16px;
  }
`;

export const Message = styled.div`
  color: ${colors.gray850};
  font-size: 14px;
  line-height: 21px;
  padding-top: 16px;
  text-align: center;
`;
