import { useTranslation } from 'react-i18next';

import { NoDataButtonType } from 'modules/tableV7/types';

import { Button } from 'modules/common-ui';
import { useNavigate } from 'react-router-dom';
import { NoData, NoDataContainer } from './index.css';

type EmptyStateProps = {
  text?: string;
  button?: NoDataButtonType;
};

export const EmptyState = ({ text, button }: EmptyStateProps) => {
  const [t] = useTranslation('tableV7');
  const navigate = useNavigate();

  return (
    <NoDataContainer noButton={!button}>
      <NoData>{text || t('table.status.noData')}</NoData>
      {button && (
        <Button
          style={{ padding: '7px 8px' }}
          variant="primary"
          onClick={() => navigate(button.link)}
          role="link"
        >
          {button.label}
        </Button>
      )}
    </NoDataContainer>
  );
};
