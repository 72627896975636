import { useQuery } from '@apollo/client';
import colors from 'constants/colors';
import { Button } from 'modules/common-ui';
import { Table } from 'modules/tableV7';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  GetReceivedSharingSummaryQuery,
  getReceivedSharingSummaryQuery,
  ReceivedSharingSummaryByOrganization,
} from './gql';
import { getColumns } from './SharingSummaryTableColumns';

const DISPLAYED_SUMMARY_COUNT = 3;
const tableId = 'received-sharing-summary';

const ActionsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: -10px;
`;

const SharedAssetsCounter = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

const TableContainer = styled.div<{ fadeBottom: boolean }>`
  ${(props) =>
    props.fadeBottom &&
    `::after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
      width: 100%;
      height: 3em;
    }`}
`;

const TableNoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  color: ${colors.gray500};
`;

const TableNoDataText = styled.span``;

export const SharingSummary = () => {
  const { t } = useTranslation('onboarding');
  const navigate = useNavigate();
  const [receivedSharingSummary, setReceivedSharingSummary] = useState<
    ReceivedSharingSummaryByOrganization[]
  >([]);
  const { data, loading } = useQuery<GetReceivedSharingSummaryQuery>(
    getReceivedSharingSummaryQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        retainedOrganizationsCount: DISPLAYED_SUMMARY_COUNT + 1,
      },
    },
  );

  useEffect(() => {
    if (!data || loading) return;
    const receivedSharingSummaryRows =
      data.viewer.sharing.receivedSharingSummary;
    setReceivedSharingSummary(receivedSharingSummaryRows);
  }, [data, loading]);

  const onClickShowSharings = () => {
    navigate('/sharing/list-received-sharings');
  };

  const renderActions = () => {
    const nbAssets = receivedSharingSummary.reduce(
      (totalAssets, { countSharedAssets }) => totalAssets + countSharedAssets,
      0,
    );

    if (nbAssets === 0) return null;

    return (
      <ActionsContainer>
        <SharedAssetsCounter>
          {t('sharing.table.actions.title', { count: nbAssets })}
        </SharedAssetsCounter>
        <Button variant="neutral" onClick={onClickShowSharings}>
          {t('sharing.table.actions.showSharings')}
        </Button>
      </ActionsContainer>
    );
  };

  const hasMoreReceivedSharings =
    receivedSharingSummary.length > DISPLAYED_SUMMARY_COUNT;

  const NoDataComponent = (
    <TableNoDataContainer>
      <TableNoDataText>{t('tableV7:table.status.noData')}</TableNoDataText>
    </TableNoDataContainer>
  );

  return (
    <TableContainer fadeBottom={hasMoreReceivedSharings}>
      <Table
        columns={getColumns(t)}
        light={true}
        disablePagination={true}
        displayColumnToggler={false}
        actions={renderActions()}
        data={receivedSharingSummary}
        displaySearch={false}
        id={tableId}
        noDataComponent={NoDataComponent}
        loading={loading}
        disableResizing={true}
        style={{
          overflowX: 'auto',
        }}
      />
    </TableContainer>
  );
};
