import {
  generateMapURLFromAddress,
  generateMapURLFromCoordinates,
} from 'utils/map';
import { StyledLink } from './index.css';

type DirectionLinkProps = {
  lat?: number;
  long?: number;
  address?: string;
  className?: string;
  children: React.ReactNode;
};

export const DirectionLink = ({
  className,
  address,
  lat,
  long,
  children,
}: DirectionLinkProps) => {
  if (!address && !lat && !long) return null;

  let locationMapURL = null;
  if (lat && long) {
    locationMapURL = generateMapURLFromCoordinates({
      latitude: lat,
      longitude: long,
    });
  } else if (address) {
    locationMapURL = generateMapURLFromAddress({ address });
  }

  if (!locationMapURL) return null;

  return (
    <StyledLink
      href={locationMapURL}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </StyledLink>
  );
};
