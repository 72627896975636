import { DeviceBatteryLevel } from 'modules/common-ui';

import { CellSubtitle, CellTitle, Container } from './index.css';

export type BatteryLevelCellProps = {
  id: number | string;
  batteryIndicator: 'ORANGE' | 'GREEN' | 'RED';
  lastUpdatedAt?: string;
  dataFor?: any;
  dataTip?: any;
};

export const BatteryLevelCell = ({
  id,
  batteryIndicator,
  lastUpdatedAt,
  dataFor = '',
  dataTip = '',
}: BatteryLevelCellProps) => (
  <Container
    key={`IoT-battery-level-cell-${id}`}
    data-for={dataFor}
    data-tip={dataTip}
  >
    <CellTitle style={{ display: 'flex' }}>
      <DeviceBatteryLevel batteryIndicator={batteryIndicator} />
    </CellTitle>
    {lastUpdatedAt && (
      <CellSubtitle style={{ marginLeft: '40px' }}>
        {lastUpdatedAt}
      </CellSubtitle>
    )}
  </Container>
);
