import styled from 'styled-components';

export const Container = styled.div`
  grid-column: 1/2;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 6px;
  margin-right: 35px;
  padding: 20px 0;
`;
