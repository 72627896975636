import { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import {
  AlertIcon,
  AnalyticsIcon,
  DashboardIcon,
  InventoryIcon,
  MapIcon,
  ZonesIcon,
} from 'modules/common-ui';

import { NavGroup } from '../NavGroup';
import { NavItem } from '../NavItem';
import { NavigationContainer, NavigationGroup } from './index.css';

type NavigationProps = {
  featureManagement: any;
  loading: boolean;
  noReport?: boolean;
  fleetUsageFlag?: boolean;
  tyreReportFlag?: boolean;
};

export const Navigation = ({
  featureManagement,
  loading,
  noReport,
  tyreReportFlag,
}: NavigationProps) => {
  const [t] = useTranslation('sidenav');

  const [groupsState, setGroupsState] = useState({
    alerts: false,
    reports: false,
  } as { [key: string]: boolean });

  const closeGroups = () => {
    const nGroups = {} as { [key: string]: boolean };

    Object.keys(groupsState).forEach((k) => {
      nGroups[k] = false;
    });

    setGroupsState(nGroups);
  };

  const toggleGroup = (key: string) => {
    const nGroups = {} as { [key: string]: boolean };

    if (groupsState[key] === true) {
      // if already open, close it
      nGroups[key] = false;
    } else {
      // if not open, close everything and open it
      Object.keys(groupsState).forEach((k) => {
        nGroups[k] = false;
      });
      nGroups[key] = true;
    }

    setGroupsState(nGroups);
  };

  const renderGroupReports = () => {
    if (noReport) return null;

    const S2S = featureManagement.S2S;

    const items = [
      {
        display: S2S,
        id: 'nav-reports-fleet-data',
        label: t('sidenav.navigation.reports.fleetData'),
        testId: 'navigation-reports-fleet-data',
        link: '/reports/fleet-data',
      },
      {
        display: S2S,
        id: 'nav-reports-equipment-faults',
        label: t('sidenav.navigation.reports.equipmentFaults'),
        testId: 'navigation-reports-equipment-faults',
        link: '/reports/equipment-faults',
        isNew: true,
      },
      {
        id: 'nav-fleet-usage',
        label: t('sidenav.navigation.reports.fleetUsage'),
        testId: 'nav-fleet-usage',
        link: '/reports/fleet-usage',
        display: false,
      },
      {
        display: true,
        id: 'nav-reports-usage-hours',
        label: t('sidenav.navigation.reports.usageHours'),
        testId: 'nav-reports-usage-hours',
        link: '/analytics/usage-hours',
      },
      {
        display: true,
        id: 'nav-reports-tyre-conditions',
        label: t('sidenav.navigation.reports.tyreConditions'),
        testId: 'navigation-reports-tyre-conditions',
        link: tyreReportFlag
          ? '/reports/tyre-conditions/risky-tyres'
          : '/reports/tyre-conditions/risky-tyres/request-beta',
      },
      {
        display: false,
        id: 'nav-reports-tyre-conditions',
        label: t('sidenav.navigation.reports.tyreConditions'),
        testId: 'navigation-reports-tyre-conditions',
        link: tyreReportFlag
          ? '/reports/tyre-conditions/all-connected'
          : '/reports/tyre-conditions/all-connected/request-beta',
      },
      {
        display: true,
        id: 'nav-reports-idle-rate-v2',
        label: t('sidenav.navigation.reports.idleRateV2'),
        testId: 'navigation-reports-idle-rate-v2',
        link: '/analytics/idle-rate',
      },
      {
        display: true,
        id: 'nav-reports-hourly-consumption-v2',
        label: t('sidenav.navigation.reports.hourlyConsumptionV2'),
        testId: 'navigation-reports-hourly-consumption-v2',
        link: '/analytics/hourly-consumption',
      },
      {
        display: true,
        id: 'nav-reports-carbon-emissions-v2',
        label: t('sidenav.navigation.reports.carbonEmissions'),
        testId: 'navigation-reports-carbon-emissions-v2',
        link: '/analytics/carbon-emissions',
      },
    ];

    const open = groupsState.reports;

    return (
      <NavGroup
        display
        icon={AnalyticsIcon}
        items={items}
        testId="navigation-reports"
        id="navigation-reports"
        label={t('sidenav.navigation.reports.title')}
        onClick={() => {
          toggleGroup('reports');
        }}
        open={open}
      />
    );
  };

  const renderSkeleton = () => {
    return (
      <NavigationContainer>
        <ContentLoader
          speed={2}
          style={{
            paddingLeft: '16',
            paddingRight: '16',
            width: '200',
            height: '240',
          }}
          width={240}
          height={200}
          viewBox={`0 0 200 240`}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          title={t('translation:common.loading')}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="24" />
          <rect x="0" y="36" rx="0" ry="0" width="100%" height="24" />
          <rect x="0" y="72" rx="0" ry="0" width="100%" height="24" />
          <rect x="0" y="108" rx="0" ry="0" width="100%" height="24" />
        </ContentLoader>
      </NavigationContainer>
    );
  };

  if (loading) {
    return renderSkeleton();
  }

  return (
    <NavigationContainer>
      <NavigationGroup>
        <NavItem
          display={!!featureManagement?.dashboards?.length}
          icon={DashboardIcon}
          label={t('sidenav.navigation.dashboard')}
          link="/dashboard"
          id="navigation-dashboard"
          testId="navigation-dashboard"
          onClick={closeGroups}
        />
      </NavigationGroup>
      <NavItem
        display
        icon={MapIcon}
        label={t('sidenav.navigation.map')}
        link="/map"
        id="navigation-home"
        testId="navigation-home"
        onClick={closeGroups}
      />
      <NavItem
        display
        icon={InventoryIcon}
        label={t('sidenav.navigation.inventory')}
        link="/inventory"
        id="navigation-inventory"
        onClick={closeGroups}
      />
      <NavItem
        display={featureManagement && featureManagement.displayZones}
        icon={ZonesIcon}
        label={t('sidenav.navigation.zones')}
        link="/zones"
        id="navigation-zones"
        onClick={closeGroups}
      />
      <NavItem
        display={featureManagement && featureManagement.displayAlerts}
        icon={AlertIcon}
        label={t('sidenav.navigation.alerts.title')}
        link="/alerts/configuration"
        id="nav-alert-configuration"
        testId="navigation-alerts-configuration"
        onClick={closeGroups}
      />
      {renderGroupReports()}
    </NavigationContainer>
  );
};
