import { createContext, useContext, useReducer } from 'react';

type InitialStateType = {
  isNavigationMenuOpen: boolean;
};

const initialState: InitialStateType = {
  isNavigationMenuOpen: false,
};

export enum NavigationMenuActionTypes {
  TOGGLE_NAVIGATION_MENU = 'HEADER_TOGGLE_NAVIGATION_MENU',
}

const NavigationMenuContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const useNavigationMenu = () => useContext(NavigationMenuContext);

const navigationMenuReducer = (
  state: InitialStateType,
  action: { type: NavigationMenuActionTypes },
) => {
  switch (action.type) {
    case NavigationMenuActionTypes.TOGGLE_NAVIGATION_MENU: {
      return {
        ...state,
        isNavigationMenuOpen: !state.isNavigationMenuOpen,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const NavigationMenuProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, dispatch] = useReducer(navigationMenuReducer, initialState);

  return (
    <NavigationMenuContext.Provider value={{ state, dispatch }}>
      {children}
    </NavigationMenuContext.Provider>
  );
};
