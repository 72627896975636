import { ThemeProvider, createTheme } from '@mui/material';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import colors from 'constants/colors';
import { useState } from 'react';

type TabContent = { label: React.ReactNode; content: React.ReactNode };

const theme = createTheme({
  typography: {
    fontFamily: 'var(--font-family)',
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.gray200}`,
          width: '100%',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontWeight: 400,
          color: colors.gray850,
          textTransform: 'none',
          ...(ownerState.selected === true && {
            color: `${colors.gray850} !important`,
            outline: '0 !important',
          }),
        }),
      },
    },
  },
});

type TabListProps = {
  visibleTabIndex: number | false;
  onTabChange: (newVisibleTabIndex: number) => void;
  tabLabels: React.ReactNode[];
};

export const TabList = ({
  visibleTabIndex,
  onTabChange,
  tabLabels,
}: TabListProps) => {
  const handleTabChange = (
    _event: React.ChangeEvent<{}>,
    newActiveTabIndex: number,
  ) => {
    onTabChange(newActiveTabIndex);
  };
  return (
    <ThemeProvider theme={theme}>
      <MuiTabs
        variant="fullWidth"
        centered
        value={visibleTabIndex}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            background: colors.gray850,
            height: '3px',
          },
        }}
      >
        {tabLabels.map((label, i) => {
          return (
            <MuiTab label={label} key={`tab-${i}`} sx={{ padding: '8px' }} />
          );
        })}
      </MuiTabs>
    </ThemeProvider>
  );
};

type TabsProps = {
  tabList: TabContent[];
  initialTabIndex?: number;
};

export const Tabs = ({ tabList, initialTabIndex = 0 }: TabsProps) => {
  const [visibleTabIndex, setVisibleTabIndex] = useState(initialTabIndex);

  return (
    <>
      <TabList
        visibleTabIndex={visibleTabIndex}
        onTabChange={setVisibleTabIndex}
        tabLabels={tabList.map((tab) => tab.label)}
      />
      {tabList.map((tab, i) => {
        return (
          <div
            role="tabpanel"
            hidden={visibleTabIndex !== i}
            key={`tab-content-${i}`}
          >
            {tab.content}
          </div>
        );
      })}
    </>
  );
};
