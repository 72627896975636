import colors from 'constants/colors';
import MdSearch from 'react-icons/lib/md/search';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 5px;
  left: 8px;
  bottom: 5px;
  color: ${colors.gray500};
`;

export const SearchIcon = () => (
  <Wrapper>
    <MdSearch />
  </Wrapper>
);
