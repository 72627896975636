import colors from 'constants/colors';

type SignalIconProps = {
  color?: string;
  size?: number;
};

export const SignalIcon = ({
  size,
  color = colors.gray850,
}: SignalIconProps) => (
  <svg
    width={size || 16}
    height={size || 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2.5" y="2.5" width="11" height="11" rx="5.5" stroke={color} />
    <rect x="4.5" y="4.5" width="7" height="7" rx="3.5" stroke={color} />
    <rect x="6.5" y="6.5" width="3" height="3" rx="1.5" stroke={color} />
  </svg>
);
