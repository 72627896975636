import colors from 'constants/colors';
import styled from 'styled-components';

export const SidePanelHideContainer = styled.div`
  position: absolute;
  right: 11px;

  svg {
    cursor: pointer;
  }

  :hover {
    svg > path {
      fill: ${colors.gray700};
    }
  }

  :active {
    svg > path {
      fill: ${colors.gray800};
    }
  }
`;
