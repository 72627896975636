import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .videos-list {
    display: flex;
    justify-content: center;
    gap: 62px;
    padding: 18px;
    padding-bottom: 8px;
  }
`;

export const AddFirstSourceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 45px 0;
`;

export const StyledLink = styled.a`
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
`;

export const CardSubtitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
`;

export const SharingSummaryContainer = styled.div`
  margin-top: 12px;
  position: relative;
`;
