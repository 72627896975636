import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { BREAKPOINT_TABLET } from 'constants/breakpoints';
import { DateFilter2, DateRangePeriodType, Filter } from 'modules/common-ui';

import { FLAGS } from 'constants/flags';
import { useFeature } from 'modules/utils';
import moment from 'moment';
import { ColumnToggler } from './ColumnToggler';
import {
  Container,
  Filters,
  FilterWrapper,
  Left,
  Right,
  Subtitle,
  TableGlobalSearchInputWrapper,
  Title,
  TitleContainer,
} from './index.css';
import { Search } from './Search';
import { ActionBarPropTypes } from './types';

export const ActionBar = <D extends object>({
  tableId,

  displaySearch = true,
  onSearch,
  searchPlaceholder,
  searchTestId,
  searchValue,
  totalRows,
  tracking,
  title,
  subtitle,
  actions,
  actionsAlignLeft,
  selectedPeriodComponent,

  filterOptions,

  allColumns,
  displayColumnToggler = true,
  setColumnOrder,
  searchFullWidth = false,
  searchWidth,
  // Export
  renderExport,
}: ActionBarPropTypes<D>) => {
  const isSmallerThanTab = useMediaQuery({ maxWidth: BREAKPOINT_TABLET - 1 });
  const {
    filters,
    onFilter,
    appliedFilters: initialAppliedFilters,
    showDateFilter = false,
    persistence = { save: () => {}, get: () => {} },
    dateRangePickerActive: initialDateRangePickerActive,
  } = filterOptions || {};

  const [appliedFilters, setAppliedFilters] = useState(initialAppliedFilters);
  const [dateRangePickerActive, setDateRangePickerActive] = useState(
    initialDateRangePickerActive === true,
  );

  // load saved filters
  useEffect(() => {
    const savedFilter = persistence.get();
    if (savedFilter) {
      setAppliedFilters(savedFilter);
      onFilter && onFilter(savedFilter);
    }
  }, [onFilter, persistence, setAppliedFilters]);

  const [dataHistorySpanFlag] = useFeature(FLAGS.DATA_HISTORY_SPAN);

  useEffect(() => {
    // force history to be only for the last week when customer is in freemium
    if (dataHistorySpanFlag === '1week') {
      const newFilter = {
        ...appliedFilters,
        period: {
          since: moment().subtract(7, 'day').format('YYYY-MM-DD'),
          until: moment().format('YYYY-MM-DD'),
        },
      };
      setAppliedFilters(newFilter);
      onFilter && onFilter(newFilter);
    }
    // we don't add appliedFilters in the effect dependency array to
    // prevent infinite update loop (calling onFilter will update props)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHistorySpanFlag, onFilter]);

  const onFilterApply = useCallback(
    (filters: { [key: string]: any }) => {
      const { period } = appliedFilters;
      const newFilters: any = {};
      Object.keys(filters).forEach(function (k) {
        newFilters[k] = filters[k];
      });
      const filter = { period, ...newFilters };
      setAppliedFilters(filter);
      onFilter && onFilter(filter);
      persistence.save(filter);
    },
    [appliedFilters, onFilter, persistence],
  );

  const onDateFilterApply = useCallback(
    (period: DateRangePeriodType) => {
      const { label, value, since, until } = period;

      if (since && until) {
        const filter = {
          ...appliedFilters,
          period: {
            label,
            value,
            since: since.format('YYYY-MM-DD'),
            until: until.format('YYYY-MM-DD'),
          },
        };
        setAppliedFilters(filter);
        setDateRangePickerActive(true);
        onFilter && onFilter(filter);
        persistence.save(filter);

        setDateRangePickerActive(true);
      }
    },
    [appliedFilters, onFilter, persistence],
  );

  const renderFilters = () => {
    if (!appliedFilters) {
      return null;
    }

    const { period, ...otherFilters } = appliedFilters;
    const displayFilters = !!filters && !!filters.length;

    return (
      <Filters>
        {showDateFilter && dataHistorySpanFlag && (
          <FilterWrapper>
            <DateFilter2
              period={period}
              onDateFilter={onDateFilterApply}
              freemium={dataHistorySpanFlag === '1week'}
              isActive={dateRangePickerActive}
            />
          </FilterWrapper>
        )}
        {displayFilters && (
          <FilterWrapper id="table-global-filters">
            <Filter
              alignRight={true}
              onFilter={onFilterApply}
              filters={filters}
              appliedFilters={otherFilters}
            />
          </FilterWrapper>
        )}
      </Filters>
    );
  };

  const hasSubtitle = subtitle && subtitle.length > 0;

  return (
    <Container>
      <Left fullWidth={searchFullWidth || isSmallerThanTab}>
        {displaySearch && (
          <TableGlobalSearchInputWrapper
            id="table-global-search-input"
            fullWidth={searchFullWidth || isSmallerThanTab}
          >
            <Search
              onSearch={onSearch}
              placeholder={searchPlaceholder}
              searchValue={searchValue}
              testId={searchTestId}
              totalRows={totalRows}
              tracking={tracking}
              fullWidth={searchFullWidth || isSmallerThanTab}
              searchWidth={searchWidth}
            />
          </TableGlobalSearchInputWrapper>
        )}
        <TitleContainer>
          {!displaySearch && title && <Title>{title}</Title>}
          {hasSubtitle && <Subtitle>{subtitle}</Subtitle>}
        </TitleContainer>
      </Left>
      <Right alignStart={isSmallerThanTab}>
        {actionsAlignLeft && actions}
        {selectedPeriodComponent}
        {renderFilters()}
        {displayColumnToggler && (
          <div id="table-global-column-toggler">
            <ColumnToggler
              allColumns={allColumns}
              mobileView={isSmallerThanTab}
              setColumnOrder={setColumnOrder}
              tableId={tableId}
            />
          </div>
        )}
        {renderExport && renderExport()}
        {!actionsAlignLeft && actions}
      </Right>
    </Container>
  );
};
