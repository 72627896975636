import styled from 'styled-components';

import {
  BREAKPOINT_DESKTOP_START,
  BREAKPOINT_MOBILE_END,
  BREAKPOINT_TABLET,
} from 'constants/breakpoints';
import colors from 'constants/colors';

export const Container = styled.div<{
  columnView?: boolean;
  alignStart?: boolean;
}>`
  display: flex;
  justify-content: space-around;
  gap: 8px;

  @media screen and (min-width: ${BREAKPOINT_DESKTOP_START}px) {
    padding: 20px;
  }

  @media screen and (max-width: ${BREAKPOINT_MOBILE_END}px) {
    padding-top: 8px;
    padding-bottom: 16px;
  }

  @media screen and (max-width: ${BREAKPOINT_TABLET - 1}px) {
    flex-direction: column;
  }  
`;

export const Left = styled.div<{ fullWidth?: boolean }>`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  ${(props) => props.fullWidth && 'width: 100%;'}

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    width: 100%;
    align-items: center;
    margin-bottom: 16px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.gray600};
`;

export const Right = styled.div<{ alignStart?: boolean }>`
  align-items: ${(props) => (props.alignStart ? 'flex-start' : 'center')};
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 8px;
  height: 32px;
  justify-content: flex-end;
  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    width: 100%;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
`;

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;

  ${FilterWrapper} + ${FilterWrapper} {
    margin-left: 8px;
  }
`;

export const TableGlobalSearchInputWrapper = styled.div<{
  fullWidth?: boolean;
}>`
  ${(props) => props.fullWidth && 'width: 100%;'}
  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    width: 100%;
  }
`;
