import colors from 'constants/colors';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  padding: 8px 12px;
`;

export const EnergyTypePickerLabel = styled.div<{ isDanger?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  ${(props) => props.isDanger && `color: ${colors.red500}`};
`;

export const HelperText = styled.div`
  color: ${colors.gray500};
  font-size: 12px;
  line-height: 16px;
`;
