import colors from 'constants/colors';
import { CrossIconWrapper } from './index.css';

export type CrossIconProps = {
  size?: number;
  color?: string;
  onClick: (event: any) => void;
};

export const CrossIcon = ({
  size = 16,
  color = colors.gray500,
  onClick,
}: CrossIconProps) => (
  <CrossIconWrapper color={color}>
    <svg
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="15.6542"
        height="1"
        rx="0.5"
        transform="matrix(0.7072 0.707013 -0.7072 0.707013 3.05972 2)"
        fill="#2D2F30"
      />
      <rect
        width="15.6542"
        height="1"
        rx="0.5"
        transform="matrix(-0.7072 0.707013 -0.7072 -0.707013 13.7481 2.92236)"
        fill="#2D2F30"
      />
    </svg>
  </CrossIconWrapper>
);
