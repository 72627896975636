type ArrowTopLeftToBottomRightProps = {
  width?: number;
  height?: number;
};
export const ArrowTopLeftToBottomRight = (
  props: ArrowTopLeftToBottomRightProps,
) => {
  const { width = 70, height = 78 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M63.5035 72.8438C59.3039 72.209 55.2767 71.5832 51.25 70.9729C50.4477 70.8476 49.8814 70.7601 48.8497 70.7688C48.0834 70.7672 46.4585 70.7988 46.631 71.6209C46.8444 72.285 47.0805 72.7293 48.4546 73.0827C52.0075 73.9771 55.6647 74.3358 59.2914 74.7114C62.494 75.0408 64.9412 75.2431 67.7919 75.3673C69.1232 75.4252 69.6287 74.7488 69.4254 73.5371C69.2425 72.4496 69.0741 71.3302 68.6901 70.2975C67.3259 66.5662 65.8699 62.8854 64.4439 59.1722C64.2696 58.7099 64.1614 58.3388 63.8754 57.8182C63.5227 57.1907 63.301 56.7146 62.6369 56.928C62.0034 57.1245 62.0709 58.0601 62.1983 58.5242C63.1782 62.4268 65.3338 70.4431 65.3514 70.8959C63.2552 69.6636 61.2894 68.5669 59.3819 67.3586C46.1376 58.8805 33.6101 49.5301 23.4581 37.3201C15.4218 27.6552 8.66854 17.2056 3.53284 5.72383C3.2252 5.04773 2.8557 4.38967 2.48742 3.76282C2.00077 2.90602 1.59592 2.54641 1.13539 2.76752C0.327233 3.2992 0.509368 3.96453 0.608598 4.50797C0.791447 5.59546 1.20789 6.65826 1.60751 7.69044C7.09692 21.4414 15.4389 33.3463 25.3822 44.204C35.5868 55.3486 47.8588 63.755 60.889 71.1469C61.5911 71.5106 63.2428 72.5725 63.5035 72.8438Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
