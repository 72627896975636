import React from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  ContentLeft,
  ContentRight,
  IconLeft,
  SidebarMobileLinkWrapper,
} from './index.css';

type SidebarMobileLinkPropTypes = {
  children: React.ReactNode;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  link?: string;
  loading?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
};

export const SidebarMobileLink = ({
  children,
  iconLeft,
  iconRight,
  link,
  loading = false,
  onClick,
  style,
}: SidebarMobileLinkPropTypes) => {
  const { t } = useTranslation('commonUi');
  const wrapperAttributes: any = {};

  if (link) {
    wrapperAttributes.to = link;
  }

  if (loading) {
    return (
      <SidebarMobileLinkWrapper>
        <ContentLoader
          title={t('loading')}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          speed={2}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
      </SidebarMobileLinkWrapper>
    );
  }

  return (
    <SidebarMobileLinkWrapper
      as={link ? Link : 'div'}
      onClick={onClick}
      {...wrapperAttributes}
      style={style}
    >
      <ContentLeft>
        {iconLeft && <IconLeft>{iconLeft}</IconLeft>}
        {children}
      </ContentLeft>
      {iconRight && <ContentRight>{iconRight}</ContentRight>}
    </SidebarMobileLinkWrapper>
  );
};
