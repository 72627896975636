import colors from 'constants/colors';
import { FLAGS } from 'constants/flags';
import { ArrowUpIconFreemium, Button, PlusIcon } from 'modules/common-ui';
import { UserStore } from 'modules/user';
import { useFeature } from 'modules/utils';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Store } from 'reducers';
import { AddWorkspaceRowContainer } from './AddWorkspaceRow.css';

type AddWorkspaceRowProps = {
  onClick: () => void;
};

export const AddWorkspaceRow = ({ onClick }: AddWorkspaceRowProps) => {
  const [t] = useTranslation('sidenav');
  const location = useLocation();

  const navigate = useNavigate();
  const [flag, loading] = useFeature(FLAGS.WORKSPACE_CREATE);
  const { assetCount } = useSelector<Store, { assetCount: number | null }>(
    (state) => {
      return {
        assetCount: state.user.assetCount,
      };
    },
  );
  const user = useSelector<Store>((state) => state.user) as UserStore;

  if (!user.adminOfOrganization || !assetCount) {
    return null;
  }

  return (
    <AddWorkspaceRowContainer>
      {loading ? (
        <ContentLoader
          height={32}
          width={326}
          speed={2}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="4" ry="4" width="326" height="32" />
        </ContentLoader>
      ) : (
        <Button
          onClick={() => {
            if (location.pathname !== '/workspaces/new') {
              navigate('/workspaces/new');
            }
            onClick();
          }}
          variant={flag ? 'outline-primary' : 'outline-freemium'}
          iconLeft={
            flag ? (
              <PlusIcon />
            ) : (
              <ArrowUpIconFreemium color={colors.purplePlainLight} />
            )
          }
        >
          {t('sidenav.picker.createWorkspace')}
        </Button>
      )}
    </AddWorkspaceRowContainer>
  );
};
