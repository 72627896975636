import moment from 'moment';

// override moment default locale for french
// so we don't have dots at the end of the day/month names
moment.updateLocale('fr', {
  weekdaysShort: 'dim_lun_mar_mer_jeu_ven_sam'.split('_'),
  monthsShort: 'janv_févr_mars_avr_mai_juin_juil_août_sept_oct_nov_déc'.split(
    '_',
  ),
});

moment.updateLocale('fr', {
  relativeTime: {
    future: 'dans %s',
    past: 'il y a %s',
    s: 'quelques secondes',
    ss: '%d secondes',
    m: 'une minute',
    mm: '%d minutes',
    h: 'une heure',
    hh: '%d heures',
    d: 'un jour',
    dd: '%d jours',
    M: 'un mois',
    MM: '%d mois',
    y: 'un an',
    yy: '%d ans',
  },
});
