import colors from 'constants/colors';
import styled from 'styled-components';

const levelsColor: { [key: string]: string } = {
  [-1]: '#E5E8EB',
  0: '#D7C2FD',
  1: '#FEE1AC',
  2: '#FFB1BA',
  3: '#F77F8E',
};

export const Badge = styled.div<{ level: string }>`
  align-items: center;
  background: ${(props) => levelsColor[props.level]};
  border-radius: 3px;
  display: flex;
  max-width: 80px;
  overflow: hidden;
  padding: 2px 6px;
  position: relative;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;

  color: ${colors.gray850};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;
