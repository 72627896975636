import colors from 'constants/colors';

export type ClockHour3Props = { color?: string; size?: number };

export const ClockHour3 = ({
  color = colors.gray500,
  size,
}: ClockHour3Props) => (
  <svg
    height={size || 16}
    width={size || 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="6.5" stroke={color} />
    <path d="M7.5 4V9H11" stroke={color} />
  </svg>
);
