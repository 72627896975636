import { useEffect, useState } from 'react';

export const useSize = (divRef: React.RefObject<HTMLElement | null>) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      // Only watch the first element
      const { height, width } = entries[0].contentRect;

      setHeight(height);
      setWidth(width);
    });

    let divRefValue: typeof divRef.current | null = null;

    if (divRef.current) {
      observer.observe(divRef.current);
      divRefValue = divRef.current;
    }

    return () => {
      if (divRefValue) {
        observer.unobserve(divRefValue);
      }
    };
  }, [divRef]);

  return { height, width };
};
