import { gql } from '@apollo/client';

type Organization = {
  id: number;
  name: string;
};

export type GraphQLGroupFeaturesWorkspace = {
  id: string;
  name: string;
  sharedWith: Organization[];
  sharedBy: Organization;
  totalAssets: number;
  parentWorkspaceId: number | null;
};

export type GraphQLGroupFeatures = {
  viewer: {
    id: string;
    organization: { id: string; name: string };
    workspaces: GraphQLGroupFeaturesWorkspace[];
    group: {
      id: string;
      name: string;
      sharedWith: Organization[];
      sharedBy: Organization;
      featureManagement: {
        S2S: boolean;
        customLogoUrl: string;
        dashboards: { labelKey: string }[];
      };
    };
  };
};

export type GraphQLUserLanguage = {
  viewer: {
    language: 'string';
  };
};

export const query = gql`
  query GroupFeatures {
    viewer {
      id
      organization {
        id
        name
      }
      workspaces {
        id
        name
        sharedWith {
          id
          name
        }
        sharedBy {
          id
          name
        }
        parentWorkspaceId
        totalAssets
      }
      group {
        id
        name
        sharedWith {
          id
          name
        }
        sharedBy {
          id
          name
        }
        featureManagement {
          S2S
          displayAlerts
          displayZones
          customLogoUrl
          dashboards {
            labelKey
          }
        }
      }
    }
  }
`;

export const queryUserLanguage = gql`
  query queryUserLanguage {
    viewer {
      id
      language
    }
  }
`;
