import React from 'react';

export const DataSourceLogo = ({
  dataSourceCode,
  style = {},
}: {
  dataSourceCode: string;
  style?: React.CSSProperties;
}) => {
  return (
    <img
      alt={`${dataSourceCode} Logo`}
      style={{
        background: 'white',
        maxWidth: '100%',
        objectFit: 'contain',
        maxHeight: '100%',
        borderRadius: '5px',
        verticalAlign: 'middle',
        textAlign: 'center',
        height: '100%',
        ...style,
      }}
      src={`https://hiboo-resources.s3-eu-west-1.amazonaws.com/data-sources/logos/${dataSourceCode}.png`}
    />
  );
};
