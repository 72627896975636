import { Link } from '@mui/material';
import colors from 'constants/colors';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  &:hover {
    color: ${colors.gray850};
  }

  &:active {
    color: ${colors.gray700};
  }
`;

export type TextLinkProps = {
  href: string;
  children: ReactNode;
};

export const TextLink = ({ href, children, ...rest }: TextLinkProps) => {
  return (
    <StyledLink href={href} target="_blank" {...rest} color="inherit">
      {children}
    </StyledLink>
  );
};
