import { Button } from 'modules/common-ui';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'utils/debounce';
import {
  DotFlashing,
  DotFlashingContainer,
  ShowMoreContainer,
} from './SearchAndLoadOptionsShowMore.css';
import { SearchAndLoadMultiselectOption } from './types';

export const SearchAndLoadOptionsShowMore = ({
  listOptions,
  listDefaultSize,
  listSize,
  setListSize,
}: {
  listOptions: SearchAndLoadMultiselectOption[];
  listDefaultSize: number;
  listSize: number;
  setListSize: (value: number) => void;
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [loading, setLoading] = useState(false); // fake loading
  const { t } = useTranslation('commonUi');

  if (listOptions.length === 0) return null;

  const onClick = debounce(() => {
    if (ref.current) ref.current.blur();
    setLoading(false);
    setListSize(listSize + listDefaultSize);
  }, 700);

  const allResultsAreDisplayed = listSize >= listOptions.length;
  return (
    <ShowMoreContainer>
      <Button
        style={{ width: '100%' }}
        ref={ref}
        onClick={() => {
          setLoading(true);
          onClick();
        }}
        disabled={allResultsAreDisplayed}
      >
        {loading ? (
          <DotFlashingContainer>
            <DotFlashing />
          </DotFlashingContainer>
        ) : (
          t('searchAndLoadMultiselect.options.showMore.more')
        )}
      </Button>
    </ShowMoreContainer>
  );
};
