export type AddressPinProps = {
  size?: number;
};

export const AddressPin = ({ size = 16 }: AddressPinProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12.7222C9.94444 11.1667 11.5 8.433 11.5 6.5C11.5 4.567 9.933 3 8 3C6.067 3 4.5 4.567 4.5 6.5C4.5 8.433 6.05556 11.1667 8 12.7222ZM8.00004 7.66671C8.64438 7.66671 9.16671 7.14438 9.16671 6.50004C9.16671 5.85571 8.64438 5.33338 8.00004 5.33338C7.35571 5.33338 6.83338 5.85571 6.83338 6.50004C6.83338 7.14438 7.35571 7.66671 8.00004 7.66671Z"
      fill="#D5D8DB"
    />
  </svg>
);
