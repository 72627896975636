import React from 'react';

export type EyeIconProps = {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
};

export const EyeIcon = ({ size, color, style }: EyeIconProps) => (
  <svg
    fill={color || 'currentColor'}
    height={size || 16}
    style={style}
    viewBox="0 0 16 16"
    width={size || 16}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.35957 7.66914C2.18151 7.96436 2.18151 8.33356 2.35957 8.62878C3.57451 10.6432 5.6291 11.922 7.9094 11.922C10.1897 11.922 12.2443 10.6432 13.4592 8.62878C13.6373 8.33355 13.6373 7.96436 13.4592 7.66914C12.2443 5.65476 10.1897 4.37592 7.90939 4.37592C5.62909 4.37592 3.57451 5.65476 2.35957 7.66914ZM1.09986 7.72488C0.966714 7.99189 0.966714 8.30603 1.09986 8.57304C2.41512 11.2107 4.97142 13 7.9094 13C10.8474 13 13.4037 11.2107 14.7189 8.57304C14.8521 8.30603 14.8521 7.99189 14.7189 7.72488C13.4037 5.08722 10.8474 3.29791 7.90939 3.29791C4.97142 3.29791 2.41512 5.08722 1.09986 7.72488Z"
    />
    <circle
      cx="7.9094"
      cy="8.14897"
      r="1.61702"
      fill="white"
      stroke="currentColor"
      strokeWidth="1.07801"
    />
  </svg>
);
