import colors from 'constants/colors';
import { CheckIcon, ShareIcon } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import {
  SelectedIconContainer,
  SharingTag,
  WorkspaceRowContainer,
  WorkspaceRowName,
  WorkspaceRowNbAssets,
  WorkspaceSharedContainer,
} from './WorkspaceRow.css';

type WorkspaceRowProps = {
  id: number;
  name: string;
  nbAssets: number;
  selected?: boolean;
  sharedBy?: { id: number; name: string };
  sharedWith?: { id: number; name: string }[];
};
export const WorkspaceRow = ({
  id,
  name,
  nbAssets,
  sharedBy,
  sharedWith,
  selected,
}: WorkspaceRowProps) => {
  const [t] = useTranslation('sidenav');

  const renderSharedBy = () => {
    if (sharedBy) {
      return (
        <WorkspaceSharedContainer>
          <SharingTag>
            <ShareIcon color="black" />
            {t('sidenav.picker.sharedBy', { org: sharedBy.name })}
          </SharingTag>
        </WorkspaceSharedContainer>
      );
    }
  };

  const renderSharedWith = () => {
    if (sharedWith && sharedWith.length) {
      return (
        <WorkspaceSharedContainer>
          <SharingTag>
            <ShareIcon color="black" />
            {t('sidenav.picker.sharedWith', {
              org: sharedWith.map((o) => o.name).join(', '),
            })}
          </SharingTag>
        </WorkspaceSharedContainer>
      );
    }
  };

  return (
    <WorkspaceRowContainer selected={selected}>
      <div id={`workspace-row-${id}`}>
        <WorkspaceRowName>{name}</WorkspaceRowName>
      </div>
      {renderSharedBy()}
      {renderSharedWith()}
      <WorkspaceRowNbAssets>
        {t('sidenav.picker.nbAssets', { count: nbAssets })}
      </WorkspaceRowNbAssets>
      {selected && (
        <SelectedIconContainer>
          <CheckIcon color={colors.gray850} />
        </SelectedIconContainer>
      )}
    </WorkspaceRowContainer>
  );
};
