import styled from 'styled-components';

import colors from 'constants/colors';

export const SidebarMobileLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 48px;
  font-size: 16px;
  line-height: 22px;
  padding: 13px 16px;
  text-decoration: none;
  color: ${colors.gray850};
  font-weight: 400;
  width: 100%;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.gray850};
    text-decoration: none;
  }
`;

export const ContentLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ContentRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 16px;
`;

export const IconLeft = styled.div`
  margin-right: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
