import { ShareIcon } from 'modules/common-ui';
import { useHasAccessToSharingV2 } from 'modules/sharing/hooks/hasAccessToSharingV2/hasAccessToSharingV2.hook';
import { useTranslation } from 'react-i18next';
import { NavItem } from '../NavItem';

export const NavigationToSharingCenter = () => {
  const [t] = useTranslation('sidenav');

  const hasAccessToSharingV2 = useHasAccessToSharingV2();

  if (!hasAccessToSharingV2) return null;

  return (
    <NavItem
      label={t('sidenav.footer.sharing')}
      link="/sharing"
      testId="navigation-sharing"
      id="navigation-sharing"
      icon={ShareIcon}
    />
  );
};
