export type PlusIconV2Props = { size?: number; color?: string };

export const PlusIconV2 = ({
  size = 16,
  color = '#929497',
}: PlusIconV2Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66667 2.49987C8.66667 2.22372 8.44281 1.99993 8.16667 2C7.89052 2.00007 7.66667 2.22399 7.66667 2.50013V7.33304H2.50013C2.22399 7.33304 2.00007 7.5569 2 7.83304C1.99993 8.10918 2.22373 8.33304 2.49987 8.33304H7.66667V13.4999C7.66667 13.776 7.89052 13.9998 8.16667 13.9998C8.44281 13.9997 8.66667 13.7758 8.66667 13.4996V8.33304H13.4996C13.7758 8.33304 13.9997 8.10918 13.9998 7.83304C13.9998 7.5569 13.776 7.33304 13.4999 7.33304H8.66667V2.49987Z"
      fill={color}
    />
  </svg>
);
