import React, { useCallback } from 'react';
import { ColumnInstance, Row } from 'react-table';

import { ExportButton } from 'modules/common-ui';
import { useExporter } from 'modules/utils/exporter';

import { ButtonWrapper } from './index.css';

type ExporterPropTypes<D extends object = {}> = {
  id?: string;
  onExport?: (rows?: Row<D>[], columns?: ColumnInstance<D>[]) => void;
  filename: string;
  getData: () => void;
  useWrapper?: boolean;
  exporting?: boolean;
};

export const Exporter: React.FC<ExporterPropTypes> = ({
  id,
  filename,
  getData,
  onExport,
  exporting = false,
  useWrapper = true,
}) => {
  const [loading, exportAsync] = useExporter({ filename });

  const onExportButtonClick = useCallback(async () => {
    // use this prop to make server side exports
    if (onExport) {
      return onExport();
    }

    const data = getData();
    await exportAsync(data);
  }, [exportAsync, getData, onExport]);

  if (useWrapper) {
    return (
      <ButtonWrapper id={id}>
        <ExportButton
          loading={loading || exporting}
          onClick={onExportButtonClick}
        />
      </ButtonWrapper>
    );
  }

  return (
    <ExportButton
      id={id}
      displayAsButton
      loading={loading || exporting}
      onClick={onExportButtonClick}
    />
  );
};
