import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import FaAngleDown from 'react-icons/lib/fa/angle-down';

import { RichMultiselectOption } from './RichMultiselect';

import { Button, ButtonLeft, ButtonRight, Tag, TagList } from './index.css';

type RichButtonProps = {
  values: RichMultiselectOption[];
  onClick: () => void;
  max?: number;
  placeholder?: string;
};

export const RichButton = ({
  values,
  onClick,
  max = -1,
  placeholder = '',
}: RichButtonProps) => {
  const { t } = useTranslation('commonUi');
  const noneSelected = !values || !values.length;

  return (
    <Button onClick={onClick}>
      <ButtonLeft>
        {noneSelected ? (
          placeholder || t('richMultiselect.richButton.placeholder')
        ) : (
          <TagList>
            {max > -1 && values.length === max ? (
              <Tag key="all">
                {t('richMultiselect.options.count', { count: max })}
              </Tag>
            ) : (
              <Fragment>
                {values.length === 1 ? (
                  <Tag key={values[0].key || values[0].value}>
                    {values[0].label}
                  </Tag>
                ) : (
                  <Tag
                    key={values.length}
                  >{`${values.length} ${t('richMultiselect.richButton.selected')}`}</Tag>
                )}
              </Fragment>
            )}
          </TagList>
        )}
      </ButtonLeft>
      <ButtonRight>
        <FaAngleDown />
      </ButtonRight>
    </Button>
  );
};
