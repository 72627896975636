import colors from 'constants/colors';

export type ChevronDownProps = { color?: string; size?: number };

export const ChevronDown = ({ color, size }: ChevronDownProps) => (
  <svg
    width={size || '16'}
    height={size || '16'}
    viewBox="0 0 16 16"
    fill={color || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 5L8 11L2 5"
      stroke={colors.gray850}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
