import colors from 'constants/colors';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const NAVITEM_HEIGHT = '32px';
const NAVITEM_FONT_WEIGHT = 'normal'; // '500';

export const NAVITEM_BASE_STYLE = `
  color: ${colors.gray850};
  height: ${NAVITEM_HEIGHT};
  font-weight: ${NAVITEM_FONT_WEIGHT};
  align-items: center;
  cursor: pointer;

  :hover {
    color: ${colors.gray850};
    text-decoration: none;
    background-color: rgba(45, 47, 48, 0.08);
    transition: background-color 120ms ease-in 0s;
  }

  :active {
    color: ${colors.gray850};
    text-decoration: none;
    background-color: rgba(55, 53, 57, 0.16);
  }

  &.active {
    color: ${colors.gray850};
    background-color: rgba(45, 47, 48, 0.08);
    font-weight: 500;
  }
`;

export const StyledLink = styled(NavLink)`
  display: flex;
  ${NAVITEM_BASE_STYLE}
  margin-bottom: 0px;
  padding-left: 16px;
  min-height: ${NAVITEM_HEIGHT};
  height: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 9px;
`;

export const NavItemIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

export const NavItemLabel = styled.div<{ alternate?: boolean }>`
  ${(props) =>
    props.alternate &&
    `
    color: ${colors.blue500};

    :hover {
      color: ${colors.blue500};
    }

    :active {
      color: ${colors.blue500};
    }

    &.active {
      color: ${colors.blue500};
    }
  `};
  width: 138px;
`;

export const InfoIcon = styled.div`
  padding: 1px 6px;
  border-radius: 3px;
  font-size: 10px;
  color: ${colors.purple400};
  background: ${colors.purple100};
  margin-left: 12px;
  align-self: center;
`;
