import colors from 'constants/colors';
import styled from 'styled-components';

export const ShowMoreContainer = styled.div`
  padding: 8px;
`;

export const DotFlashingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const DotFlashing = styled.div`
  display: inline-block;
  position: absolute;
  width: 2px;
  height: 2px;
  border-radius: 5px;
  background-color: ${colors.blue300};
  color: ${colors.blue300};
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  ::before,
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  ::before {
    left: -5px;
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background-color: ${colors.blue300};
    color: ${colors.blue300};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  ::after {
    left: 5px;
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background-color: ${colors.blue300};
    color: ${colors.blue300};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: ${colors.blackNeutral};
    }
    50% {
      background-color: ${colors.blue300};
    }
    100% {
      background-color: ${colors.blue200};
    }
  }
`;
