import { useEffect, useRef, useState } from 'react';

import colors, { categoryColors as customFieldColors } from 'constants/colors';
import { FatCrossIcon, Tooltip } from 'modules/common-ui';
import { CustomFieldV2SelectValueType } from 'modules/types';
import { useSize } from 'modules/utils';

import { Close, Tag, Text } from './index.css';

export type CustomFieldV2SelectValueProps = {
  value: CustomFieldV2SelectValueType;
  fullSize?: boolean;
  onClear?: () => void;
};

export const CustomFieldV2SelectValue = ({
  value = { label: '', color: 'default' },
  onClear,
  fullSize = false,
}: CustomFieldV2SelectValueProps) => {
  const [isEllipsis, setIsEllipsis] = useState(false);

  const textRef = useRef<HTMLDivElement | null>(null);

  const { width } = useSize(textRef);

  useEffect(() => {
    if (textRef.current) {
      const isHidden =
        textRef.current.scrollWidth > textRef.current.clientWidth;

      setIsEllipsis(isHidden);
    }
  }, [width]);

  useEffect(() => {
    Tooltip.rebuild();
  }, [isEllipsis]);

  const { label, color } = value;
  if (!label) return null;

  const bgColor =
    (customFieldColors as any)[color] || customFieldColors.default;
  const tooltipId = `cfv-${label}-${Math.round(Math.random() * 100000)}`;

  return (
    <>
      <Tag
        className="customFieldTag2"
        bgColor={bgColor}
        data-tip={isEllipsis ? label : null}
        data-for={tooltipId}
        fullSize={fullSize}
      >
        <Text
          className="customFieldInsideTag"
          ref={textRef}
          showFullText={fullSize}
        >
          {label}
        </Text>
        {onClear !== undefined ? (
          <Close
            onClick={(event) => {
              event.stopPropagation();
              if (onClear) onClear();
            }}
          >
            {bgColor === colors.black ? ( // TODO: we do have black customField ?
              <FatCrossIcon color={colors.white} />
            ) : (
              <FatCrossIcon />
            )}
          </Close>
        ) : null}
        <Tooltip id={tooltipId} />
      </Tag>
    </>
  );
};
