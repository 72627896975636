import { useEffect } from 'react';

type HubspotFormProps = {
  region: string;
  portalId: string;
  formId: string;
  hiddenFields: Record<string, string>;
};

export const HubspotForm = ({
  region,
  portalId,
  formId,
  hiddenFields,
}: HubspotFormProps) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js-eu1.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region,
          portalId,
          formId,
          target: '#hubspotForm',
          onFormReady: (form: any) => {
            Object.entries(hiddenFields).forEach(([key, value]) => {
              form.querySelector(`input[name="TICKET.${key}"]`).value = value;
            });
          },
        });
      }
    });
  }, [region, portalId, formId, hiddenFields]);

  return <div id="hubspotForm"></div>;
};
