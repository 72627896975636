import { apiClient } from 'modules/api-client';
import { Spinner } from 'modules/common-ui';
import {
  Card,
  CardHeader,
  FooterContainer,
  MainContainer,
  PageContainer,
} from 'modules/signup/common';
import { Header, PartnersFooter } from 'modules/signup/components';
import { Text } from 'modules/signup/components/ConfirmEmail/ConfirmEmail.css';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const ActivationPage = () => {
  const location = useLocation();
  const [t] = useTranslation('signup');
  const params = new URLSearchParams(location.search);
  const [email] = useState(params.get('email') || '');
  const [token] = useState(params.get('token') || '');
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [alreadyActivated, setAlreadyActivated] = useState(false);
  const [redirectCooldown, setRedirectCooldown] = useState(5); // secs

  // activate
  useMemo(() => {
    apiClient.user
      .activate(email, token)
      .then((res) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((reason: string) => {
        if (reason && reason.toString().indexOf('already activated') > -1) {
          setAlreadyActivated(true);
        }
        setLoading(false);
        setSuccess(false);
      });
  }, [email, token]);

  const renderLoading = () => {
    return (
      <>
        <CardHeader displayLogo={true} title={t('activation.loading.title')} />
        <Spinner />
      </>
    );
  };

  const renderSuccess = () => {
    window.setInterval(function () {
      // Move to a new location or you can do something else
      setRedirectCooldown(redirectCooldown - 1);
      if (redirectCooldown === 1) {
        window.location.href = '/login';
      }
    }, 1000);
    return (
      <>
        <CardHeader displayLogo={true} title={t('activation.success.title')} />
        <br />
        <Text>
          {t('activation.success.message', { count: redirectCooldown })}
        </Text>
      </>
    );
  };

  const renderAlreadyActivated = () => {
    window.setInterval(function () {
      // Move to a new location or you can do something else
      setRedirectCooldown(redirectCooldown - 1);
      if (redirectCooldown === 1) {
        window.location.href = '/login';
      }
    }, 1000);
    return (
      <>
        <CardHeader
          displayLogo={true}
          title={t('activation.alreadyActivated.title')}
        />
        <br />
        <Text>
          {t('activation.success.message', { count: redirectCooldown })}
        </Text>
      </>
    );
  };

  const renderError = () => {
    return (
      <>
        <CardHeader displayLogo={true} title={t('activation.error.title')} />
        <br />
        <Text>{t('activation.error.message')}</Text>
      </>
    );
  };

  return (
    <PageContainer>
      <Header />

      <MainContainer>
        <Card width={440}>
          {loading && renderLoading()}
          {!loading && success && renderSuccess()}
          {!loading && alreadyActivated && renderAlreadyActivated()}
          {!loading && !alreadyActivated && !success && renderError()}
        </Card>
      </MainContainer>

      <FooterContainer>
        <PartnersFooter />
      </FooterContainer>
    </PageContainer>
  );
};
