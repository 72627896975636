import { useDispatch, useSelector } from 'react-redux';

import { Responsive } from 'modules/common-ui';
import { Store } from 'reducers';

import { useQuery } from '@apollo/client';
import i18next from 'i18next';
import { simpleActions } from 'modules/language/actions';
import moment from 'moment';
import { SideNavDesktop } from './containers/SideNavDesktop';
import { SideNavMobile } from './containers/SideNavMobile';
import { GraphQLUserLanguage, queryUserLanguage } from './gql';

export const SideNav = (props: any) => {
  const dispatch = useDispatch();

  const { userId, email } = useSelector<
    Store,
    { userId: number | null; email: string | null }
  >((state) => ({ userId: state.user.id, email: state.user.email }));

  useQuery<GraphQLUserLanguage>(queryUserLanguage, {
    onCompleted: (data) => {
      const { language } = data.viewer;

      i18next.changeLanguage(language);
      moment.locale(i18next.language);
      dispatch(simpleActions.chooseLanguage(language, userId, email));
    },
  });

  return (
    <Responsive props={props} desktop={SideNavDesktop} mobile={SideNavMobile} />
  );
};
