import styled from 'styled-components';

import colors from 'constants/colors';
import { resizeAnimation } from 'modules/common-ui';
import { StyledButton } from 'modules/common-ui/components/Button/index.css';
import {
  HeaderLeft,
  HeaderTitle,
} from 'modules/common-ui/components/Drawer/index.css';
import { GenericStyledInlineLinkCSS } from 'modules/common-ui/components/InlineLink/index.css';

const disabledFilterStyle = `
  background: ${colors.white};
  border: 1px solid ${colors.gray300};
  color: ${colors.gray300};
`;

export const FilterWrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  height: 100%;

  ${StyledButton} {
    ${(props) => (props.disabled ? disabledFilterStyle : '')}
  }
`;

export const FilterMask = styled.div`
  position: fixed;
  z-index: 99;
  height: 100%;
  width: 100%;
  inset: 0;
`;

export const Container = styled.div<{ alignRight?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 400px;
  position: absolute;

  margin-top: 2px;
  background-color: ${colors.white};
  z-index: 1001;
  border: 1px solid ${colors.gray300};
  border-radius: 5px;
  box-shadow: 0 9px 24px rgba(15, 15, 15, 0.2), 0 3px 6px rgba(15, 15, 15, 0.1),
    0 0 0 rgba(15, 15, 15, 0.05);
  ${(props) => props.alignRight && 'right: 0;'};

  animation-name: ${() => resizeAnimation};
  animation-duration: 200ms;
  animation-timing-function: ease-in;
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 340px;
  overflow: scroll;
`;

export const NewFilter = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
`;

export const FilterColumns = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
`;

export const ColumnLeft = styled.div<{ horizontalPadding?: boolean }>`
  padding-top: 8px;
  ${(props) => props.horizontalPadding && 'padding-left: 8px;'}
  margin-right: 8px;
`;

export const ColumnRight = styled.div`
  padding-top: 8px;
  background-color: white;
  width: 100%;
`;

export const DeleteColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
`;

export const DeleteIconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  transition: background 120ms ease-in 0s;
  width: 16px;
  border-radius: 3px;
  margin-right: 6px;
  margin-left: 6px;
  font-size: 12px;
  user-select: none;

  :hover {
    background: rgba(56, 58, 60, 0.08);
    cursor: pointer;
  }

  :active {
    background: rgba(45, 47, 48, 0.1);
  }
`;

export const AddFilterRow = styled.div`
  display: flex;
  padding: 6px 0;
`;

export const AddFilter = styled.div<{ disabled?: boolean }>`
  align-items: center;
  color: ${colors.gray500};
  cursor: pointer;
  display: flex;
  height: 28px;
  line-height: 28px;
  padding-left: 10.5px;
  transition: background 120ms ease-in 0s;
  width: 100%;
  user-select: none;

  ${(props) => props.disabled && 'cursor: not-allowed;'};

  :hover {
    background: rgba(45, 47, 48, 0.08);
  }

  :active {
    background: rgba(45, 47, 48, 0.16);
  }
`;

export const AddFilterIconWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  font-size: 18px;
  height: 100%;
  justify-content: flex-start;
  margin-right: 8px;
`;

export const FilterRowMobileWrapper = styled.div`
  padding: 24px 16px;
`;

export const CustomHeaderLeftMobile = styled(HeaderLeft)`
  justify-content: flex-start;
  overflow: visible;
`;

export const CustomHeaderTitleMobile = styled(HeaderTitle)`
  width: 100%;
  justify-content: center;
  padding-right: 25px;
`;

export const MobileHeaderResetAllAction = styled.div`
  ${GenericStyledInlineLinkCSS}
  margin-left: -8px;
  color: ${colors.cyan400};
  white-space: nowrap;
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 2;

  span {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    top: 30%;
    left: 0;
    color: rgba(0, 0, 0, 0.8);
    font-size: 15px;
    text-align: center;
    width: 100%;
    transform: translateY(50%);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`;

export const BetaLabelled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
`;

export const TabItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 8px;
  border-radius: 5px;
  &:hover {
    background: rgba(55, 53, 57, 0.08);
  }
`;

export const FiltersToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${colors.gray200};
`;

export const FilterButton = styled.button`
  background: ${colors.white};
  border: 0;
  color: ${colors.cyan400};

  :hover {
    cursor: pointer;
  }
`;

export const FreemiumButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 12px;
`;

export const SegmentListNew = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px;
  width: 100%;
  button {
    width: 100%;
  }
`;
