import colors from 'constants/colors';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { ChevronUpIcon } from '../Icons';
import { InlineLink } from '../InlineLink';

const CollapsableSections = styled.div`
  margin-bottom: -20px;
`;

const CollapsableSectionContainer = styled.div`
    padding-bottom: 20px;

    & + & {
        padding-top: 20px;
        border-top: 1px solid ${colors.gray200};
    }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MediaContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const Label = styled.div`
    color: ${colors.gray800};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;

    & + ${MediaContainer} {
      margin-top: 16px;
    }
    & + ${CollapsableSections} {
      margin-top: 16px;
    }
    & + ${LinksContainer} {
      margin-top: 16px;
    }
`;

const CollapsableRow = styled.div`
    cursor: pointer;
    color: ${colors.gray850};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    justify-content: space-between;
    display: flex;
    align-items: center;
`;

const CollapsableTitle = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${colors.gray850};

`;

const CollapsableIcon = styled.div<{ isToggled: boolean }>`
    margin-left: 6px;
    svg {
        height: 12px;
        width: 12px;
        transition: transform 0.2s ease-in-out;

        ${(props) =>
          props.isToggled
            ? `
            transform: rotate(180deg);
          `
            : `
            transform: rotate(0);
          `}
    }
`;

const CollapsableContent = styled.div<{ show: boolean }>`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    padding-top: ${(props) => (props.show ? '8px' : '0')};
    visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.show ? '1' : '0')};
    max-height: ${(props) => (props.show ? '1000px' : '0')};
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, max-height 0.2s ease-in-out, padding-top 0.2s ease-in-out ;
`;

const CollapsableSection = ({
  title,
  content,
}: { title: string; content: string }) => {
  const [isToggled, setIsToggled] = useState(false);

  const toggle = () => setIsToggled(!isToggled);

  return (
    <CollapsableSectionContainer>
      <CollapsableRow
        onClick={() => {
          toggle();
        }}
      >
        <CollapsableTitle>{title}</CollapsableTitle>
        <CollapsableIcon isToggled={isToggled}>
          <ChevronUpIcon color={colors.gray850} />
        </CollapsableIcon>
      </CollapsableRow>
      <CollapsableContent show={isToggled}>{content}</CollapsableContent>
    </CollapsableSectionContainer>
  );
};

const ContextualHelpContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const ContextualHelpContent = ({
  label,
  links,
  collapsableSections,
  media,
}: {
  label?: string;
  media?: ReactNode;
  links?: { icon: ReactNode; label: string; onClick: () => void }[];
  collapsableSections?: { title: string; content: string }[];
}) => {
  const renderLabel = () => {
    if (!label) return null;
    return <Label>{label}</Label>;
  };

  const renderLinks = () => {
    if (!links) return null;

    return (
      <LinksContainer>
        {links.map((link) => (
          <InlineLink
            key={`contextualhelp-link-${link.label}`}
            style={{ color: colors.gray850 }}
            iconLeft={link.icon}
            onClick={link.onClick}
          >
            {link.label}
          </InlineLink>
        ))}
      </LinksContainer>
    );
  };

  const renderMedia = () => {
    if (!media) return null;
    return <MediaContainer>{media}</MediaContainer>;
  };

  const renderCollapsableSections = () => {
    if (!collapsableSections) return null;

    return (
      <CollapsableSections>
        {collapsableSections.map((section) => (
          <CollapsableSection
            key={`contextualhelp-section-${section.title}`}
            title={section.title}
            content={section.content}
          />
        ))}
      </CollapsableSections>
    );
  };

  return (
    <ContextualHelpContentContainer>
      {renderLabel()}
      {renderLinks()}
      {renderMedia()}
      {renderCollapsableSections()}
    </ContextualHelpContentContainer>
  );
};
