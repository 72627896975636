export type PlusProps = { size?: number; color?: string };

export const PlusIcon = ({ size, color }: PlusProps) => (
  <svg
    width={size || 10}
    height={size || 9}
    viewBox="0 0 10 10"
    fill={color || 'currentColor'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50001 0.499924C5.50001 0.223818 5.27618 4.89967e-05 5.00008 0.00012207C4.72397 0.00019518 4.50014 0.224083 4.50014 0.500189V3.99999H0.999944C0.723838 3.99999 0.499951 4.22382 0.499878 4.49992C0.499805 4.77603 0.723574 4.99986 0.99968 4.99986H4.50014V8.50032C4.50014 8.77643 4.72397 9.0002 5.00008 9.00012C5.27618 9.00005 5.50001 8.77616 5.50001 8.50006V4.99986H8.99981C9.27592 4.99986 9.49981 4.77603 9.49988 4.49992C9.49995 4.22382 9.27618 3.99999 9.00008 3.99999H5.50001V0.499924Z"
    />
  </svg>
);
