import styled from 'styled-components';

export const ConfirmModalHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConfirmModalBodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const ConfirmModalFooterContainer = styled.div`
  display: flex;
  padding: 16px;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    width: 100%;
  }
  button + button {
    margin-top: 8px;
  }
`;
