import colors from 'constants/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ResultItemContainer = styled.div<{ active?: boolean }>`
  box-shadow: inset 0px -1px 0px #e5e8eb;
  cursor: pointer;
  display: flex;
  min-height: 52px;
  transition: background 0.3s;

  ${(props) => props.active && `background: ${colors.gray100}`};

  :hover {
    background: ${colors.gray100};
  }

  :active {
    background: ${colors.gray200};
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  padding-left: 16px;
  width: 45px;
  align-items: center;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const Label = styled.div`
  color: #2d2f30;
  font-size: 14px;
  font-weight: 500;
`;

export const Subtitle = styled.div`
  font-size: 12px;
  color: #929497;
`;

export const CategoryContainer = styled.div`
  display: flex;
  padding-right: 16px;
  justify-content: center;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  :active {
    text-decoration: none;
  }
`;
