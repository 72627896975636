import colors from 'constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

type DotProps = {
  gray?: boolean;
  blue?: boolean;
  brown?: boolean;
  red?: boolean;
  orange?: boolean;
  green?: boolean;
};

export const Dot = styled.div<DotProps>`
  background-color: ${(props) => {
    if (props.gray) return colors.gray400;
    if (props.blue) return colors.blue400;
    if (props.brown) return colors.brown100;
    if (props.red) return colors.red300;
    if (props.orange) return colors.yellow600;
    if (props.green) return colors.green300;
    return colors.gray850;
  }};
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  min-width: 8px;
  min-height: 8px;
`;
