import moment from 'moment';
import i18next from '../../../i18n';
import { simpleActions } from '../actions';

export type LanguageStore = {
  language: string;
};

const initialState: LanguageStore = {
  language: i18next.language,
};

type Action = ReturnType<(typeof simpleActions)[keyof typeof simpleActions]>;

export default function languageReducer(state = initialState, action: Action) {
  switch (action.type) {
    case 'CHOOSE_LANGUAGE':
      moment.locale(action.language);
      return { ...state, language: action.language };

    default:
      return state;
  }
}
