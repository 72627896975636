import { TextInput } from 'modules/common-ui';
import { useDebounce } from 'modules/utils';
import { useEffect, useState } from 'react';

export type SearchBarProps = {
  onSearch: (input: string) => void;
  debounceMs?: number;
  testId?: string;
  placeholder?: string;
  disabled?: boolean;
  variant?: 'primary' | 'secondary';
  autoFocus?: boolean;
};

export const SearchBar = ({
  onSearch,
  debounceMs = 1000,
  testId = 'test-id',
  placeholder = '',
  disabled = false,
  variant = 'primary',
  autoFocus = false,
}: SearchBarProps) => {
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, debounceMs);

  useEffect(() => {
    onSearch(debouncedValue);
  }, [debouncedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (event: any) => {
    event.persist();
    setValue(event.target.value);
    // do tracking
  };

  const onReset = () => {
    setValue('');
    // do tracking
    onSearch('');
  };

  return (
    <TextInput
      data-testid={testId}
      fullWidth
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      variant={variant}
      disabled={disabled}
      icon="search"
      onReset={onReset}
      autoFocus={autoFocus}
    />
  );
};
