import colors, { primaryColors } from 'constants/colors';
import styled from 'styled-components';

export const Box = styled.div<{
  disabled?: boolean;
  switchOn?: boolean;
}>`
  cursor: pointer;
  background: ${(props) =>
    props.disabled
      ? colors.gray300
      : props.switchOn
        ? primaryColors.main
        : colors.gray300};
  height: 18px;
  min-width: 30px;
  width: 30px;
  border-radius: 44px;
  padding: 2px;
  transition: background 200ms ease 0s, box-shadow 200ms ease 0s;
`;

export const Button = styled.div<{
  switchOn?: boolean;
}>`
  border-radius: 50%;
  background: white;
  height: 14px;
  width: 14px;
  transition: transform 0.2s;
  ${(props) =>
    props.switchOn ? 'transform: translateX(12px) translateY(0px);' : ''};
`;
