import { BREAKPOINT_DESKTOP_LARGE } from 'constants/breakpoints';
import colors from 'constants/colors';
import styled from 'styled-components';
import { FatCrossIcon } from '../Icons';
import { useClosableBannerLocalStorage } from './useClosableBannerStorage';

const Container = styled.div`
  display: flex;
  width: 100%;
  background: ${colors.offWhite};
  position: relative;
  border-radius: 5px;
  border: 1px solid ${colors.blue100};
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;

  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: ${colors.neutral200};
  }
  &:active {
    background: ${colors.neutral300};
  }    
  transition: background 0.1s ease-in-out;
  

  svg {
    padding: 8px;
    margin: auto;
    path {
      fill: ${colors.neutral850};
    }
  }
`;

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${BREAKPOINT_DESKTOP_LARGE}px) {
    align-items: left;
    margin-bottom: 16px;
  }
`;
const RightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;

  @media (max-width: ${BREAKPOINT_DESKTOP_LARGE}px) {
    margin-left: 0;
    align-items: left;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 16px;
  margin-right: 28px;
  flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;

  @media (max-width: ${BREAKPOINT_DESKTOP_LARGE}px) {
    flex-direction: column;
    align-items: start;
    margin-right: 60px;
  }
`;

const Title = styled.div`
    max-width: 880px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px
    color: ${colors.gray850};
`;

const Body = styled.div`
  max-width: 880px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: Inter;
  color: ${colors.gray850};
`;

const Footer = styled.div`
  width: fit-content;
`;

export type ClosableBannerProps = {
  style?: React.CSSProperties;
  id: string;
  content: {
    title: string;
    body: string;
    footer?: React.ReactNode;
  };
  action?: React.ReactNode;
};

/** This component is used to notify about a call to action which is closable by the user */
export const ClosableBanner = ({
  style,
  id,
  content,
  action,
}: ClosableBannerProps) => {
  const { isClosed, close } = useClosableBannerLocalStorage(id);

  const renderContent = () => {
    return (
      <LeftContainer>
        <Title>{content.title}</Title>
        <Body>{content.body}</Body>
        {content.footer ? <Footer>{content.footer}</Footer> : null}
      </LeftContainer>
    );
  };

  const renderAction = () => {
    if (!action) return null;
    return <RightContainer>{action}</RightContainer>;
  };

  if (isClosed) return null;

  return (
    <Container style={style}>
      <CloseContainer
        onClick={() => {
          close();
        }}
      >
        <FatCrossIcon size={24} />
      </CloseContainer>
      <ContentContainer>
        {renderContent()}
        {renderAction()}
      </ContentContainer>
    </Container>
  );
};
