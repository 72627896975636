import {
  GraphQLAssetDetailMobileType,
  GraphQLDetailsDataType,
  GraphQLUsageDataType,
} from '../types';

export type AssetOverviewAction =
  | ReturnType<typeof receivedUsageData>
  | ReturnType<typeof receivedDetailsData>
  | ReturnType<typeof receivedDetailsDataMobile>
  | ReturnType<typeof updateCache>
  | ReturnType<typeof filterDate>
  | ReturnType<typeof closeWarning>;

export const ASSET_OVERVIEW = {
  CLOSE_WARNING: 'ASSET_OVERVIEW_CLOSE_WARNING' as const,
  FILTER_DATE: 'ASSET_OVERVIEW_FILTER_DATE' as const,
  RECEIVED_DETAILS_DATA_MOBILE: 'RECEIVED_DETAILS_DATA_MOBILE' as const,
  RECEIVED_DETAILS_DATA: 'ASSET_OVERVIEW_RECEIVED_DETAILS_DATA' as const,
  RECEIVED_USAGE_DATA: 'ASSET_OVERVIEW_RECEIVED_USAGE_DATA' as const,
  UPDATE_CACHE: 'ASSET_OVERVIEW_UPDATE_CACHE' as const,
};

export function receivedUsageData(data: GraphQLUsageDataType) {
  return {
    type: ASSET_OVERVIEW.RECEIVED_USAGE_DATA,
    data,
  };
}

export function receivedDetailsData(data: GraphQLDetailsDataType) {
  return {
    type: ASSET_OVERVIEW.RECEIVED_DETAILS_DATA,
    data,
  };
}

export function receivedDetailsDataMobile(data: GraphQLAssetDetailMobileType) {
  return {
    type: ASSET_OVERVIEW.RECEIVED_DETAILS_DATA_MOBILE,
    data,
  };
}

export function updateCache(data: any) {
  return {
    type: ASSET_OVERVIEW.UPDATE_CACHE,
    data,
  };
}

export function filterDate(since: string, until: string) {
  return {
    type: ASSET_OVERVIEW.FILTER_DATE,
    since,
    until,
  };
}

function closeWarning() {
  return {
    type: ASSET_OVERVIEW.CLOSE_WARNING,
  };
}
