export type TrashIconProps = {
  size?: number;
  color?: string;
};

export const TrashIcon = ({ size = 16, color = '#E12646' }: TrashIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1.5"
      y1="3.5"
      x2="14.5"
      y2="3.5"
      stroke={color}
      strokeLinecap="round"
    />
    <rect x="2.5" y="3.5" width="11" height="11" stroke={color} />
    <rect x="5.5" y="6" width="1" height="6" fill={color} />
    <rect x="7.5" y="6" width="1" height="6" fill={color} />
    <rect x="9.5" y="6" width="1" height="6" fill={color} />
    <rect x="5.5" y="1.5" width="5" height="2" rx="0.5" stroke={color} />
  </svg>
);
