import colors from 'constants/colors';

type InfoIconProps = {
  color?: string;
  dataFor?: string;
  marginLeft?: number;
  marginRight?: number;
  size?: number;
  style?: React.CSSProperties;
};

export const InfoIcon = ({
  color = colors.gray500,
  dataFor,
  marginLeft,
  marginRight,
  size = 16,
  style,
}: InfoIconProps) => (
  <svg
    data-for={dataFor}
    data-tip=""
    fill="none"
    height={size}
    style={{ ...style, marginLeft, marginRight, verticalAlign: 'middle' }}
    viewBox={`0 0 ${size} ${size}`}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={true}
  >
    <circle cx="8" cy="8" r="6.5" stroke={color} />
    <path
      d="M8.47266 11.95H7.38867V6.60911H8.47266V11.95ZM7.30078 4.92713C7.30078 4.75131 7.35352 4.60285 7.45898 4.48173C7.56836 4.36061 7.72852 4.30005 7.93945 4.30005C8.15039 4.30005 8.31055 4.36061 8.41992 4.48173C8.5293 4.60285 8.58398 4.75131 8.58398 4.92713C8.58398 5.10295 8.5293 5.24946 8.41992 5.36667C8.31055 5.48388 8.15039 5.54249 7.93945 5.54249C7.72852 5.54249 7.56836 5.48388 7.45898 5.36667C7.35352 5.24946 7.30078 5.10295 7.30078 4.92713Z"
      fill={color}
    />
  </svg>
);
