import styled from 'styled-components';

import colors from 'constants/colors';

export type ProgressCircularProps = {
  size?: number;
  color?: string;
};

const SVG = styled.svg`
  animation: 0.75s linear infinite svg-animation;
  max-width: 100px;

  // SVG animation.
  @keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  circle {
    // animation: 1.2s ease-in-out infinite both circle-animation; // chrome-like animation
    display: block;
    fill: transparent;
    stroke: ${colors.gray500};
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 90;
    stroke-width: 10px;
    transform-origin: 50% 50%;
  }

  // Circle animation.
  @keyframes circle-animation {
    0%,
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }

    50%,
    75% {
      stroke-dashoffset: 90;
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
`;

export const ProgressCircular = ({
  size = 16,
  color = colors.gray500,
}: ProgressCircularProps) => (
  <SVG
    color={color}
    width={size}
    height={size}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="50" cy="50" r="40" />
  </SVG>
);
