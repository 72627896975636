import React from 'react';
import { useTranslation } from 'react-i18next';

import PageAction from 'components/ui/pageaction';
import { Button, CircularSpinner, ExportIcon } from 'modules/common-ui';

import { ExportInProgressButton } from './index.css';

export type ExportPropTypes = {
  id?: string;
  label?: string;
  trackingLabel?: string;
  loading?: boolean;
  loadingLabel?: string;
  onClick: () => void;
  style?: React.CSSProperties;
  displayAsButton?: boolean;
};

export const ExportButton = (props: ExportPropTypes) => {
  const [t] = useTranslation('translation');

  const {
    id,
    label,
    loading = false,
    loadingLabel,
    onClick,
    style,
    trackingLabel = '',
    displayAsButton = false,
  } = props;

  return displayAsButton ? (
    <Button
      id={id}
      iconLeft={loading ? <CircularSpinner /> : <ExportIcon size={16} />}
      style={style}
      onClick={onClick}
      variant="neutral"
    >
      {loading
        ? loadingLabel
          ? loadingLabel
          : t('common.exporting')
        : label
          ? label
          : t('common.export')}
    </Button>
  ) : loading ? (
    <ExportInProgressButton>
      <div
        className="spinner"
        style={{
          height: '14px',
          width: '14px',
          margin: '0',
          marginRight: '8px',
          backgroundColor: '#929497',
        }}
      />
      <span>{loadingLabel ? loadingLabel : t('common.exporting')}</span>
    </ExportInProgressButton>
  ) : (
    <PageAction
      id={id}
      trackingLabel={trackingLabel}
      data-test="export-button"
      icon={ExportIcon}
      label={label ? label : t('common.export')}
      onClick={onClick}
      style={style}
    />
  );
};
