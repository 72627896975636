import { HibooLogo } from 'modules/common-ui';
import {
  CardHeaderContainer,
  CardHeaderLogo,
  CardHeaderTitle,
} from 'modules/signup/common';

type CardHeaderProps = {
  title: string;
  displayLogo?: boolean;
};

export const CardHeader = ({ title, displayLogo = true }: CardHeaderProps) => {
  return (
    <CardHeaderContainer>
      {displayLogo && (
        <CardHeaderLogo>
          <HibooLogo />
        </CardHeaderLogo>
      )}
      <CardHeaderTitle>{title}</CardHeaderTitle>
    </CardHeaderContainer>
  );
};
