import styled from 'styled-components';

import colors from 'constants/colors';

export const CardImageContainer = styled.div`
  filter: drop-shadow(0px 0px 0px rgba(15, 15, 15, 0.05))
    drop-shadow(0px 2.75139px 5.50278px rgba(15, 15, 15, 0.1))
    drop-shadow(0px 8.25417px 22.0111px rgba(15, 15, 15, 0.2));
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    inset: 0;
    background: ${colors.gray50};
    box-shadow: 0px 0px 0px rgba(15, 15, 15, 0.05),
      0px 2.75139px 5.50278px rgba(15, 15, 15, 0.1),
      0px 8.25417px 22.0111px rgba(15, 15, 15, 0.2);
    border-radius: 7.33704px;
    transform: rotate(-3.87deg);
    z-index: -1;
  }

  &::after {
    content: ' ';
    position: absolute;
    inset: 0;
    background: ${colors.white};
    box-shadow: 0px 0px 0px rgba(15, 15, 15, 0.05),
      0px 2.75139px 5.50278px rgba(15, 15, 15, 0.1),
      0px 8.25417px 22.0111px rgba(15, 15, 15, 0.2);
    border-radius: 7.33704px;
    transform: rotate(3.3deg);
    z-index: -2;
  }

  img.thumbnail {
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    width: 243px;
    height: 180px;
    position: relative;
    z-index: 10;
    object-fit: cover;
  }

  img.play-button {
    cursor: pointer;
    height: 42px;
    width: 42px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    transition: transform 120ms ease-in-out;
  }

  &:hover {
    img.play-button {
      transform: translate(-50%, -50%) scale(1.5);
    }
  }

  iframe {
    height: 100%;
    width: 100%;
  }
`;

export const CardContainer = styled.div`
  p {
    color: ${colors.blue500};
    margin-top: 32px;
    margin-bottom: 0;
    text-align: center;
  }
`;
