import { ReactNode } from 'react';
import styled from 'styled-components';

const ContextualHelpFooterContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 16px;
`;

export const ContextualHelpFooter = ({ footer }: { footer?: ReactNode }) => {
  if (!footer) return null;
  return (
    <ContextualHelpFooterContainer>{footer}</ContextualHelpFooterContainer>
  );
};
