import React from 'react';

import { StepContainer } from './index.css';
import { Step as StepType } from './Stepper';

type StepProps = {
  step: StepType;
  isCurrent: boolean;
};

export const Step: React.FC<StepProps> = ({ step, isCurrent }) => {
  const { label, title } = step;

  return (
    <StepContainer isCurrent={isCurrent}>
      <div>{label}</div>
      <p>{title}</p>
    </StepContainer>
  );
};
