import colors from 'constants/colors';

export type FormatTextIconProps = {
  color?: string;
};

export const FormatTextIcon = ({
  color = colors.gray850,
}: FormatTextIconProps) => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.3031 4.06818C7.3031 3.79204 7.07924 3.56818 6.8031 3.56818H0.803101C0.526959 3.56818 0.303101 3.79204 0.303101 4.06818V5.11364C0.303101 5.38978 0.526959 5.61364 0.803101 5.61364H6.8031C7.07924 5.61364 7.3031 5.38978 7.3031 5.11364V4.06818ZM0.803101 0.5C0.526959 0.5 0.303101 0.723858 0.303101 1V2.04545C0.303101 2.3216 0.526959 2.54545 0.803101 2.54545H12.8031C13.0792 2.54545 13.3031 2.3216 13.3031 2.04545V1C13.3031 0.723858 13.0792 0.5 12.8031 0.5H0.803101ZM0.303101 8.18182C0.303101 8.45796 0.526959 8.68182 0.803101 8.68182H11.8031C12.0792 8.68182 12.3031 8.45796 12.3031 8.18182V7.13636C12.3031 6.86022 12.0792 6.63636 11.8031 6.63636H0.803101C0.526959 6.63636 0.303101 6.86022 0.303101 7.13636V8.18182ZM0.303101 11.25C0.303101 11.5261 0.526959 11.75 0.803101 11.75H9.8031C10.0792 11.75 10.3031 11.5261 10.3031 11.25V10.2045C10.3031 9.9284 10.0792 9.70455 9.8031 9.70455H0.803101C0.526959 9.70455 0.303101 9.9284 0.303101 10.2045V11.25Z"
      fill={color}
    />
  </svg>
);
