import { apiClient } from 'modules/api-client';
import { ApiPricingResponse } from 'modules/api-client/flags';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'reducers';
import { PricingPlan } from '../models/PricingPlan.model';

const buildPricingPlan = (response: ApiPricingResponse) => {
  const { pricingPlan, freeTrial, freeTrialStart, freeTrialEnd } = response;
  return new PricingPlan(pricingPlan, freeTrial, freeTrialStart, freeTrialEnd);
};

export const usePricingPlan = () => {
  const [pricingPlan, setPricingPlan] = useState<PricingPlan | null>(null);
  const [loadingPricingPlan, setLoadingPricingPlan] = useState<boolean>(true);

  const { userToken } = useSelector((state: Store) => ({
    userToken: state.user.token || '',
  }));

  useEffect(() => {
    apiClient.flag
      .getPricingPlanCheck(userToken)
      .then((response: ApiPricingResponse) => {
        const plan = buildPricingPlan(response);
        setPricingPlan(plan);
      })
      .finally(() => setLoadingPricingPlan(false));
  }, [userToken]);

  return {
    pricingPlan,
    loadingPricingPlan,
  };
};
