import colors from 'constants/colors';

export type TruckIconProps = {
  size?: number;
  color?: string;
};

export const TruckIcon = ({
  size = 16,
  color = colors.gray850,
}: TruckIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.74374 11.4118H1.79999V4H9.74374V5.58824M9.74374 11.4118H14.51V8.23529L13.1142 6.49109L12.3917 5.58824H9.74374M9.74374 11.4118V5.58824"
      stroke={color}
    />
    <circle cx="4.08824" cy="11.4118" r="1.08824" fill="white" stroke={color} />
    <circle cx="12.1882" cy="11.4118" r="1.08824" fill="white" stroke={color} />
  </svg>
);
