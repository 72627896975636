import colors from 'constants/colors';

export type BusinessIconProps = { color?: string; size?: number };

export const BusinessIcon = ({
  color = colors.gray500,
  size = 16,
}: BusinessIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9587 4.2H2.04107C1.68696 4.2 1.3999 4.45195 1.3999 4.76275V12.4373C1.3999 12.748 1.68696 13 2.04107 13H13.9587C14.3128 13 14.5999 12.748 14.5999 12.4373V4.76275C14.5999 4.45195 14.3128 4.2 13.9587 4.2Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2 7L6.5 9M14 7L9.5 9" stroke={color} strokeLinecap="round" />
    <path
      d="M10.2 4.2V3.1C10.2 2.49249 9.70756 2 9.10005 2H6.90005C6.29254 2 5.80005 2.49249 5.80005 3.1L5.80005 4.2"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="6.5" y="7.5" width="3" height="2" rx="0.5" stroke={color} />
  </svg>
);
