import { PRICING_PLANS } from 'constants/pricingPlans';
import debounce from 'lodash/debounce';
import { FleetSegment } from 'modules/analytics/components/AssetFilter/types';
import { CheckIcon, TextInput, Tooltip } from 'modules/common-ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Store } from 'reducers';
import { LockIcon } from '../LockIcon/LockIcon';
import {
  CheckIconContainer,
  LockContainer,
  SearchContainer,
  Segment,
  SegmentActionsContainer,
  SegmentActionsHoverContainer,
  SegmentAssetCount,
  SegmentDefaultItem,
  SegmentInfoContainer,
  SegmentTitle,
  Segments,
} from './Segment.css';
import { SegmentAction, SegmentActions } from './SegmentActions';
import { SegmentBetaInfos } from './SegmentBetaInfos';

const SegmentItem = ({
  segment,
  isActive,
  isEditable,
  isLocked,
  applySegment,
  onTrigger,
  hibooSegmentTooltipId,
  isLastElement,
}: {
  segment: FleetSegment;
  isActive: boolean;
  isEditable: boolean;
  isLocked: boolean;
  applySegment: (segment: FleetSegment) => void;
  onTrigger: (action: SegmentAction, segment: FleetSegment) => void;
  hibooSegmentTooltipId: string;
  isLastElement: boolean;
}) => {
  const { t } = useTranslation('fleetSegment');
  const [isHover, setIsHover] = useState(false);
  return (
    <Segment
      active={isActive}
      key={segment.id}
      onClick={() => {
        applySegment(segment);
      }}
      onMouseLeave={debounce(() => {
        setIsHover(false);
      }, 10)}
      onMouseEnter={debounce(() => {
        setIsHover(true);
      }, 10)}
    >
      <SegmentInfoContainer>
        <SegmentTitle active={isActive}>
          {segment.name}
          {segment.kind === 'default' && (
            <SegmentDefaultItem>
              {' '}
              ({t('list.defaultSegment').toLowerCase()})
            </SegmentDefaultItem>
          )}
        </SegmentTitle>
        <SegmentAssetCount>
          {t('list.assetCount', { count: segment.assetCount })}
        </SegmentAssetCount>
      </SegmentInfoContainer>
      <SegmentActionsContainer>
        <CheckIconContainer>
          {isActive && <CheckIcon color="#2D2F30" size={16} />}
        </CheckIconContainer>
        <SegmentActionsHoverContainer id="segment-actions-hover-container">
          {isLocked && (
            <LockContainer>
              <LockIcon data-for={hibooSegmentTooltipId} data-tip="" />
            </LockContainer>
          )}
          {isEditable && (
            <SegmentActions
              segment={segment}
              onTrigger={onTrigger}
              segmentIsHover={isHover}
              isLastElement={isLastElement}
            />
          )}
        </SegmentActionsHoverContainer>
      </SegmentActionsContainer>
    </Segment>
  );
};

export const SegmentList = ({
  activeSegment,
  segments,
  applySegment,
  triggerEditSegment,
  triggerDeleteSegment,
  userIsAdmin,
  isMobile,
}: {
  activeSegment: FleetSegment | null;
  segments: FleetSegment[];
  applySegment: (segment: FleetSegment) => void;
  triggerEditSegment?: (fleetSegment: FleetSegment) => void;
  triggerDeleteSegment?: (fleetSegment: FleetSegment) => void;
  userIsAdmin: boolean;
  isMobile: boolean;
}) => {
  const { t } = useTranslation('fleetSegment');
  const hibooSegmentTooltipId = 'tooltip-hiboo-segment';
  const [filteredSegments, setFilteredSegments] = useState(segments);
  const [searchValue, setSearchValue] = useState('');

  const { pricingPlan } = useSelector<Store, { pricingPlan: string | null }>(
    (state) => {
      return {
        pricingPlan: state.user.organizationPlan,
      };
    },
  );

  useEffect(() => {
    if (!searchValue) {
      setFilteredSegments(segments);
      return;
    }

    const lowerCaseSearch = searchValue.toLowerCase();
    setFilteredSegments(
      segments.filter((segment) =>
        segment.name.toLowerCase().includes(lowerCaseSearch),
      ),
    );
  }, [segments, searchValue]);

  const onSearch = (search: string) => {
    setSearchValue(search);
  };

  const onTrigger = (action: SegmentAction, segment: FleetSegment) => {
    switch (action) {
      case SegmentAction.EDIT:
        triggerEditSegment && triggerEditSegment(segment);
        break;
      case SegmentAction.DELETE:
        triggerDeleteSegment && triggerDeleteSegment(segment);
        break;
    }
  };

  return (
    <>
      <SegmentBetaInfos />
      <Tooltip
        id={hibooSegmentTooltipId}
        background="dark"
        type="dark"
        place="top"
      >
        {t('readonly')}
      </Tooltip>

      <SearchContainer>
        <TextInput
          fullWidth={true}
          value={searchValue}
          onChange={(e) => {
            onSearch(e.target.value);
          }}
          placeholder={t('search.placeholder')}
          aria-label={t('search.placeholder')}
          variant="secondary"
          disabled={false}
          icon="search"
          onReset={() => {
            onSearch('');
          }}
        />
      </SearchContainer>

      <Segments isMobile={isMobile}>
        {filteredSegments
          .filter((s) => {
            const isFreemium = pricingPlan === PRICING_PLANS.freemium;
            // Prevent to list the previously created custom segments for freemium users
            if (isFreemium) {
              return s.kind !== 'custom';
            }
            return true;
          })
          .map((segment, index) => {
            const isHiboo = segment.kind !== 'custom';
            const isLocked = isHiboo && userIsAdmin && !isMobile;
            const isEditable = !isHiboo && userIsAdmin && !isMobile;
            const isActive = segment.id === activeSegment?.id;
            const isLastElement = index === filteredSegments.length - 1;
            return (
              <SegmentItem
                applySegment={applySegment}
                hibooSegmentTooltipId={hibooSegmentTooltipId}
                isActive={isActive}
                isEditable={isEditable}
                isLocked={isLocked}
                onTrigger={onTrigger}
                segment={segment}
                key={segment.id}
                isLastElement={isLastElement}
              />
            );
          })}
      </Segments>
    </>
  );
};
