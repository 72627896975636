import colors from 'constants/colors';

type LockIconProps = {
  'data-tip'?: string;
  'data-for'?: string;
  color?: (typeof colors)[keyof typeof colors];
};

export const LockIcon = ({
  color = colors.gray500,
  ...props
}: LockIconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.7149 7.40503H4.27988C4.05897 7.40503 3.87988 7.58412 3.87988 7.80503V13.26C3.87988 13.4809 4.05897 13.66 4.27988 13.66H11.7149C11.9358 13.66 12.1149 13.4809 12.1149 13.26V7.80503C12.1149 7.58412 11.9358 7.40503 11.7149 7.40503Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6199 6.95002V5.02002C10.6199 3.57502 9.44988 2.40002 7.99988 2.40002C6.54988 2.40002 5.37988 3.57002 5.37988 5.02002V7.09502"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
