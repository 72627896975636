import { TreeInput, TreeNode } from './types';

function createIdsMapping<T>(data: TreeInput<T>[]) {
  const idsToIndexesMapping: Record<string, number> = {};
  const idsToParentIdsMapping: Record<string, string> = {};
  const idsToChildrenIdsMapping: Record<string, string[]> = {};

  data.forEach((row, index) => {
    idsToIndexesMapping[`${row.id}`] = index;
    if (row.parentId) {
      idsToParentIdsMapping[`${row.id}`] = `${row.parentId}`;
      if (!idsToChildrenIdsMapping[`${row.parentId}`]) {
        idsToChildrenIdsMapping[`${row.parentId}`] = [];
      }
      idsToChildrenIdsMapping[`${row.parentId}`].push(`${row.id}`);
    }
  });
  return {
    idsToIndexesMapping,
    idsToParentIdsMapping,
    idsToChildrenIdsMapping,
  };
}

function addLevels<T>(roots: TreeNode<T>[]) {
  for (const rootNode of roots) {
    if (rootNode.children) {
      for (const child of rootNode.children) {
        child.level = rootNode.level + 1;
      }
      addLevels(rootNode.children);
    }
  }
  return roots;
}

export function createNodesFromList<T>(data: TreeInput<T>[]): TreeNode<T>[] {
  const dataNodes: TreeNode<T>[] = data.map((d) => {
    const { parentId, label, ...rest } = d;
    return {
      id: d.id,
      parentId,
      label,
      level: 0,
      children: [],
      data: rest as T,
    };
  });
  return dataNodes;
}

export function makeTree<T>(data: TreeInput<T>[]): TreeNode<T>[] {
  let roots: TreeNode<T>[] = [];
  // create basic nodes from input
  const dataNodes = createNodesFromList(data);

  // update tree with children
  const { idsToIndexesMapping } = createIdsMapping(data);
  dataNodes.forEach((el) => {
    if (!el.parentId || idsToIndexesMapping[el.parentId] === undefined) {
      el.level = 0;
      el.parentId = null;
      roots.push(el);
      return;
    }
    const parentEl = dataNodes[+idsToIndexesMapping[`${el.parentId}`]];
    if (parentEl) {
      parentEl.children = [...(parentEl.children || []), el];
    }
  });

  // update levels
  roots = addLevels<T>(roots);
  return roots;
}

/**
 * returns list of node where first elem is nearest parent
 * @param nodeId
 * @param data
 * @returns
 */
export function findParentNodes<T>(nodeId: string, data: TreeInput<T>[]) {
  const { idsToIndexesMapping, idsToParentIdsMapping } = createIdsMapping(data);

  const results = [];

  let parentNodeId = idsToParentIdsMapping[nodeId];
  if (parentNodeId === nodeId) {
    console.error(`Node ${nodeId} is its own parent!`);
    return [];
  }

  const MAX_RECURSION_LEVEL = 10;
  let recursionLevel = 0;
  while (
    parentNodeId &&
    data[idsToIndexesMapping[`${parentNodeId}`]] &&
    recursionLevel < MAX_RECURSION_LEVEL
  ) {
    results.push(data[idsToIndexesMapping[`${parentNodeId}`]]);
    parentNodeId = idsToParentIdsMapping[`${parentNodeId}`];
    recursionLevel++;
  }
  if (recursionLevel === MAX_RECURSION_LEVEL) {
    console.warn(`Max recursion reached for nodeId ${nodeId}`);
  }
  return results;
}

export function findChildNodes<T>(nodeId: string, data: TreeInput<T>[]) {
  const { idsToChildrenIdsMapping } = createIdsMapping(data);

  const results: string[] = [];

  const walkThrough = (
    results: string[],
    idsToChildrenIdsMapping: Record<string, string[]>,
    nodeId: string,
  ) => {
    if (idsToChildrenIdsMapping[nodeId]) {
      const children = idsToChildrenIdsMapping[nodeId];
      results.push(...children);
      children.forEach((child) => {
        // avoid infinite loop if node is its own parent
        if (child !== nodeId) {
          walkThrough(results, idsToChildrenIdsMapping, child);
        }
      });
    }
  };

  walkThrough(results, idsToChildrenIdsMapping, nodeId);
  return results;
}
