import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { ExecutionResult, GraphQLError } from 'graphql';

interface ErrorResponse {
  graphQLErrors?: ReadonlyArray<GraphQLError>;
  networkError?: Error | ServerError | ServerParseError;
  response?: ExecutionResult;
  operation: Operation;
  forward: NextLink;
}

interface ErrorHandler {
  (error: ErrorResponse): Observable<FetchResult> | void;
}

export function onError(errorHandler: ErrorHandler): ApolloLink {
  return new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      let sub: any;
      let retriedSub: any;
      let retriedResult: any;

      try {
        sub = forward(operation).subscribe({
          next: (result) => {
            if (result.errors) {
              retriedResult = errorHandler({
                graphQLErrors: result.errors,
                response: result,
                operation,
                forward,
              });

              if (retriedResult) {
                retriedSub = retriedResult.subscribe({
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                });
                return;
              }
            }
            observer.next(result);
          },
          error: (networkError) => {
            retriedResult = errorHandler({
              operation,
              networkError,
              //Network errors can return GraphQL errors on for example a 403
              graphQLErrors:
                networkError &&
                networkError.result &&
                networkError.result.errors,
              forward,
            });
            if (retriedResult) {
              retriedSub = retriedResult.subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });
              return;
            }
            observer.error(networkError);
          },
          complete: () => {
            // disable the previous sub from calling complete on observable
            // if retry is in flight.
            if (!retriedResult) {
              observer.complete.bind(observer)();
            }
          },
        });
      } catch (e: any) {
        errorHandler({ networkError: e, operation, forward });
        observer.error(e);
      }

      return () => {
        if (sub) sub.unsubscribe();
        if (retriedSub) sub.unsubscribe();
      };
    });
  });
}

export const reactErrorBoundaryHandler = (
  error: Error,
  organizationId: number | null,
  workspaceId: number | null,
) => {
  Sentry.withScope((scope) => {
    scope.setExtra('source', 'REACT_ERROR_BOUNDARY');
    organizationId && scope.setExtra('organizationId', organizationId);
    workspaceId && scope.setExtra('workspaceId', workspaceId);
    Sentry.captureException(error);
  });
};
