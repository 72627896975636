import { Tree } from 'modules/common-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HeaderRow,
  TreeContainer,
  WorkspaceSwitcherContainer,
} from './WorkspaceSwitcher.css';
import { AddWorkspaceRow } from './components/AddWorkspaceRow/AddWorkspaceRow';
import { WorkspaceRow } from './components/WorkspaceRow/WorkspaceRow';

type WorkspaceSwitcherProps = {
  currentWorkspace: any;
  workspaces: any[];
  onChange: (workspaceId: number) => void;
  onClose: () => void;
};

type WorkspaceData = {
  id: number;
  name: string;
  sharedWith: { id: number; name: string }[];
  sharedBy?: { id: number; name: string };
  totalAssets: number;
};

const sortSharedWorkspacesLast = (workspaces: any[]) => {
  const sharedWithWorkspaces = workspaces
    .filter((ws) => ws.sharedWith.length > 0)
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  const sharedByWorkspaces = workspaces
    .filter((ws) => ws.sharedBy && ws.sharedBy.id)
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  const otherWorkspaces = workspaces
    .filter((ws) => ws.sharedWith.length === 0 && !ws.sharedBy)
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

  return [...otherWorkspaces, ...sharedWithWorkspaces, ...sharedByWorkspaces];
};

export const WorkspaceSwitcher = ({
  currentWorkspace,
  workspaces,
  onChange,
  onClose,
}: WorkspaceSwitcherProps) => {
  const [t] = useTranslation('sidenav');

  const workspacesList = useMemo(() => {
    return sortSharedWorkspacesLast(workspaces).map((ws) => ({
      ...ws,
      label: ws.name,
      parentId: ws.parentWorkspaceId,
    }));
  }, [workspaces]);

  const renderItem = (data: WorkspaceData) => {
    return (
      <WorkspaceRow
        key={data.id}
        id={data.id}
        name={data.name}
        nbAssets={data.totalAssets}
        sharedBy={data.sharedBy}
        sharedWith={data.sharedWith}
        selected={currentWorkspace && +data.id === +currentWorkspace.id}
      />
    );
  };

  return (
    <WorkspaceSwitcherContainer>
      <HeaderRow>{t('sidenav.picker.yourFleet')}</HeaderRow>
      <TreeContainer>
        <Tree<WorkspaceData>
          list={workspacesList}
          renderItem={renderItem}
          selectedNodesId={currentWorkspace ? [`${currentWorkspace.id}`] : []}
          highlightSelecteds={true}
          searchPlaceholder={t('sidenav.picker.searchPlaceholder')}
          onClick={(data) => {
            onChange(+data.id);
          }}
        />
      </TreeContainer>
      <AddWorkspaceRow onClick={onClose} />
    </WorkspaceSwitcherContainer>
  );
};
