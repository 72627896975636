export const ImageIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 12.6667V3.33333C12 2.6 11.4 2 10.6667 2H1.33333C0.6 2 0 2.6 0 3.33333V12.6667C0 13.4 0.6 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667ZM3.51391 9.1964C3.59236 9.09553 3.74399 9.0931 3.82564 9.1914L5.17419 10.8151C5.25589 10.9134 5.40765 10.9109 5.48604 10.8099L7.50595 8.2071C7.58686 8.10284 7.74477 8.10414 7.82395 8.20971L10.4267 11.68C10.5256 11.8118 10.4315 12 10.2667 12H1.74226C1.57583 12 1.48221 11.8086 1.58439 11.6772L3.51391 9.1964Z"
        fill={color || '#929497'}
      />
    </svg>
  );
};
