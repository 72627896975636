import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

export const Portal: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const mount = document.getElementById('portal-root');
  const el = document.createElement('div');

  useEffect(() => {
    if (mount) {
      mount.appendChild(el);
    }

    return () => {
      if (mount) mount.removeChild(el);
    };
  }, [el, mount]);

  return createPortal(children, el);
};
