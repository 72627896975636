import layout from 'modules/layout';

import { Header } from './components/header';
import { MainContent } from './components/mainContent';
import { UserfulLinks } from './components/usefulLinks';
import { Container } from './index.css';

export const OnboardingPage = () => {
  return (
    <layout.Page regular>
      <Container>
        <Header />
        <UserfulLinks />
        <MainContent />
      </Container>
    </layout.Page>
  );
};
