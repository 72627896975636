import FaCheck from 'react-icons/lib/fa/check';
import styled from 'styled-components';

import colors from 'constants/colors';

const borderColor = (props: {
  danger?: boolean;
  checked?: boolean;
  disabled?: boolean;
}) => {
  if (props.danger) {
    return colors.magenta;
  }
  if (props.disabled) {
    return colors.blue200;
  }

  return props.checked ? colors.blue400 : colors.blue300;
};

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  min-height: 28px;
`;

export const CheckIcon = styled(FaCheck)`
  fill: ${colors.white};
  font-size: 14px;
`;

const getBackgroundColor = (
  checked: boolean,
  disabled?: boolean,
  color?: string,
) => {
  if (!checked) {
    return colors.gray100;
  }

  if (disabled && checked) {
    return colors.blue100;
  }

  if (color && color.length) {
    return color;
  }

  return colors.blue300;
};

// eslint-disable-next-line
export const StyledCheckbox = styled.div<{
  checked: boolean;
  danger?: boolean;
  color?: string;
  disabled?: boolean;
}>`
  align-items: center;
  background: ${(props) =>
    getBackgroundColor(props.checked, props.disabled, props.color)};
  border-radius: 5px;
  border: 1px solid ${(props) => borderColor(props)};
  padding: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 18px;
  justify-content: center;
  margin-right: 8px;
  transition: all 120ms ease-in 0s;
  transition-property: background, border-color;
  width: 18px;

  &:hover {
    ${(props) =>
      !props.disabled && `box-shadow: 0 0 0px 2px ${colors.blue100};`}
  }
`;

export const Label = styled.span<{ danger?: boolean }>`
  cursor: pointer;
  font-size: 14px;
  ${(props) => (props.danger ? `color: ${colors.magenta}` : '')};
`;
