import { AssetSharingInfos } from 'modules/sharing/models/AssetSharingInfos/AssetSharingInfos.model';
import { useTranslation } from 'react-i18next';

export type SharingTextProps = {
  sharingInfos: AssetSharingInfos;
};

export const SharingText = ({ sharingInfos }: SharingTextProps) => {
  const { t } = useTranslation('sharing');
  if (!sharingInfos.isShared()) {
    return <>-</>;
  }
  const text = sharingInfos.isSharedByItsOwner()
    ? t('common.by', {
        organizationName: sharingInfos.getOwnerOrganizationName(),
      })
    : t('common.with', {
        organizationNames: sharingInfos.getBeneficiariesOrganizationNames(),
      });

  return <>{text}</>;
};
