import i18n from 'i18n';
import { Badge } from './index.css';

// TODO Move to constants
export const FAULT_LEVEL_LABELS: { [key: string]: string } = {
  [-1]: i18n.t('assets:faults.levels.unknown'),
  0: i18n.t('assets:faults.levels.low'),
  1: i18n.t('assets:faults.levels.medium'),
  2: i18n.t('assets:faults.levels.high'),
  3: i18n.t('assets:faults.levels.critical'),
};

export const FaultLevel = ({
  level,
  label,
}: {
  level: string;
  label?: string;
}) => <Badge level={level}>{label || FAULT_LEVEL_LABELS[level]}</Badge>;
