import { SearchIcon } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import {
  IconContainer,
  SearchInputContainer,
  StyledTextInput,
} from './index.css';

export type GlobalSearchInputProps = {
  value: string | undefined;
  onReset: () => void;
  onChange: (event: any) => void;
};

export const GlobalSearchInput = ({
  value,
  onReset,
  onChange,
}: GlobalSearchInputProps) => {
  const { t } = useTranslation('globalSearch');

  return (
    <SearchInputContainer>
      <IconContainer>
        <SearchIcon size={18} color="#929497" />
      </IconContainer>
      <StyledTextInput
        value={value}
        onChange={onChange}
        onReset={onReset}
        placeholder={`${t('placeholder')}`}
        big
        fullWidth
        autoFocus
      />
    </SearchInputContainer>
  );
};
