import colors from 'constants/colors';

export type ChevronLeftProps = { color?: string; size?: number };

export const ChevronLeft = ({ color, size }: ChevronLeftProps) => (
  <svg
    fill={color || 'none'}
    height={size || 10}
    viewBox="0 0 6 10"
    width={size || 6}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99999 8.99999L1 4.99999L4.99999 1"
      stroke={colors.gray500}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
