import { useTranslation } from 'react-i18next';
import FaCheck from 'react-icons/lib/fa/check';

import colors from 'constants/colors';
import { Drawer, ShareIcon, TimesIcon } from 'modules/common-ui';
import {
  Header as WSHeader,
  HeaderRight as WSHeaderRight,
} from 'modules/common-ui/components/Drawer/index.css';

import { GraphQLGroupFeaturesWorkspace } from '../../gql';
import {
  Tag,
  Workspace,
  WorkspaceActive,
  WorkspaceContent,
  WorkspaceSubline,
  WorkspaceThumbnail,
  WorkspaceTitle,
} from './index.css';
import { WSHeaderLeft } from './WorkspacePickerMobile.css';

type WorkspacePickerMobileProps = {
  workspaceName?: string;
  isOpen: boolean;
  loading: boolean;
  onChange: (workspaceId: number) => void;
  onClose: () => void;
  organization?: any;
  workspaces?: GraphQLGroupFeaturesWorkspace[];
};

export const WorkspacePickerMobile = (props: WorkspacePickerMobileProps) => {
  const { t } = useTranslation('sidenav');
  const { workspaceName, workspaces, organization, isOpen, onClose } = props;

  const WorkspaceSwitcherHeader = () => (
    <WSHeader>
      <WSHeaderLeft>{t('sidenav.navigation.myWorkspaces')}</WSHeaderLeft>
      <WSHeaderRight onClick={() => onClose()}>
        <TimesIcon />
      </WSHeaderRight>
    </WSHeader>
  );

  const renderSubline = (ws: GraphQLGroupFeaturesWorkspace) => {
    let tag: any =
      organization && organization.name === ws.name
        ? t('sidenav:sidenav.picker.organization')
        : t('sidenav:sidenav.picker.workspace');

    if (ws && ws.sharedWith?.length) {
      tag = (
        <Tag isShareSource>
          <ShareIcon color={colors.green500} />
          {t('sidenav:sidenav.picker.sharedWith', {
            org: ws.sharedWith[0].name,
          })}
        </Tag>
      );
    }

    if (ws && ws.sharedBy) {
      tag = (
        <Tag isShareDest>
          <ShareIcon color={colors.cyan500} />
          {t('sidenav:sidenav.picker.sharedBy', { org: ws.sharedBy.name })}
        </Tag>
      );
    }

    return (
      <WorkspaceSubline>
        {tag}
        <span>{` • `}</span>
        <span>
          {t('sidenav:sidenav.assetsCount', {
            total: ws.totalAssets,
          })}
        </span>
      </WorkspaceSubline>
    );
  };

  return (
    <Drawer isOpen={isOpen} customHeader={<WorkspaceSwitcherHeader />}>
      {workspaces!.map((workspace) => (
        <Workspace
          key={`workspace-option-${workspace.id}`}
          onClick={() => {
            if (workspaceName !== workspace.name) {
              props.onChange(+workspace.id);
            }
            onClose();
          }}
        >
          <WorkspaceThumbnail
            isShareDest={workspace.sharedBy !== null}
            isShareSource={workspace.sharedWith?.length > 0}
          >
            {workspace.name.substr(0, 1)}
          </WorkspaceThumbnail>
          <WorkspaceContent>
            <WorkspaceTitle>{workspace.name}</WorkspaceTitle>
            {renderSubline(workspace)}
          </WorkspaceContent>
          {workspaceName === workspace.name && (
            <WorkspaceActive>
              <FaCheck />
            </WorkspaceActive>
          )}
        </Workspace>
      ))}
    </Drawer>
  );
};
