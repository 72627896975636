import colors from 'constants/colors';

export const CounterIcon: React.FC<{ size?: number; color?: string }> = ({
  size = 16,
  color = colors.gray500,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.009 6.91539C11.5245 5.1736 9.9268 3.8954 8.03061 3.8954C6.13441 3.8954 4.53675 5.1736 4.05225 6.91539"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0.07 3"
      />
      <circle cx="7.99997" cy="8" r="5.97067" stroke={color} />
      <path
        d="M10.5058 4.75478L7.96471 8.03529"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};
