import colors from 'constants/colors';
import { ShareIcon, Tooltip, TooltipProps } from 'modules/common-ui';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { SharingIconContainer } from './styles.css';
import { AssetSharingInfos } from 'modules/sharing/models/AssetSharingInfos/AssetSharingInfos.model';

export type SharingMarkerProps = {
  sharingInfos: AssetSharingInfos;
  iconSize?: number;
  tooltipPlace?: TooltipProps['place'];
};

export const SharingMarker = ({
  sharingInfos,
  iconSize = 24,
  tooltipPlace = 'top',
}: SharingMarkerProps) => {
  const { t } = useTranslation('sharing');
  const shareTooltipId = useId();

  if (!sharingInfos.isShared()) {
    return null;
  }
  const tip = sharingInfos.isSharedByItsOwner()
    ? t('common.tooltip.sharedBy', {
        organizationName: sharingInfos.getOwnerOrganizationName(),
      })
    : t('common.tooltip.sharedWith', {
        organizationNames: sharingInfos.getBeneficiariesOrganizationNames(),
      });

  return (
    <SharingIconContainer data-for={shareTooltipId} data-tip={tip}>
      <Tooltip id={shareTooltipId} place={tooltipPlace} multilineHtml />
      <ShareIcon size={iconSize} color={colors.blue500} />
    </SharingIconContainer>
  );
};
