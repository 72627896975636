import colors from 'constants/colors';

export type LogoutIconProps = {
  size?: number;
  color?: string;
};

export const LogoutIcon = ({
  size = 16,
  color = colors.gray500,
}: LogoutIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 16 16`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.13403 1.78391V7.2389"
      stroke={color}
      strokeWidth="0.999999"
      strokeLinecap="round"
    />
    <path
      d="M5.3098 3.78992C4.62662 4.19875 4.03489 4.74207 3.58302 5.40911C2.82991 6.52265 2.50714 7.87287 2.67928 9.20696C2.85142 10.541 3.50771 11.7622 4.51903 12.6498C5.53035 13.532 6.83216 14.0161 8.17701 14.0054C9.52185 13.9946 10.8129 13.489 11.8135 12.5906C12.814 11.6922 13.4488 10.455 13.5994 9.12089C13.75 7.7868 13.4058 6.44196 12.6311 5.33918C12.1846 4.70441 11.6091 4.18261 10.9528 3.78992"
      stroke={color}
      strokeWidth="0.999999"
      strokeLinecap="round"
    />
  </svg>
);
