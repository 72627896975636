import React from 'react';

import { Dropdown, DropdownOption, DropdownProps } from 'modules/common-ui';

import { useTranslation } from 'react-i18next';
import {
  EnergyTypePickerLabel,
  HelperText,
  OptionWrapper,
  Wrapper,
} from './index.css';
import { generateEnergyTypeSubTitle, generateEnergyTypeTitle } from './utils';

export type EnergyTypeType = {
  id: number;
  code: string;
  emissionFactor: number;
};

export type EnergyTypePickerProps = {
  value: EnergyTypeType | null;
  placeholder?: string;
  onChange: any;
  onDelete?: any;
  energyTypes: EnergyTypeType[];
  menuWidth?: DropdownProps['menuWidth'];
  align?: DropdownProps['align'];
  inputType?: DropdownProps['inputType'];
  light?: DropdownProps['light'];
  menuStyle?: React.CSSProperties;
  selectStyle?: React.CSSProperties;
};

export const EnergyTypePicker = ({
  value,
  placeholder = '',
  onChange,
  energyTypes,
  menuWidth,
  align,
  inputType = false,
  light = false,
  menuStyle = {},
  selectStyle = {},
}: EnergyTypePickerProps) => {
  const { t } = useTranslation('assets');

  const energyTypeOptions: DropdownOption[] = energyTypes
    .map((energyType) => {
      const label = generateEnergyTypeTitle(t, energyType.code);
      const subTitle = generateEnergyTypeSubTitle(
        t,
        energyType.code,
        energyType.emissionFactor,
      );
      return {
        label,
        value: energyType.id,
        renderOption: () => (
          <OptionWrapper data-testid={`energy-type-option-${energyType.id}`}>
            <EnergyTypePickerLabel>{label}</EnergyTypePickerLabel>
            <HelperText>{subTitle}</HelperText>
          </OptionWrapper>
        ),
      };
    })
    .sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });

  return (
    <Wrapper data-testid="energy-type-select">
      <Dropdown
        value={value?.id}
        options={energyTypeOptions}
        className="energy-type-picker"
        placeholder={placeholder}
        onChange={(energyType) => onChange(energyType)}
        menuStyle={{
          marginTop: '4px',
          padding: '8px 0',
          width: '100%',
          maxWidth: '100%',
          height: 300,
          minHeight: 40,
          ...menuStyle,
        }}
        selectStyle={selectStyle}
        menuWidth={menuWidth}
        align={align}
        inputType={inputType}
        light={light}
      />
    </Wrapper>
  );
};
