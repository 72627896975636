import { usePricingPlan } from 'modules/administration/containers/PlansPage/hooks/pricingPlan.hook';
import { PricingPlan } from 'modules/administration/containers/PlansPage/models/PricingPlan.model';
import { FreemiumButton } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import {
  FooterContainer,
  FooterSection,
  FooterSectionContainer,
  FreemiumButtonWrapper,
  FreemiumSubtitle,
} from './index.css';

const useLabels = (pricingPlan: PricingPlan | null) => {
  const [t] = useTranslation('sidenav');

  if (!pricingPlan) {
    return { subtitle: '', buttonLabel: '', freeTrialLink: false };
  }

  if (pricingPlan.isFreemium()) {
    return {
      subtitle: pricingPlan.isFreeTrialOver()
        ? t('sidenav.footer.freemiumSubtitle')
        : t('sidenav.footer.freemiumSubtitleStartFreeTrial'),
      buttonLabel: pricingPlan.isFreeTrialOver()
        ? t('sidenav.footer.freemiumButtonLabel')
        : t('sidenav.footer.freemiumButtonLabelStartFreeTrial'),
      freeTrialLink: !pricingPlan.isFreeTrialOver(),
    };
  }
  if (pricingPlan.isFreeTrialActive()) {
    return {
      subtitle: pricingPlan.isUnlimitedFreeTrial()
        ? t('sidenav.footer.trial')
        : t('sidenav.footer.trialDaysRemaining', {
            days: pricingPlan.getFreeTrialRemainingDays(),
          }),
      buttonLabel: t('sidenav.footer.freemiumButtonLabel'),
      freeTrialLink: false,
    };
  }

  return { subtitle: '', buttonLabel: '', freeTrialLink: false };
};

export const Footer = () => {
  const { pricingPlan, loadingPricingPlan } = usePricingPlan();

  const { subtitle, buttonLabel, freeTrialLink } = useLabels(pricingPlan);

  if (
    loadingPricingPlan ||
    !pricingPlan ||
    pricingPlan.isEnterprise() ||
    (!pricingPlan.isFreemium() &&
      !(pricingPlan.isFreeTrial() || pricingPlan.isFreeTrialOver()))
  )
    return null;

  return (
    <FooterContainer>
      <FooterSectionContainer>
        <FooterSection>
          <FreemiumSubtitle>{subtitle}</FreemiumSubtitle>
          <FreemiumButtonWrapper>
            <FreemiumButton
              id="upgrade"
              style={{ fontSize: '13px' }}
              freemiumTooltipButtonLabel={buttonLabel}
              freeTrialLink={freeTrialLink}
            ></FreemiumButton>
          </FreemiumButtonWrapper>
          <FreemiumSubtitle></FreemiumSubtitle>
        </FooterSection>
      </FooterSectionContainer>
    </FooterContainer>
  );
};
