import { useTranslation } from 'react-i18next';
import {
  BetaInfosContainer,
  Infos,
  LearnMore,
  Title,
} from './SegmentBetaInfos.css';

export const SegmentBetaInfos = () => {
  const { t } = useTranslation('commonUi');

  return (
    <BetaInfosContainer>
      <Title>{t('filter.segmentBetaInfos.title')}</Title>

      <Infos>{t('filter.segmentBetaInfos.infos')}</Infos>
      <LearnMore href={t('filter.segmentBetaInfos.learnMoreLink')}>
        {t('filter.segmentBetaInfos.learnMore')}
      </LearnMore>
    </BetaInfosContainer>
  );
};
