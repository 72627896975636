import colors from 'constants/colors';
import styled from 'styled-components';

export const FormErrorMessage = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.red300};
`;
