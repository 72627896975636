import colors from 'constants/colors';
import styled from 'styled-components';

export const Segments = styled.ul<{ isMobile: boolean }>`
  padding: 0;
  margin: 0;
  list-style-type: none;
  overflow: auto;
  ${({ isMobile }) => {
    return `max-height: ${isMobile ? 'auto' : '300px'};`;
  }}
`;

export const Segment = styled.li<{ active: boolean }>`
  padding: 12px;
  border-top: 1px solid ${colors.gray100};

  &:hover {
    background: ${colors.gray50};
    cursor: pointer;
    #segment-actions-hover-container {
      opacity: 1;
    }
  }

  ${({ active }) => {
    return `background: ${active ? colors.gray50 : 'transparent'};`;
  }}
  display: flex;
  justify-content: space-between;
  gap: 4px;
  flex-direction: row;
  align-items: center;

  &: last-child {
    border-bottom: 1px solid ${colors.gray100};
  }
  #segment-actions-hover-container {
    opacity: 0;
  }
`;

export const SegmentTitle = styled.div<{ active: boolean }>`
  color: ${colors.gray850};
  font-size: 14px;
  ${({ active }) => {
    return `font-weight: ${active ? '600' : '400'};`;
  }}
  line-height: 18px;
`;

export const SegmentAssetCount = styled.div`
  color: ${colors.gray500};
  font-size: 11px;
`;

export const SegmentInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const SearchContainer = styled.div`
  padding: 0px 8px 8px 8px;
`;

export const LockContainer = styled.div`
  display: flex;
  user-select: none;
  width: 24px;
  align-items: center;
  justify-content: center;
`;

export const CheckIconContainer = styled.div`
  padding-top: 4px;
  padding-left: 4px;
  user-select: none;
  width: 20px;
  text-align: center;
`;

export const SegmentActionsContainer = styled.div`
  display: flex;
  width: 44px;
  flex-direction: row;
  align-items: center;
  align-content: center;
`;

export const SegmentActionsHoverContainer = styled.div`
  display: flex;
`;

export const SegmentDefaultItem = styled.span`
  color: ${colors.gray500};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
