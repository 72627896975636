import React, {
  Children,
  ReactElement,
  cloneElement,
  isValidElement,
  useCallback,
  useState,
} from 'react';

import { Tooltip } from '../Tooltip';

type EllipsisTooltipProps = {
  onMouseOver: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  'data-for': string;
  'data-tip': string;
};

export const EllipsisToolTip: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const mouseEnterHandler = useCallback(
    (e: any) => {
      if (e.target.offsetWidth !== e.target.scrollWidth) {
        Tooltip.rebuild();
        setShowTooltip(true);
      } else if (e.target.offsetWidth === e.target.scrollWidth) {
        setShowTooltip(false);
      }
    },
    [setShowTooltip],
  );

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child as ReactElement<EllipsisTooltipProps>, {
        onMouseOver: mouseEnterHandler,
        'data-for': 'hiboo-tooltip',
        'data-tip': showTooltip ? child.props['data-tip'] : '',
      });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
};
