import colors from 'constants/colors';

export type DashboardIconProps = {
  size?: number;
  color?: string;
};

export const DashboardIcon = ({
  size = 16,
  color = colors.gray500,
}: DashboardIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.073 13.962H2.00732V5.03766L7.98736 0.998779L14.073 5.03766V13.962Z"
      stroke={color}
    />
    <path d="M6.02209 13.826V9.06781H9.98071V13.6888" stroke={color} />
  </svg>
);
