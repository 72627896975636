import colors from 'constants/colors';

export type AdministrationIconProps = {
  size?: number;
  color?: string;
};

export const AdministrationIcon = ({
  size = 16,
  color = colors.gray500,
}: AdministrationIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 16 16`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="6.5" y="1.5" width="3" height="3" stroke={color} />
    <rect x="1.5" y="11.5" width="3" height="3" stroke={color} />
    <rect x="11.5" y="11.5" width="3" height="3" stroke={color} />
    <path d="M3 11V8H8M13 11V8H8M8 8V5" stroke={color} />
  </svg>
);
