export type ChevronUpAndDownProps = {
  color?: string;
};

export const ChevronUpAndDownIcon = ({ color }: ChevronUpAndDownProps) => (
  <svg
    width="9"
    height="12"
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 49">
      <path
        id="Rectangle 4"
        d="M7.53906 7.15482L4.03907 10.6548L0.539073 7.15482"
        stroke={color || '#2D2F30'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Rectangle 5"
        d="M0.539062 4.33762L4.03906 0.837621L7.53905 4.33762"
        stroke={color || '#2D2F30'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
