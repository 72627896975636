import { AssetFileFormData } from 'modules/assets/files/components/AssetFileFormModal';
import { FetchApi } from '../client';

export const fleetApi = (fetchApi: FetchApi) => ({
  downloadAssetInOrganizationFile(
    token: string,
    assetInOrganizationFileId: number,
    onCompleted: (res: Response) => void,
    onError: () => void,
  ): Promise<void> {
    const endpoint = fetchApi.generateEndpoint(
      `/app/assetInOrganizationFiles/${assetInOrganizationFileId}/download`,
    );

    return fetch(endpoint, {
      method: 'GET',
      headers: {
        'x-access-token': token,
      },
    })
      .then(onCompleted)
      .catch(onError);
  },

  uploadAssetInOrganizationFile(
    token: string,
    assetId: number,
    formData: AssetFileFormData,
  ): Promise<void> {
    const { file, shared, name } = formData;
    return fetchApi
      .setToken(token)
      .postFile(
        '/app/assetInOrganizationFiles/upload',
        { assetId, shared, name },
        file,
      );
  },
});
