import { gql } from '@apollo/client';

export type OrganizationData = {
  viewer: {
    organization: {
      assetsCount: number;
      hasOwnedAsset: boolean;
      integrationsCount: number;
    };
  };
};

export const queryOrgData = gql`
  query OrganizationData {
    viewer {
      id
      organization {
        id
        assetsCount
        hasOwnedAsset
        integrationsCount
      }
    }
  }
`;
