import colors from 'constants/colors';
import styled from 'styled-components';

export const PartnersFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PartnersFooterText = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  color: ${colors.gray850};
`;

export const PartnersFooterLogos = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  img {
    margin-top: 24px;
    height: 24px;
  }

  div + div {
    margin-right: 8px;
  }
`;
