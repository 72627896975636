import styled from 'styled-components';

import colors from 'constants/colors';

type ModalHeaderProps = {
  borderBottom?: boolean;
};

type ModalFooterProps = {
  borderTop?: boolean;
};

export const ModalHeader = styled.div<ModalHeaderProps>`
  font-weight: 600;
  font-size: 16px;
  color: ${colors.gray850};
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  ${(props) =>
    props.borderBottom ? `border-bottom: 1px solid ${colors.gray300};` : ''}

  align-items: center;
  display: flex;
  line-height: 22px;
`;

export const ModalSubTitle = styled.div`
  font-size: 14px;
  color: ${colors.gray500};
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const ModalBody = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  color: ${colors.gray850};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  height: 100%;
  overflow-y: auto;
`;

export const ModalFooter = styled.div<ModalFooterProps>`
  margin-top: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 16px;
  ${(props) =>
    props.borderTop ? `border-top: 1px solid ${colors.gray300};` : ''}

  button + button {
    margin-left: 8px;
  }
`;
