export type SearchProps = { size?: number; color?: string };

export const SearchIcon = ({ size, color }: SearchProps) => (
  <svg
    width={size || 12}
    height={size || 12}
    viewBox="0 0 12 12"
    fill={color || 'currentColor'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.65374 4.63823C8.65374 6.76384 6.89674 8.48698 4.72937 8.48698C2.562 8.48698 0.804999 6.76384 0.804999 4.63823C0.804999 2.51263 2.562 0.789487 4.72937 0.789487C6.89674 0.789487 8.65374 2.51263 8.65374 4.63823ZM7.48431 8.40871C6.70852 8.95488 5.75724 9.27647 4.72937 9.27647C2.11741 9.27647 0 7.19986 0 4.63823C0 2.07661 2.11741 0 4.72937 0C7.34133 0 9.45874 2.07661 9.45874 4.63823C9.45874 5.76471 9.04927 6.7974 8.36834 7.60094L11.5648 10.7358C11.8038 10.9702 11.8038 11.3551 11.5648 11.5895C11.3323 11.8175 10.9601 11.8175 10.7276 11.5895L7.48431 8.40871Z"
    />
  </svg>
);
