import styled from 'styled-components';

export const FirstNavPartContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LogoContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 11px;
  margin-bottom: 5px;

  background: #ffffff;
`;

export const CustomLogo = styled.img`
  width: 100%;
  max-height: 100px;
`;

export const HibooLogo = styled.div`
  height: 22px;
`;

export const SideTopContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const ScrollableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;
