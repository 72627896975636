import { useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';

import {
  Button,
  DragIcon,
  Drawer,
  EllipsisToolTip,
  PlusIcon,
  Switch,
} from 'modules/common-ui';

import {
  Column,
  ColumnSwitch,
  ColumnTogglerMask,
  ColumnTogglerWrapper,
  DragIconWrapper,
  Label,
  List,
  ListContainer,
  ListContainerMobile,
} from './ColumnToggler.css';
import { ColumnTogglerPropTypes } from './types';

export const ColumnToggler = <D extends object>({
  allColumns,
  tableId,
  setColumnOrder,
  mobileView = false,
}: ColumnTogglerPropTypes<D>) => {
  const { t } = useTranslation('tableV7');
  const [isOpen, updateIsOpen] = useState(false);

  const onDragEnd = useCallback(
    (result: any) => {
      const { destination, source, draggableId } = result;

      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      const newColumnsOrder = allColumns.map((ac) => ac.id);
      newColumnsOrder.splice(source.index, 1);
      newColumnsOrder.splice(destination.index, 0, draggableId);

      setColumnOrder(newColumnsOrder);
    },
    [allColumns, setColumnOrder],
  );

  const renderHeader = (column: any) => {
    if (typeof column.Header === 'string') {
      return (
        <EllipsisToolTip>
          <Label data-tip={column.Header} htmlFor={column.id}>
            {column.Header}
          </Label>
        </EllipsisToolTip>
      );
    }
    return (
      <Label data-tip={column.Header} htmlFor={column.id}>
        {column.Header}
      </Label>
    );
  };

  const renderChoice = useCallback(
    (column: any, index: any) => (
      <Draggable
        draggableId={column.id}
        index={index}
        key={`column-${column.id}`}
        isDragDisabled={column.disableOrdering}
      >
        {(provided: any, snapshot: any) => {
          const { checked } = column.getToggleHiddenProps();

          return (
            <Column
              {...provided.draggableProps}
              ref={provided.innerRef}
              isDragging={snapshot.isDragging}
            >
              <DragIconWrapper
                {...provided.dragHandleProps}
                disabled={column.disableOrdering}
              >
                <DragIcon />
              </DragIconWrapper>
              <ColumnSwitch
                disabled={column.disableToggling}
                onClick={() => !column.disableToggling && column.toggleHidden()}
              >
                {renderHeader(column)}
                <Switch
                  disableToggling={column.disableToggling}
                  value={checked}
                  onChange={() => {}}
                />
              </ColumnSwitch>
            </Column>
          );
        }}
      </Draggable>
    ),
    [],
  );

  const renderList = useCallback(() => {
    if (mobileView) {
      return (
        <Drawer
          isOpen={isOpen}
          onDone={() => updateIsOpen(false)}
          title={t('columnToggler.label')}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <ListContainerMobile>
              <Droppable droppableId={tableId}>
                {(provided: any) => (
                  <List ref={provided.innerRef} {...provided.droppableProps}>
                    {allColumns
                      .filter((column) => !!column.hideFromToggling === false)
                      .map((column, index) => renderChoice(column, index))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </ListContainerMobile>
          </DragDropContext>
        </Drawer>
      );
    }
    if (!isOpen) {
      return null;
    }

    return (
      <>
        <ColumnTogglerMask />
        <OutsideClickHandler onOutsideClick={() => updateIsOpen(false)}>
          <DragDropContext onDragEnd={onDragEnd}>
            <ListContainer>
              <Droppable droppableId={tableId}>
                {(provided: any) => (
                  <List ref={provided.innerRef} {...provided.droppableProps}>
                    {allColumns
                      .filter((column) => !!column.hideFromToggling === false)
                      .map((column, index) => renderChoice(column, index))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </ListContainer>
          </DragDropContext>
        </OutsideClickHandler>
      </>
    );
  }, [allColumns, isOpen, mobileView, onDragEnd, renderChoice, t, tableId]);

  return (
    <ColumnTogglerWrapper>
      <Button
        iconLeft={<PlusIcon size={10} />}
        onClick={() => updateIsOpen(true)}
        variant="neutral"
      >
        {t('columnToggler.label')}
      </Button>
      {renderList()}
    </ColumnTogglerWrapper>
  );
};
