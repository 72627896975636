import colors from 'constants/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  color: ${colors.gray500};
  display: inline-flex;

  :hover {
    color: ${colors.gray500};
    text-decoration: none;
  }

  :active {
    color: ${colors.gray500};
    text-decoration: none;
  }
`;

export const Icon = styled.div`
  padding-right: 8px;
`;
