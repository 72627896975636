import colors from 'constants/colors';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

export const Spinner = styled.div<{ alternate?: boolean }>`
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: ${(props) =>
    props.alternate ? colors.cyan400 : colors.blue};

  border-radius: 100%;
  animation: ${pulse} 1s infinite ease-in-out;
`;
