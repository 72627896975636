export const MB_TO_BYTES = 1048576; // 1024 * 1024

export type FileType =
  | 'PDF'
  | 'JPG'
  | 'PNG'
  | 'GIF'
  | 'TIFF'
  | 'CSV'
  | 'XLS'
  | 'DOC';

const supportedTypesMimeTypesMap: { [k in FileType]: string[] } = {
  PDF: ['application/pdf'],
  JPG: ['image/jpeg'],
  PNG: ['image/png'],
  GIF: ['image/gif'],
  TIFF: ['image/tiff'],
  CSV: ['text/csv'],
  XLS: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  DOC: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
};

export const fileTypesToMimeTypes = (fileTypes: FileType[]) => {
  const filteredMimeTypes: string[] = [];
  fileTypes.forEach((fileType) => {
    filteredMimeTypes.push(...supportedTypesMimeTypesMap[fileType]);
  });
  return filteredMimeTypes;
};

export const convertMimeTypeToFileType = (mimeType: string) => {
  const type = (Object.keys(supportedTypesMimeTypesMap) as FileType[]).find(
    (fileType) => {
      return supportedTypesMimeTypesMap[fileType].includes(mimeType);
    },
  );
  return type ?? 'unknown';
};

export const getSizeMb = (file: File) => {
  return Number((file.size / MB_TO_BYTES).toFixed(2));
};
