export type DragProps = { size?: number; color?: string };

export const DragIcon = ({ size, color }: DragProps) => (
  <svg
    width={size || 16}
    height={size || 16}
    viewBox="0 0 16 16"
    fill={color || 'currentColor'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="5" y="3" width="2" height="2" rx="1" />
    <rect x="5" y="7" width="2" height="2" rx="1" />
    <rect x="5" y="11" width="2" height="2" rx="1" />
    <rect x="9" y="3" width="2" height="2" rx="1" />
    <rect x="9" y="7" width="2" height="2" rx="1" />
    <rect x="9" y="11" width="2" height="2" rx="1" />
  </svg>
);
