import classNames from 'classnames';
import hexToRgba from 'hex-to-rgba';
import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import colors from 'constants/colors';
import { CloseIcon } from 'modules/common-ui';

import { SearchIcon } from './SearchIcon';

type InputProps = {
  big?: boolean;
  veryBig?: boolean;
  large?: boolean;
  fullWidth?: boolean;
  textAlign?: string;
  withIcon?: boolean;
  variant: 'primary' | 'secondary';
};

const Input = styled.input<InputProps>`
  color: ${(props) => (props.variant === 'primary' ? '#495057' : '#000')};
  background: ${(props) =>
    props.variant === 'primary' ? colors.white : colors.lightGrey};
  border: 0.5px solid ${colors.gray300};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 8px;
  ${(props) => (props.withIcon ? 'padding-left: 26px;' : '')}
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${(props) => (props.large ? 'min-width: 300px;' : '')}
  ${(props) => (props.fullWidth ? 'width: 100%;' : '')}
  ${(props) => (props.big ? 'height: 44px;' : '')}
  ${(props) => (props.veryBig ? 'height: 90px;' : '')}
  ${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : '')}
  ::placeholder {
    color: ${colors.gray500};
  }
  :-ms-input-placeholder {
    color: ${colors.gray500};
  }
  ::-ms-input-placeholder {
    color: ${colors.gray500};
  }

  &:disabled {
    background: ${colors.shadyGrey};
    cursor: not-allowed;
  }

  &:focus {
    border-color: ${colors.blue100};
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 0.15rem ${hexToRgba(colors.blue400, 0.25)};
  }

  &.invalid {
    border-color: ${colors.red300};
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 0.15rem ${hexToRgba(colors.red300, 0.25)};
  }
`;

export const InputWrapper = styled.div<{
  fullWidth: boolean;
  searchWidth?: number;
}>`
  position: relative;
  ${(props) =>
    props.searchWidth &&
    `
      width: ${props.searchWidth}px;
      ${Input} {
        width: ${props.searchWidth}px;
      }
    `}
  ${(props) =>
    props.fullWidth
      ? `
  width: 100%;
  ${Input} {
    width: 100%;
  }
  `
      : ''}
`;

export const ResetIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 8px;
  bottom: 5px;
  color: ${colors.gray500};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Suffix = styled.div`
  position: absolute;
  top: 5px;
  right: 8px;
  bottom: 5px;
  color: ${colors.gray500};
`;

const icons = {
  none: null,
  search: <SearchIcon />,
};

type TextInputProps = {
  big?: boolean;
  veryBig?: boolean;
  fullWidth?: boolean;
  searchWidth?: number;
  textAlign?: string;
  large?: boolean;
  valid?: boolean;
  variant?: 'primary' | 'secondary';
  onReset?: () => void;
  icon?: keyof typeof icons;
  inputClassName?: string;
  suffix?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      big = false,
      veryBig = false,
      large = false,
      className,
      valid = true,
      fullWidth = false,
      searchWidth,
      textAlign,
      variant = 'primary',
      onReset,
      icon,
      inputClassName,
      suffix,
      ...otherProps
    },
    ref,
  ) => {
    return (
      <InputWrapper
        fullWidth={fullWidth}
        searchWidth={searchWidth}
        className={className}
      >
        <Input
          ref={ref}
          big={big}
          veryBig={veryBig}
          withIcon={!!icon && !!icons[icon]}
          variant={variant}
          large={large}
          textAlign={textAlign}
          className={classNames({ invalid: !valid }, inputClassName)}
          {...otherProps}
        />
        {suffix ? <Suffix>{suffix}</Suffix> : null}
        {icon ? icons[icon] : null}
        {onReset && otherProps.value && (
          <ResetIcon>
            <CloseIcon onClick={onReset} />
          </ResetIcon>
        )}
      </InputWrapper>
    );
  },
);
