export const CATALOG_EXTERNAL_LINKS = {
  LEARN_HOW_CATALOG_WORKS_EN: 'https://bit.ly/3vrHTZn',
  LEARN_HOW_CATALOG_WORKS_FR: 'https://hiboo.xyz/3RBF7tc',

  SUGGEST_A_NEW_DATASOURCE_EN:
    'https://form.typeform.com/to/GKX6TMy9?typeform-medium=embed-snippet',
  SUGGEST_A_NEW_DATASOURCE_FR:
    'https://form.typeform.com/to/lQYp7KCd?typeform-medium=embed-snippet',
};

export const INVENTORY_MASS_UPDATE_LINKS = {
  LEARN_MORE_EN: 'https://hiboo.xyz/3cZQ8pn',
  LEARN_MORE_FR: 'https://hiboo.xyz/3qbTB7x',
};

export const ONBOARDING_EXTERNAL_LINKS = {
  BOOK_A_DEMO: 'https://hiboo.xyz/demo',

  EXPLORE_VIDEO_EN:
    'https://www.loom.com/embed/916bc886f4ff489ab8956d3324c3f577?autoplay=1',
  EXPLORE_VIDEO_FR:
    'https://www.loom.com/embed/d78557d9b7cb4a8a9a90e71d5f7550b6?autoplay=1',

  GETTING_STARTED_EN: 'https://hiboo.xyz/help',
  GETTING_STARTED_FR: 'https://hiboo.xyz/aide',

  GETTING_STARTED_VIDEO:
    'https://www.youtube.com/embed/sdBSGTVhM0U?autoplay=1&cc_load_policy=1',

  TOUR_GUIDE_EN: 'https://hiboo.xyz/3v6LJHJ',
  TOUR_GUIDE_FR: 'https://hiboo.xyz/3k5V8ZA',

  TOUR_GUIDE_ID_EN: 342419,
  TOUR_GUIDE_ID_FR: 342368,
};

export const TYPEFORM_FREEMIUM_LINKS = {
  FREEMIUM_GET_IN_TOUCH_EN: 'https://hiboosystems.typeform.com/to/Q2bi5Wqj#',
  FREEMIUM_GET_IN_TOUCH_FR: 'https://hiboosystems.typeform.com/to/xvrJprij#',
};

export const ZONES_EXTERNAL_LINKS = {
  LEARN_MORE_EN: 'https://hiboo.xyz/3a5MEQY',
  LEARN_MORE_FR: 'https://hiboo.xyz/3NC6QY9',
};
