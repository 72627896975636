import React from 'react';

import colors from 'constants/colors';

import { CloseIconWrapper } from './index.css';

export type CloseIconProps = {
  size?: number;
  color?: string;
  hoverColor?: string;
  onClick: (event: any) => void;
};

export const CloseIcon: React.FC<React.PropsWithChildren<CloseIconProps>> = ({
  size = 16,
  color = colors.gray300,
  hoverColor = colors.gray500,
  onClick,
}) => (
  <CloseIconWrapper color={color} hoverColor={hoverColor}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8 1C4.13413 1 1 4.13413 1 8C1 11.8659 4.13413 15 8 15C11.8659 15 15 11.8659 15 8C15 4.13413 11.8659 1 8 1ZM11.2667 10.6133L10.6133 11.2667L8 8.65333L5.38667 11.2667L4.73333 10.6133L7.34667 8L4.73333 5.38667L5.38667 4.73333L8 7.34667L10.6133 4.73333L11.2667 5.38667L8.65333 8L11.2667 10.6133Z"
        fill={color}
      />
    </svg>
  </CloseIconWrapper>
);
