import colors from 'constants/colors';

export type UnarchiveIconProps = {
  size?: number;
  color?: string;
};

export const UnarchiveIcon = ({
  size = 16,
  color = colors.gray850,
}: UnarchiveIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6053 1.36002L1.11723 3.87425C1.05248 3.88842 1.01149 3.95239 1.02566 4.01713L1.57725 6.53747L13.2998 3.97194L12.7482 1.45159C12.734 1.38685 12.6701 1.34585 12.6053 1.36002Z"
      stroke={color}
    />
    <path d="M2.6001 13.9999V6.3999H13.4001V13.9999H2.6001Z" stroke={color} />
    <rect
      x="4.6999"
      y="8.10005"
      width="6.6"
      height="0.6"
      rx="0.3"
      stroke={color}
      strokeWidth="0.6"
    />
  </svg>
);
