import React from 'react';

export type ExportIconProps = {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
};

export const ExportIcon = ({ size, color, style }: ExportIconProps) => (
  <svg
    fill={color || 'currentColor'}
    height={size || 16}
    style={style}
    viewBox="0 0 16 16"
    width={size || 16}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      height="6.24999"
      rx="0.624999"
      transform="rotate(-180 8.5 10.5)"
      width="1.25"
      x="8.5"
      y="10.5"
    />
    <path
      clipRule="evenodd"
      d="M7.34468 3.18306C7.63757 2.93899 8.11244 2.93899 8.40534 3.18306L11.4053 5.68306C11.6982 5.92714 11.6982 6.32286 11.4053 6.56694C11.1124 6.81102 10.6376 6.81102 10.3447 6.56694L7.87501 4.50889L5.40534 6.56694C5.11245 6.81102 4.63757 6.81102 4.34468 6.56694C4.05179 6.32286 4.05179 5.92714 4.34468 5.68306L7.34468 3.18306Z"
      fillRule="evenodd"
    />
    <rect x="3.5" y="11.75" width="8.74999" height="1.25" rx="0.624999" />
  </svg>
);
