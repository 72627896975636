import { CellSubtitle, CellTitle, Container } from './index.css';

export type CumulativeUsageHoursCellProps = {
  id: number | string;
  subtitle?: string;
  title: string;
};

export const CumulativeUsageHoursCell = ({
  id,
  subtitle = '',
  title,
}: CumulativeUsageHoursCellProps) => (
  <Container
    style={{ alignItems: 'flex-end' }}
    key={`cumulative-usage-hours-cell-${id}`}
  >
    <CellTitle withPaddingLeft={true}>{title}</CellTitle>
    {subtitle && <CellSubtitle withPaddingLeft={true}>{subtitle}</CellSubtitle>}
  </Container>
);
