import { HibooLogoLarge } from 'modules/common-ui';
import { HeaderContainer } from 'modules/signup/common';

export const Header = () => {
  return (
    <HeaderContainer>
      <HibooLogoLarge />
    </HeaderContainer>
  );
};
