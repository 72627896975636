import colors from 'constants/colors';

export type CheckIconProps = { color?: string; size?: number };

export const CheckIcon = ({
  color = colors.green400,
  size = 11,
}: CheckIconProps) => {
  const ratio = 8 / 11;
  const width = size;
  const height = size * ratio;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.70036 7.8429L0.213743 4.35629C0.00427328 4.14682 0.00427328 3.80719 0.213743 3.5977L0.972312 2.83911C1.18178 2.62962 1.52143 2.62962 1.7309 2.83911L4.07965 5.18784L9.11042 0.157102C9.31989 -0.0523673 9.65954 -0.0523673 9.86901 0.157102L10.6276 0.915691C10.837 1.12516 10.837 1.46479 10.6276 1.67428L4.45895 7.84292C4.24946 8.05239 3.90983 8.05239 3.70036 7.8429Z"
        fill={color}
      />
    </svg>
  );
};
