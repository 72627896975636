type Cache = {
  [key: string]: {
    lastCall: number;
    promise: Promise<any>;
  };
};

export const createCache = () => {
  const cache: Cache = {};

  return {
    setCache: (key: string, promise: Promise<any>) => {
      cache[key] = {
        lastCall: new Date().getTime(),
        promise,
      };
    },
    getCache: (key: string) => {
      return (cache[key] && cache[key].promise) || null;
    },
    invalidCache: (match: string) => {
      return Object.keys(cache).forEach((key) => {
        if (key.match(`^${match}`)) {
          delete cache[key];
        }
      });
    },
    hasValidCache: (key: string, timeout: number = 60) => {
      return (
        cache[key] &&
        cache[key].lastCall > new Date().getTime() - timeout * 1000
      );
    },
  };
};
