import colors from 'constants/colors';
import { New } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'utils/string';
import { InfoIcon, NavItemIcon, NavItemLabel, StyledLink } from './index.css';

type NavItemProps = {
  link: string;
  label: string;
  display?: boolean;
  icon?: ({ color, size }: { color: string; size: number }) => JSX.Element;
  onClick?: () => void;
  testId?: string;
  id?: string;
  alternate?: boolean;
  isNew?: boolean;
  isBeta?: boolean;
};

export const NavItem = ({
  link,
  label,
  display = true,
  icon,
  onClick = () => {},
  testId,
  id,
  alternate,
  isNew,
  isBeta,
}: NavItemProps) => {
  const [t] = useTranslation('sidenav');
  if (!display) {
    return null;
  }

  return (
    <StyledLink to={`${link}`} onClick={onClick} data-testid={testId} id={id}>
      {icon && (
        <NavItemIcon>
          {icon({
            color: alternate ? colors.blue500 : colors.gray850,
            size: 18,
          })}
        </NavItemIcon>
      )}
      <NavItemLabel alternate={alternate}>{capitalize(label)}</NavItemLabel>
      {isNew && <New />}
      {isBeta && <InfoIcon>{t('sidenav.navigation.common.beta')}</InfoIcon>}
    </StyledLink>
  );
};
