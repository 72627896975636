export type ActionType = {
  type: string;
};

export const SUBNAV = {
  REFRESH: 'SUBNAV_REFRESH',
};

export const refresh = () => ({
  type: SUBNAV.REFRESH,
});
