import FaCheck from 'react-icons/lib/fa/check';
import { Color, Option } from './index.css';

export type ColorPickerOptionProps = {
  label: string;
  value: any;
  color: string;
  selected: boolean;
  onChange: (value: any) => any;
  pickerMode: 'badges' | 'dots';
};

export const ColorPickerOption = ({
  value,
  selected,
  onChange,
  label,
  color,
  pickerMode = 'badges',
}: ColorPickerOptionProps) => (
  <Option
    selected={selected}
    key={value}
    onClick={() => {
      onChange(value);
    }}
  >
    <Color badge={pickerMode === 'badges'} color={color}>
      {pickerMode === 'badges' && (
        <>
          {label}
          {selected && <FaCheck size={10} />}
        </>
      )}
    </Color>
  </Option>
);
