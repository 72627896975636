import { FeatureFlagsProvider } from './feature-flags/FeatureFlagsContext';
import { NavigationMenuProvider } from './navigation-menu/NavigationMenuContext';

// we can nest multiple independent providers here so that App.js remains clean
// example:
// return (
//   <UserProvider>
//     <NavigationMenuProvider>
//       {children}
//     </NavigationMenuProvider>
//   </UserProvider>
// );

export const ContextProviders: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <FeatureFlagsProvider>
      <NavigationMenuProvider>{children}</NavigationMenuProvider>
    </FeatureFlagsProvider>
  );
};
