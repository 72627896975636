import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const usePageAnimation = () => {
  const location = useLocation();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);

    const timer = setTimeout(() => {
      setAnimate(false);
    }, 300);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  return animate;
};
