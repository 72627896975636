import colors from 'constants/colors';

export type WarningSolidIconProps = {
  color?: string;
  width?: number;
  height?: number;
};

export const WarningSolidIcon = ({
  color = colors.neutral850,
  width = 17,
  height = 16,
}: WarningSolidIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66803 2.51949C9.28413 1.84767 8.31543 1.84767 7.93154 2.51949L2.65472 11.7539C2.27377 12.4206 2.75514 13.2501 3.52296 13.2501H14.0766C14.8444 13.2501 15.3258 12.4206 14.9448 11.7539L9.66803 2.51949ZM8.75313 5.50006C8.4538 5.50006 8.21114 5.74272 8.21114 6.04205V8.099C8.21114 8.39833 8.4538 8.64099 8.75313 8.64099C9.05246 8.64099 9.29512 8.39833 9.29512 8.099V6.04205C9.29512 5.74272 9.05246 5.50006 8.75313 5.50006ZM9.22481 10.7684C9.33028 10.6473 9.38301 10.4988 9.38301 10.323C9.38301 10.1472 9.33028 10.0006 9.22481 9.88343C9.11543 9.76622 8.95528 9.70762 8.74434 9.70762C8.5334 9.70762 8.37325 9.76622 8.26387 9.88343C8.1545 10.0006 8.09981 10.1472 8.09981 10.323C8.09981 10.4988 8.1545 10.6473 8.26387 10.7684C8.37325 10.8895 8.5334 10.9501 8.74434 10.9501C8.95528 10.9501 9.11543 10.8895 9.22481 10.7684Z"
      fill={color}
    />
  </svg>
);
