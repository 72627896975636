import { ReactNode } from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from '../Button/Button';

const CustomAnchor = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export type ButtonLinkProps = ButtonProps & {
  href: string;
  children: ReactNode;
};

export const ButtonLink = ({ href, children, ...rest }: ButtonLinkProps) => {
  return (
    <CustomAnchor href={href} target="_blank">
      <Button {...rest}>{children}</Button>
    </CustomAnchor>
  );
};
