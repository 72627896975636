import layout from 'modules/layout';

import { ChevronLeft } from 'modules/common-ui';

import { Icon, StyledLink } from './index.css';

export type BreadcrumbProps = {
  link: string;
  label: string;
};

export const Breadcrumb = ({ link, label }: BreadcrumbProps) => {
  return (
    <StyledLink to={link}>
      <layout.BreadcrumbContainer>
        <Icon>
          <ChevronLeft />
        </Icon>
        <span>{label}</span>
      </layout.BreadcrumbContainer>
    </StyledLink>
  );
};
