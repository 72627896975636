import { DocumentIcon } from './DocumentIcon';
import { ImageIcon } from './ImageIcon';

export const FileIcon = ({
  contentType,
  color,
}: {
  contentType?: string;
  color?: string;
}) => {
  if (contentType?.includes('image')) {
    return <ImageIcon color={color} />;
  }
  return <DocumentIcon color={color} />;
};
