import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'modules/common-ui';

export const CopyButton = ({ text }: { text: string }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation('catalog');
  const buttonText = isCopied ? t('form.copied') : t('form.copy');

  const handleclick = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [isCopied]);

  return (
    <Button variant="primary" onClick={handleclick} disabled={isCopied}>
      {buttonText}
    </Button>
  );
};
