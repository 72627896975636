import { useState } from 'react';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import OutsideClickHandler from 'react-outside-click-handler';
import { ColorPickerOption, ColorPickerOptionProps } from './ColorPickerOption';

import { Color, Container, Left, Options, Right, Select } from './index.css';

export type ColorPickerProps = {
  options: { color: string; value: string; label: string }[];
  value: any;
  placeholder?: string;
  pickerMode?: ColorPickerOptionProps['pickerMode'];
  selectStyle?: { [key: string]: any };
  onChange: (value: any) => any;
};

export const ColorPicker = ({
  value,
  onChange,
  options,
  placeholder = '',
  pickerMode = 'badges',
  selectStyle = {},
}: ColorPickerProps) => {
  const [display, setDisplay] = useState(false);

  const selectedOption = options.find((option) => option.value === value);

  return (
    <OutsideClickHandler onOutsideClick={() => setDisplay(false)}>
      <Container>
        <Select onClick={() => setDisplay(!display)} style={selectStyle}>
          <Left isPlaceholder={!value}>
            {selectedOption ? (
              <Color
                badge={pickerMode === 'badges'}
                color={selectedOption.color}
              >
                {pickerMode === 'badges' ? selectedOption.label : ''}
              </Color>
            ) : (
              placeholder
            )}
          </Left>
          <Right>
            <FaCaretDown />
          </Right>
        </Select>
        {display && (
          <Options>
            {options.map((option) => (
              <ColorPickerOption
                key={`${option.label}-${option.color}`}
                label={option.label}
                color={option.color}
                value={option.value}
                selected={option.value === value}
                onChange={onChange}
                pickerMode={pickerMode}
              />
            ))}
          </Options>
        )}
      </Container>
    </OutsideClickHandler>
  );
};
