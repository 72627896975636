import { useTranslation } from 'react-i18next';

import i18n from 'i18n';
import { Button } from 'modules/common-ui';

import { usePageAnimation } from './hooks';
import { ImgContainer, PlaceholderContainer, StyledLink } from './index.css';

export const PlaceholderPage2 = () => {
  const { t } = useTranslation('onboarding');

  const animate = usePageAnimation();

  const userLanguage = i18n.resolvedLanguage;
  const placeholderImage =
    userLanguage === 'fr'
      ? '/images/placeholder2_fr.png'
      : '/images/placeholder2_en.png';

  return (
    <PlaceholderContainer animate={animate}>
      <ImgContainer>
        <img
          src={placeholderImage}
          alt="hiboo onboarding"
          key={placeholderImage}
        />
      </ImgContainer>
      <StyledLink to="/catalog">
        <Button variant="primary">
          {t('onboarding.addYourFirstDataSource')}
        </Button>
      </StyledLink>
    </PlaceholderContainer>
  );
};
