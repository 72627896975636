import { Navigate, Route, Routes } from 'react-router-dom';

import { ActivationPage, Signup } from './containers';

export const SignupPages = () => {
  return (
    <Routes>
      <Route path="/activate" element={<ActivationPage />} />
      <Route path="/freetrial" element={<Signup />} />
      <Route path="/" element={<Navigate to={'/signup/freetrial'} />} />
    </Routes>
  );
};
