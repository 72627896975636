import styled from 'styled-components';

import colors from 'constants/colors';

export const HeaderContainer = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
`;

export const Title = styled.div`
  color: ${colors.gray850};
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 5px;
`;

export const Subtitle = styled.div`
  color: ${colors.black};
  font-size: 14px;
  line-height: 20px;
`;
